import axios from "axios";
import * as authClient from "./authClient";

const BASE_IRI = {
  organizations: "/api/organizations",
  queries: "/api/queries",
  queryFolders: "/api/query_folders",
  userAccounts: "/api/user_accounts",
  queryExports: "/api/query_exports",
  servers: "/api/servers",
  ExportFields: "/api/export_fields",
  themes: "/api/themes",
  media: "/api/media_objects",
  campaigns: "/api/campaigns",
  partners: "/api/partners",
  customers: "/api/customers",
  customerPacks: "/api/customer_packs",
  cities: "/api/cities",
  campaignOrders: "/api/campaign_orders",
  exportTemplates: "/api/export_templates",
};

/**
 * Helper function to retrieve the IRI of an entity base on an ID or not
 * @param {*} entity
 * @param {*} ressourceId
 */
function getIRI(entity, ressourceId = null) {
  if (entity in BASE_IRI) {
    const IRI = BASE_IRI[entity];
    return ressourceId ? IRI + "/" + ressourceId : IRI;
  } else {
    // TODO: this error should be logged but not thrown
    throw new Error(
      `The IRI could not be build based on the entity key provides : "${entity}". Accepted values are : ${Object.keys(
        BASE_IRI
      ).join(" - ")}`
    );
  }
}

/**
 * Generic methods
 */

async function get(auth, URL, data) {
  try {
    const response = await client(auth, URL, { data: data, method: "GET" });
    return response;
  } catch (e) {
    throw e.message;
  }
}
/**
 * NOT USED ! keeping the code is required one day.
 * This could replace the generic method 'get()'
 */
/**
async function send(auth, method, URL, data){
  try {
    const response = await client(auth, URL, { data:data, method: method});
    return response;
  }
  catch (e) {
    throw (e.message)
  }
}
*/

/**
 * Entities method (IRI based)
 */

async function getRessource(auth, IRI) {
  try {
    const response = await client(auth, IRI);
    return response;
  } catch (e) {
    throw e.message;
  }
}

async function getRessources(auth, entity, data) {
  try {
    const IRI = getIRI(entity); // Can fail
    const response = await client(auth, IRI, { method: "GET", data: data });
    return response;
  } catch (e) {
    throw e.message;
  }
}

async function createRessource(auth, entity, data) {
  try {
    const IRI = getIRI(entity); // Can fail
    const response = await client(auth, IRI, { method: "POST", data: data });
    return response;
  } catch (e) {
    throw e.message;
  }
}

async function getPostedData(auth, IRI, data) {
  try {
    const response = await client(auth, IRI, { method: "POST", data: data });
    return response;
  } catch (e) {
    throw e.message;
  }
}

async function updateRessource(auth, IRI, data) {
  try {
    const response = await client(auth, IRI, { method: "PUT", data: data });
    return response;
  } catch (e) {
    throw e.message;
  }
}

async function patchRessource(auth, IRI, data) {
  try {
    const response = await client(auth, IRI, {
      method: "PATCH",
      data: data,
      headers: { "content-type": "application/merge-patch+json" },
    });
    return response;
  } catch (e) {
    throw e.message;
  }
}

async function deleteRessource(auth, IRI) {
  try {
    const response = await client(auth, IRI, { method: "delete" });
    return response;
  } catch (e) {
    throw e.message;
  }
}

/**
 * Helper function used to serialized object as query string
 */
function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return "?" + str.join("&");
}

/**
 * Global function handling every requests send to the API
 * @param {Bool} authRequired - Tells if the user authentication is required or not (= auth token should be added to the header)
 * @param {endpoint} The URL to fetch the API to
 */
async function client(authRequired, endpoint, { data, ...customConfig } = {}) {
  const headers = { "content-type": "application/json" };

  let queryStr = "";
  const method = customConfig.method
    ? customConfig.method
    : data
    ? "POST"
    : "GET";

  // If the method is GET and there is data, they need to be serialzied and added to the URL
  if (method === "GET" && data) {
    queryStr = serialize(data);
    data = null;
  }

  // Inject the user token is authentication is required
  if (authRequired) {
    const token = authClient.getAuthToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  }

  const config = {
    method: method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    data: data,
  };

  try {
    const r = await axios(
      `${process.env.REACT_APP_API_URL}${endpoint}${queryStr}`,
      config
    );
    return r.data;
  } catch (error) {
    console.log("error =>", error.response);
    let msg = "";
    if (error.response.status === 403) {
      msg = error.response.data.message;
    } else {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // msg = "Erreur " + error.response.status + " : " + error.response.data.message;

        /**
         * TODO : best handling of errors messages : API should return error code that the front app should be able to translate thanks to a dictionanry.
         * Today we only manage a generic message as the reeturned resultat by the API is not consistent.
         */
        msg =
          "Une erreur est survenue lors du traitement de l'opération, merci de rééssayer plus tard. Notre équipe technique a été avertie et travaille à la résolution du problème.";
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        msg =
          "Un problème de communication avec le serveur est survenu, merci de réessayer plus tard. Notre équipe technique a été avertie et travaille à la résolution du problème.";
      } else {
        // Something happened in setting up the request that triggered an Error
        msg = error.message;
      }
    }
    throw new Error(msg);
  }
}

export default client;
export {
  get,
  getRessource,
  getRessources,
  createRessource,
  updateRessource,
  patchRessource,
  deleteRessource,
  getPostedData,
};
