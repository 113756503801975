import React, { useState } from "react";
import { useAuth } from "../../../../context/authContext";

// Material-ui
import { Typography } from "@material-ui/core";

// Utils
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { toast } from "react-toastify";

const chartDataUrl = "/api/stats/genders";

export default function GlobalGenderPotentialChart(props) {
  const { colors } = { ...props };

  const [isPending, setIsPending] = useState(true);
  const [chartData, setChartData] = useState([]);
  const { apiManager } = useAuth();

  React.useEffect(() => {
    // Fetch the chart data
    function getChartData() {
      setIsPending(true);
      apiManager
        .get(chartDataUrl)
        .then((data) => {
          setChartData(data);
        })
        .catch((e) => {
          setChartData([]);
          toast.error(e);
        })
        .finally(() => setIsPending(false));
    }
    getChartData();
  }, [apiManager]); // apiManager will never change during the lycicle of the comoonent, so it should never re-run the effect

  const renderCustomizedLabel = (props) => {
    const { x, y, cx, cy, percent, name, fill, value } = { ...props };

    let textAnchor = "start";
    let xPos = x + 10;
    let yPos = y - 5;
    if (x < cx) {
      textAnchor = "end";
      xPos = x - 10;
    }

    return (
      <g fill={fill}>
        <text
          x={xPos}
          y={yPos}
          cx={cx}
          cy={cy}
          fill={fill}
          textAnchor={textAnchor}
          dominantBaseline="central"
          style={{ fontWeight: "bolder" }}
        >
          {name}
        </text>
        <text
          x={xPos}
          y={yPos + 20}
          cx={cx}
          cy={cy}
          fill={fill}
          textAnchor={textAnchor}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`} ({value})
        </text>
      </g>
    );
  };

  return isPending ? (
    "Chargement en cours"
  ) : (
    <div style={{ width: "100%", height: "350px" }}>
      <Typography variant="h6" component="h3">
        Potentiel par genre
      </Typography>
      <ResponsiveContainer width="100%">
        <PieChart>
          <Pie
            isAnimationActive={false}
            data={chartData}
            cx={170}
            cy={140}
            outerRadius="60%"
            innerRadius="40%"
            dataKey="POTENTIEL"
            dataName="SEXE"
            label={renderCustomizedLabel}
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
                name={entry.SEXE === "H" ? "Hommes" : "Femmes"}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
