import React, { useState } from 'react'


// Material-ui / dependencies
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core/'
import { SketchPicker } from 'react-color'


const useStyles = makeStyles(theme => ({
  root: {
    
  },
  color: {
    width: '60px',
    height: '36px',
    borderRadius: '2px',
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));


export default function ColorPicker(props){
  
  const classes = useStyles()
  const { onChange, color } = {...props}

  const [ state, setState ] = useState({
    displayColorPicker: false,
    color: color ? color : '',
  })

  const handleClick = () => {
    setState((prevState) =>  { return {...prevState, displayColorPicker: !state.displayColorPicker } })
  }

  const handleClose = () => {
    setState((prevState) =>  { return {...prevState, displayColorPicker: false } })
  }

  const handleColorChange = (color) => {
    onChange(color)
    setState((prevState) =>  { return {...prevState, color: color } } )
  }

  return (
    <Box className={classes.root}>
      <Box className={ classes.swatch } onClick={ handleClick }>
        <Box className={ classes.color} style={ { background: state.color.hex } } />
      </Box>
      { state.displayColorPicker ? 
        <Box className={ classes.popover }>
          <Box className={ classes.cover } onClick={ handleClose }/>
          <SketchPicker color={ state.color } onChange={ handleColorChange } disableAlpha />
        </Box> : null 
      }
    </Box>
  )

  
}