import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import moment from "moment";

import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import CloseIcon from "@material-ui/icons/Close";

import TextBox from "../forms/TextBox";

import { useAuth } from "../../context/authContext";

import "moment/locale/fr";

const drawerWidth = window.innerWidth;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowY: "hidden",
  },
  content: {
    padding: theme.spacing(2),
    marginTop: 48,
    marginBottom: 68,
    overflowY: "auto",
    minHeight: `calc(100% - 32px)`,
  },
  loaderWrapper: {
    width: drawerWidth,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusWrapper: {
    flexDirection: "row",
    marginTop: 32,
  },
  redChip: {
    backgroundColor: "orangered",
    color: "white",
    marginLeft: 16,
  },
  blueChip: {
    backgroundColor: "deepskyblue",
    color: "white",
    marginLeft: 16,
  },
  greenChip: {
    backgroundColor: "lawngreen",
    color: "white",
    marginLeft: 16,
  },
  yellowChip: {
    backgroundColor: "gold",
    color: "white",
    marginLeft: 16,
  },
  resultText: {
    cursor: "pointer",

    "&:hover": {
      textDecoration: "underline",
    },
  },
  title: {
    marginBottom: 8,
  },
}));

export default ({ open, handleDrawerClose, modalData }) => {
  let history = useHistory();
  const classes = useStyles();
  const { authManager, apiManager, isAdmin } = useAuth();
  const [campaignData, setCampaignData] = useState(null);

  useEffect(() => {
    if (open) {
      getCampaignDetails();
    }
  }, [open]);

  const handleClose = () => {
    handleDrawerClose();
  };

  const getCampaignDetails = async () => {
    try {
      const result = await apiManager.getRessource(modalData?.campaign["@id"]);
      if (result) {
        setCampaignData(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderStatus = () => {
    if (isAdmin) {
      switch (modalData?.orderStatus) {
        case 1:
          return <Chip label="BAT à envoyer" className={classes.redChip} />;
        case 2:
          return (
            <Chip
              label="BAT envoyé + Cible à faire"
              className={classes.redChip}
            />
          );
        case 3:
          return (
            <Chip label="BAT envoyé + Cible OK" className={classes.redChip} />
          );
        case 4:
          return (
            <Chip label="BAT OK + Cible à faire" className={classes.redChip} />
          );
        case 5:
          return <Chip label="À programmer" className={classes.blueChip} />;
        case 6:
          return <Chip label="Terminée" className={classes.greenChip} />;
        case 7:
          return (
            <Chip label="Reporting à faire" className={classes.greenChip} />
          );
        case 8:
          return (
            <Chip label="Reporting à valider" className={classes.greenChip} />
          );
        case 9:
          return <Chip label="En attente" className={classes.yellowChip} />;
        case 10:
          return <Chip label="Annulée" />;
        default:
          return "Erreur";
      }
    } else {
      switch (modalData?.orderStatus) {
        case 1:
          return (
            <Chip
              label="Campagne en cours de traitement"
              className={classes.redChip}
            />
          );
        case 2:
          return (
            <Chip label="Campagne programmée" className={classes.blueChip} />
          );
        case 3:
          return (
            <Chip label="Campagne terminée" className={classes.greenChip} />
          );
        case 4:
          return (
            <Chip label="Campagne en attente" className={classes.yellowChip} />
          );
        case 5:
          return <Chip label="Annulée" />;
        default:
          return "Erreur";
      }
    }
  };

  const onSeeResultsPress = () => {
    history.push({
      pathname: "/queries",
      state: { queryItem: campaignData?.query["@id"] },
    });
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={handleClose}
    >
      <AppBar position="absolute" color="secondary">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={classes.content}>
        {campaignData ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="overline">
                  {`Detail de la commande ${modalData?.orderNum}`}
                </Typography>
                <Typography variant="h4" className={classes.title}>
                  {modalData?.campaign.campaignName}
                </Typography>
                <Typography
                  onClick={onSeeResultsPress}
                  component="span"
                  className={classes.resultText}
                >
                  Voir résultat
                </Typography>
                <div className={classes.statusWrapper}>
                  <Typography component="span">Statut:</Typography>
                  {renderStatus()}
                </div>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h5">Créée par :</Typography>
                <TextBox
                  id="brand-name"
                  label="Organisation"
                  value={modalData?.userAccount?.organization?.organizationName}
                  disabled
                />
                <TextBox
                  id="full-name"
                  label="Par"
                  value={modalData?.userAccount?.fullName}
                  disabled
                />
                <TextBox
                  id="email"
                  label="Email"
                  value={modalData?.userAccount?.email}
                  disabled
                />
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Annonceur :</Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextBox
                    id="brand-name"
                    label="Enseigne"
                    value={modalData?.customer?.BrandName}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id="company-name"
                    label="Raison sociale"
                    value={modalData?.customer?.CompanyName}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id="employee-registration-number"
                    label="N° identifiant client *"
                    value={modalData?.customer?.EmployeeID}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id="sub-account"
                    label="Sous compte"
                    value={modalData?.customer?.SubAccount}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Campagne :</Typography>
                </Grid>

                <Grid item xs={6}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale="fr"
                  >
                    <DateTimePicker
                      ampm={false}
                      value={modalData?.campaign?.campaignStartDate}
                      label="Date et heure d'envoi"
                      inputVariant="outlined"
                      margin="dense"
                      format="dddd DD MMMM YYYY [à] HH[h]mm"
                      fullWidth
                      minutesStep={15}
                      disabled
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id="url-box"
                    label="URL"
                    value={modalData?.campaign?.campaignBody[0]?.msgURL}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id="volume-box"
                    label="Volume envoyé"
                    value={modalData?.campaign?.campaignVolume}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id="unsubscribe-box"
                    label="Désinscription"
                    value={"STOP AU 36105"}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id="sender-box"
                    label="Emetteur"
                    value={modalData?.campaign?.campaignBody[0]?.msgSender}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id="interlocutor-box"
                    label="Interlocuteurs BAT"
                    value={campaignData?.shootingRecipients?.[0]}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id="message-box"
                    label="Message"
                    value={modalData?.campaign?.campaignBody[0]?.msgBody}
                    disabled
                    multiline
                    rows={5}
                    rowsMax={20}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextBox
                    id="comments-box"
                    label="Commentaire"
                    value={campaignData?.comment}
                    disabled
                    multiline
                    rows={5}
                    rowsMax={20}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Pack :</Typography>
                </Grid>

                <Grid item xs={4}>
                  <TextBox
                    id="pack-date"
                    label="Date de création"
                    value={moment
                      .utc(modalData?.customerPack?.createdAt)
                      .format("DD/MM/YYYY")}
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextBox
                    id="pack-name"
                    label="Nom du pack"
                    value={modalData?.customerPack?.packName}
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextBox
                    id="pack-volume"
                    label="Volume"
                    value={modalData?.customerPack?.volume}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    id="pack-description"
                    label="Description"
                    value={modalData?.customerPack?.packDesc}
                    disabled
                    multiline
                    rows={5}
                    rowsMax={20}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Box className={classes.loaderWrapper}>
            <CircularProgress size={48} color="secondary" />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};
