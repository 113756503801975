import React from "react";
import { NavLink } from "react-router-dom";

// Context
import { useAuth } from "../../context/authContext";
import { useTheme } from "../../context/themeContext";

// Material-ui
import { Divider, Drawer, Hidden, Collapse } from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core/";

// Nav icons from material-ui
import HomeIcon from "@material-ui/icons/Home";
import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import SettingsIcon from "@material-ui/icons/Settings";
import PeopleIcon from "@material-ui/icons/People";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import BusinessIcon from "@material-ui/icons/Business";
import TelegramIcon from "@material-ui/icons/Telegram";
import ContactsIcon from "@material-ui/icons/Contacts";
import ReceiptIcon from "@material-ui/icons/Receipt";

export default function LeftPane(props) {
  const classes = props.useStyles();

  const { isAdmin, isCounter, isUserOrgAdmin } = useAuth();

  const { getLogoUrl } = useTheme();

  const [openAdmin, setOpenAdmin] = React.useState(true);

  const handleAdminMenuClick = () => {
    setOpenAdmin(!openAdmin);
  };

  const drawer = (
    <div id="left-pane">
      <div className={classes.toolbar}>
        {" "}
        {/*Simulate a toolbar container to align element with the actual header toolbar*/}
        <img src={getLogoUrl()} alt="Logo" />
      </div>
      <List>
        {isAdmin && (
          <ListItem
            button
            key="dashboard"
            component={NavLink}
            to="/"
            className={classes.listitem}
            exact
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Tableau de bord" />
          </ListItem>
        )}

        {isCounter ? (
          <>
            <ListItem
              button
              key="query"
              component={NavLink}
              to="/queries"
              className={classes.listitem}
              exact
            >
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary="Comptages" />
            </ListItem>

            <ListItem
              button
              key="folders"
              className={classes.nested}
              component={NavLink}
              to="/folders"
              exact
            >
              <ListItemIcon>
                <FolderOpenIcon />
              </ListItemIcon>
              <ListItemText primary="Mes dossiers" />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem
              button
              key="query"
              component={NavLink}
              to="/queries"
              className={classes.listitem}
              exact
            >
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary="Comptages" />
            </ListItem>

            <ListItem
              button
              key="folders"
              className={classes.nested}
              component={NavLink}
              to="/folders"
              exact
            >
              <ListItemIcon>
                <FolderOpenIcon />
              </ListItemIcon>
              <ListItemText primary="Mes dossiers" />
            </ListItem>

            <ListItem
              button
              key="campaigns"
              component={NavLink}
              to="/campaigns"
              className={classes.listitem}
              exact
            >
              <ListItemIcon>
                <TelegramIcon />
              </ListItemIcon>
              <ListItemText primary="Campagnes" />
            </ListItem>

            <ListItem
              button
              key="advertisers"
              component={NavLink}
              to="/advertisers"
              className={classes.listitem}
              exact
            >
              <ListItemIcon>
                <ContactsIcon />
              </ListItemIcon>
              <ListItemText primary="Annonceurs" />
            </ListItem>
            <ListItem
              button
              key="orders"
              className={classes.listitem}
              component={NavLink}
              to="/orders"
              exact
            >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="Commandes" />
            </ListItem>
          </>
        )}
      </List>

      <Divider />
      {isAdmin && (
        <List>
          <ListItem
            button
            key="admin-menu"
            className={classes.listitem}
            onClick={handleAdminMenuClick}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Administration" />
            {openAdmin ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openAdmin} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <React.Fragment>
                <ListItem
                  button
                  key="organizations"
                  className={classes.nested}
                  component={NavLink}
                  to="/organizations"
                  exact
                >
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary="Organisations" />
                </ListItem>

                <ListItem
                  button
                  key="users"
                  className={classes.nested}
                  component={NavLink}
                  to="/users"
                  exact
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Utilisateurs" />
                </ListItem>

                <ListItem
                  button
                  key="packs"
                  className={classes.nested}
                  component={NavLink}
                  to="/packs"
                  exact
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Packs" />
                </ListItem>

                {!isUserOrgAdmin() && (
                  <ListItem
                    button
                    key="templates"
                    className={classes.nested}
                    component={NavLink}
                    to="/templates"
                    exact
                  >
                    <ListItemIcon>
                      <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary="Templates" />
                  </ListItem>
                )}
              </React.Fragment>
            </List>
          </Collapse>
        </List>
      )}
    </div>
  );

  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={props.open}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open={props.open}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}
