import React, { useState } from "react";
import { useAuth } from "./authContext";
import { themeHelper } from "../utils/datamatchHelpers";

// Logo
import defaultLogo from "../assets/img/logo.png";

const ThemeContext = React.createContext();
const defaultThemeOptions = themeHelper.getDefaultThemeOptions();

/**
 * The Theme provider define a default theme for the application
 * A login user may have a custom theme depending on its organization configuration
 * If a theme is available, the themeProvider will provide it.
 * @param {*} props
 */
export const ThemeProvider = (props) => {
  const { authManager, isAuthenticated, apiManager } = useAuth();
  const user = authManager.getUser();

  const [themeOptions, setThemeOptions] = useState(defaultThemeOptions);
  // const [ logoUrl, setLogoUrl ] = useState()
  const [isPending, setIsPending] = useState(false);

  React.useEffect(() => {
    /**
     * Fetching user organization theme data if :
     * - A user is logged in
     * - The user organization has a registered theme
     * - The fetching is not currently in progress
     */
    if (isAuthenticated() && !isPending && user.theme) {
      setIsPending(true);

      // Fetching the theme for the current user
      // Remove '/api' when the API has been updated
      apiManager
        .getRessource(`${user.theme}`)
        .then((themeOptions) => {
          // Theme is available for the user organization
          if (themeOptions.isEnabled) {
            setThemeOptions(themeOptions);
          }
          // No theme available : using default theme options
          else {
            setThemeOptions(defaultThemeOptions);
          }
        })
        .catch((error) => setThemeOptions(defaultThemeOptions)) // Default theme option in case of error
        .finally(() => {
          setIsPending(false);
        });
    } else {
      setThemeOptions(defaultThemeOptions); // Default theme for unlogged user
    }
  }, [isAuthenticated()]);

  const updateTheme = (options) => {
    setThemeOptions(options);
  };

  const getLogoUrl = () => {
    if (themeOptions.isEnabled && themeOptions.logoUrl) {
      return themeOptions.logoUrl;
    }

    return defaultLogo;
  };

  const getMuiThemeOptions = () => {
    return themeHelper.getMuiThemeOptions(themeOptions);
  };

  return (
    <ThemeContext.Provider
      value={{ getMuiThemeOptions, isPending, updateTheme, getLogoUrl }}
      {...props}
    />
  );
};

export const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error(`useTheme must be used within a ThemeProvider`);
  }
  return context;
};
