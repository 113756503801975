import React from 'react';

// Components
import MainTemplate from '../../../components/templates/main'
import PotentialByGenders from './PotentialByGenders/'
import PotentialByPartners from './PotentialByPartners/'

export default function Dashboard() {

  return (
    <MainTemplate 
      pageCategory="Dashboard"
      pageTitle="Visualisation des données statistiques"
    >
      <PotentialByGenders />

      <PotentialByPartners />

    </MainTemplate>
  )
}