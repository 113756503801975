import React from 'react'
import { useAuth } from '../../../context/authContext'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import { Grid, TextField, FormLabel, Switch, FormControlLabel } from '@material-ui/core/'
import LoaderButton from '../../../components/LoaderButton'
import RoleSelect from './roleSelect'
import OrgSelect from './orgSelect'

// Form validation 
import * as Yup from 'yup' 
import { Formik, Form } from 'formik'

// Notification
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  form:{
    width: '70%',
    maxWidth: '600px',
  }, 
  select: {
    width: '100%'
  }
}));

/**
 * The UserForm component can be used in any context 
 * @param {*} props 
 */
function UserForm(props){

  const { apiManager } = useAuth()
  const classes = useStyles()
  const { userData, setUserData, orgIRI } = {...props}
  
  /**
   * Return true if the userData are defined
   */
  function isEditMode(){ return userData ? true : false }

  /**
   * Handling form submition
   */
  function handleFormSubmit(values, actions){

    let newData = {
      ...values, 
      roles: [values.roles],
    }

    /**
     * Form submission depends on the mode
     */
    if(isEditMode()){
      // Edition mode
      apiManager.patchRessource(userData['@id'], newData)
        .then(
          // Success
          (response) => {
            toast.success('Utilisateur modifié avec succès')
            actions.setSubmitting(false)
            setUserData(response)
          },
          // Error
          (error) => {
            toast.error(error)
            actions.setSubmitting(false)
          }
        )
    }
    else{

      // Creation mode
      apiManager.createRessource('userAccounts', newData)
        .then( (userData) => {
          toast.success('Utilisateur créé avec succès')
          setUserData(userData)
          /**
           * Manually triggering some actions after user creation (basicallay: sending a confirmation mail so the user can set his password)
           * TODO: should be handle on the API side and then removed from there
           */
          apiManager.get('/api/user_accounts/created/'+userData.id)
            .then( response => {
              toast.success('Un mail de confirmation vient d\'être envoyé à l\'utilisateur')
            })
            .catch( error => {
              toast.error(error)
            })
        })
        .catch( (error) => {
          toast.error(error)
        })
        .finally(() => { actions.setSubmitting(false) })
    }
  }
  
  /**
   * Check if the hydra id of an organization correspond to the ADLP org. 
   * For this org, the only possible role is ROLE_ADMIN
   * @param {String} orgIRI : the Hydra id of an organization
   */
  function isSuperAdmin(orgIRI){
    return (orgIRI === '/organizations/1')
  }

  /**
   * Generate the object used as default value for the form
   */
  function getInitialValue(){

    // Initialization for edit mode
    if(userData){

      return {
        // TODO: the API should only return the IRI of the organization and not an object
        organization: userData.organization['@id'], // IRI of the organization given in the user Data
        email: userData.email,
        lastName: userData.lastName,
        firstName: userData.firstName,
        roles: userData.roles[0], // In edition mode roles are coming back from the API as an array. But in the form it is treated as a string
        isEnabled: userData.isEnabled
      }
    }
    else{ // Creation mode

      // In creation mode the role depends on the IRI given as a parameter of the form
      const roles = isSuperAdmin(orgIRI) ? 'ROLE_ADMIN' : ''

      // Initialization for create mode
      return {
        organization: orgIRI ? orgIRI : '', // IRI of the organization
        email: '',
        lastName: '',
        firstName: '',
        roles: roles, 
        isEnabled: false
      }
    }
  }

  
  
  /**
   * Handling form validation
   */
  const validationSchema = Yup.object().shape({
    organization: Yup.string().required(),
    roles: Yup.string().required(),
    lastName: Yup.string().required(),
    firstName: Yup.string().required(),
    email: Yup.string().email().required(),
    isEnabled: Yup.boolean()
  })

  const formValues = getInitialValue() 

  return (
    <Formik 
      key={userData ? userData['@id'] : 'newUserForm'}
      initialValues={ formValues }
      onSubmit={(values, actions) => {
        handleFormSubmit(values, actions)
      }}
      validationSchema={validationSchema}
      render={({
        values,
        errors,
        touched,
        handleChange,
        isSubmitting,
        setFieldValue
      }) => (
        
        <Form className={classes.form} noValidate >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.isEnabled}
                    onChange={handleChange}
                    value={values.isEnabled}
                    name='isEnabled'
                    id='isEnabled'
                    color='primary'
                  />
                }
                label={ values.isEnabled ? 'Désactiver l\'utilisateur' : 'Activer l\'utilisateur'}
              />
              
            </Grid>
            <Grid item xs={12}>
              {isEditMode() ? (
                  <a href={`mailto:${values.email}`} target='_blank' rel='noopener noreferrer'>{values.email}</a>
                )
              : (
                  <TextField
                    variant='outlined'
                    margin='dense'
                    id='email'
                    label='Adresse email'
                    name='email'
                    autoComplete='email'
                    autoFocus
                    type='email'
                    fullWidth
                    className={classes.input}
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={errors.email && touched.email &&<FormLabel error>{errors.email}</FormLabel>}
                  />
                )
              }
            </Grid>
            <Grid item xs={12} md={8}>
              <OrgSelect 
                value={values.organization}
                error={Boolean(touched.organization) && Boolean(errors.organization)}
                isSuperAdmin={isSuperAdmin}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RoleSelect 
                organization={values.organization} 
                value={values.roles}
                handleChange={handleChange} 
                error={Boolean(touched.roles) && Boolean(errors.roles) ? errors.roles : ''}
                isSuperAdmin={isSuperAdmin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant='outlined'
                margin='dense'
                id='lastName'
                label='Nom'
                name='lastName'
                autoComplete='lastName'
                type='text'
                fullWidth
                className={classes.input}
                error={touched.lastName && Boolean(errors.lastName)}
                onChange={handleChange}
                value={values.lastName}
                helperText={errors.lastName && touched.lastName &&<FormLabel error>{errors.lastName}</FormLabel>}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant='outlined'
                margin='dense'
                id='firstName'
                label='Prénom'
                name='firstName'
                autoComplete='firstName'
                type='text'
                fullWidth
                className={classes.input}
                error={touched.firstName && Boolean(errors.firstName)}
                onChange={handleChange}
                value={values.firstName}
                helperText={errors.firstName && touched.firstName &&<FormLabel error>{errors.firstName}</FormLabel>}
              />
            </Grid>
            <Grid item xs={12}>
              <LoaderButton 
                isSubmitting={isSubmitting} 
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                disabled={isSubmitting}
              >
                { isEditMode() ? 'Modifier l\'utilisateur' : 'Créer l\'utilisateur'}
              </LoaderButton>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  )
}

UserForm.propTypes = {
  userData: PropTypes.object, // Optional userData for edit mode
  setUserData: PropTypes.func.isRequired // Required setUserData provided by the parent component
}

export default UserForm;