import React, { useEffect } from "react";
import mapboxgl from "mapbox-gl";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Map = ({ className, coordinates, center, zdcCoordinates }) => {
  mapboxgl.accessToken =
    "pk.eyJ1IjoibGF1cmVudC1kZXNjYW1wcyIsImEiOiJja2loNWFsbnkxdTNyMnJwZXJsa294cGt0In0.3ncndIx8GEQwOwnaG0uulA";

  useEffect(() => {
    let isMounted = true;

    const map = new mapboxgl.Map({
      container: "mapContainer",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [46.2276, 2.2137],
      zoom: 1,
    });

    if (isMounted) {
      map.addControl(new mapboxgl.NavigationControl(), "top-right");

      map.on("load", function () {
        map.setLayoutProperty("country-label", "text-field", [
          "get",
          "name_fr",
        ]);
        map.setLayoutProperty("state-label", "text-field", ["get", "name_fr"]);
        map.setLayoutProperty("settlement-label", "text-field", [
          "get",
          "name_fr",
        ]);
        map.setLayoutProperty("settlement-subdivision-label", "text-field", [
          "get",
          "name_fr",
        ]);

        if (center && center.length > 0) {
          if (center.length === 1) {
            map.panTo(center[0], {
              padding: { top: 64, bottom: 64, left: 64, right: 64 },
              maxZoom: 10.75,
            });
            map.setMinZoom(10.75);
          } else {
            var bounds = center.reduce(function (bounds, coord) {
              return bounds.extend(coord);
            }, new mapboxgl.LngLatBounds(center[0], center[0]));

            map.fitBounds(bounds, {
              padding: { top: 64, bottom: 64, left: 64, right: 64 },
              maxZoom: 10.75,
            });
          }
        }
      });

      map.on("idle", function () {
        let mapLoaded = map.loaded();

        if (mapLoaded && coordinates) {
          coordinates.forEach((item, index) => {
            if (!map.getSource(`layer-${index}`)) {
              map.addSource(
                `layer-${index}`, // identifiant unique de la source
                {
                  type: "geojson",
                  data: {
                    geometry: {
                      coordinates: item,
                      type: "Polygon",
                    },
                    type: "Feature",
                    properties: {
                      name: "Highlighted city",
                    },
                  },
                }
              );

              map.addLayer({
                id: `layer-${index}-fill`,
                type: "fill",
                source: `layer-${index}`,
                layout: {},
                paint: {
                  "fill-color": "#1E90FF",
                  "fill-opacity": 0.8,
                },
              });

              map.addLayer({
                id: `layer-${index}-line`,
                type: "line",
                source: `layer-${index}`,
                layout: {
                  "line-cap": "round",
                },
                paint: {
                  "line-dasharray": [1, 2],
                  "line-color": "#fff",
                  "line-width": 2,
                },
              });
            }
          });
        }

        if (mapLoaded && zdcCoordinates) {
          zdcCoordinates.forEach((item, index) => {
            if (!map.getSource(`layer-zdc-${index}`)) {
              map.addSource(
                `layer-zdc-${index}`, // identifiant unique de la source
                {
                  type: "geojson",
                  data: {
                    geometry: {
                      coordinates: item,
                      type: "Polygon",
                    },
                    type: "Feature",
                    properties: {
                      name: "Highlighted city",
                    },
                  },
                }
              );

              map.addLayer({
                id: `layer-zdc-${index}-fill`,
                type: "fill",
                source: `layer-zdc-${index}`,
                layout: {},
                paint: {
                  "fill-color": "#99c24d",
                  "fill-opacity": 0.8,
                },
              });

              map.addLayer({
                id: `layer-zdc-${index}-line`,
                type: "line",
                source: `layer-zdc-${index}`,
                layout: {
                  "line-cap": "round",
                },
                paint: {
                  "line-dasharray": [1, 2],
                  "line-color": "#fff",
                  "line-width": 2,
                },
              });
            }
          });
        }
      });
    }

    return () => {
      map.remove();
      isMounted = false;
    };
  }, [coordinates, center, zdcCoordinates]);

  return <div id="mapContainer" className={className}></div>;
};

export default Map;
