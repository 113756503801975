import React, { useState } from "react";

import TopBar from "./TopBar";
import MainPane from "./MainPane";
import LeftPane from "./LeftPane";

import { CssBaseline } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;
const menuBreakpoint = "lg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up(menuBreakpoint)]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  drawer: {
    [theme.breakpoints.up(menuBreakpoint)]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  title: {
    flexGrow: 1,
    textTransform: "uppercase",
  },
  welcomeMsg: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    padding: theme.spacing(1, 2, 1, 2),
    borderRadius: "5px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(menuBreakpoint)]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  listitem: {
    "&.active": {
      background: theme.palette.secondary.main,
    },
    "&.active *": {
      color: "#ffffff",
      fontWeight: "bolder",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    "&.active": {
      background: theme.palette.secondary.main,
    },
    "&.active *": {
      color: "#ffffff",
      fontWeight: "bolder",
    },
  },
}));

export default function Layout(props) {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <TopBar useStyles={useStyles} handleDrawerToggle={handleDrawerToggle} />

      <LeftPane
        useStyles={useStyles}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
      />

      <MainPane useStyles={useStyles} className={classes.content}>
        {props.children}
      </MainPane>
    </div>
  );
}
