import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import useStateWithCallback from "use-state-with-callback";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import SearchIcon from "@material-ui/icons/Search";

import TextBox from "../../components/forms/TextBox";
import SearchBoxList from "../../components/forms/SearchBoxList";

import Breadcrumbs from "../../components/general/Breadcrumbs";

import Accordion from "../../components/countsList/Accordion";
import CountModal from "../../components/countsList/CountModal";
import ExtractionModal from "../../components/countsList/ExtractionModal";

import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "../../context/authContext";

const useStyles = makeStyles((theme) => ({
  panelWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[2],
  },
  countsTitleWrapper: {
    margin: theme.spacing(1, 0),
  },
  listWrapper: {
    flexGrow: 1,
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4, 2),
  },
}));

export default (props) => {
  const classes = useStyles();
  let history = useHistory();
  let location = useLocation();

  const { authManager, apiManager } = useAuth();
  const currentUser = authManager.getUser();

  const [countsListItems, setCountsListItems] = useStateWithCallback(
    null,
    (data) => {
      if (!data) {
        setListLoading(true);
      } else {
        setListLoading(false);
      }
    }
  );

  const [countsListItemsFiltered, setCountsListItemsFiltered] =
    useStateWithCallback(null, (data) => {
      if (!data) {
        setListLoading(true);
      } else {
        setListLoading(false);
      }
    });

  const [listLoading, setListLoading] = useState(true);
  const [organizations, setOrganizations] = useState(null);
  const [users, setUsers] = useState(null);
  const [folders, setFolders] = useState(null);

  const [organizationValue, setOrganizationValue] = useState(null);
  const [organizationInputValue, setOrganizationInputValue] = useState("");

  const [userValue, setUserValue] = useState(null);
  const [userInputValue, setUserInputValue] = useState("");

  const [folderValue, setFolderValue] = useState(null);
  const [folderInputValue, setFolderInputValue] = useState("");

  const [newFolderName, setNewFolderName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [isMe, setIsMe] = useState(null);

  const [extractionOpen, setExtractionOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalReadOnly, setModalReadyOnly] = useState(null);

  const getOrganizationsAndUsersCallback = useCallback(
    getOrganizationsAndUsers,
    []
  );

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getOrganizationsAndUsersCallback();

      if (location && location.state) {
        openModalFromOrders();
      }
    }

    return () => {
      isMounted = false;
    };
  }, [getOrganizationsAndUsersCallback]);

  async function openModalFromOrders() {
    const data = location.state.queryItem;

    try {
      toast.info("Chargement du comptage en cours...");
      const result = await apiManager.getRessource(data);

      setModalData(result);
      setTimeout(() => setModalOpen(true), 750);
      setModalReadyOnly(true);
    } catch (e) {
      console.log(e);
    }
  }

  async function getOrganizationsAndUsers() {
    try {
      const result = await apiManager.getRessources("organizations", {
        "order[organizationName]": "ASC",
      });

      if (result) {
        setOrganizations(result["hydra:member"]);

        const myOrganization = result["hydra:member"].find((result) => {
          return result["@id"] === `${currentUser.organization}`;
        });

        setOrganizationValue(myOrganization);

        try {
          const result = await apiManager.getRessource(
            `/api/organizations/${myOrganization.id}/user_accounts`
          );

          if (result) {
            setUsers(result["hydra:member"]);

            const me = result["hydra:member"].find((result) => {
              return result["@id"] === `${currentUser.userAccount}`;
            });

            setUserValue(me);
          }
        } catch (e) {
          console.log(e);
        }

        getUsersFolders(currentUser.userAccount);
        getUserQueries(currentUser.userAccount);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function getUsersFolders(id) {
    try {
      const result = await apiManager.getRessource(`${id}/query_folders`);

      if (result) {
        setFolders(result["hydra:member"]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function getUserQueries(id) {
    setCountsListItemsFiltered(null);
    setIsMe(currentUser.userAccount === id);

    try {
      const result = await apiManager.getRessource(`${id}/queries`);
      const data = await result["hydra:member"];

      if (data) {
        setCountsListItems(data);
        setCountsListItemsFiltered(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function getUserQueriesByFolder(id) {
    if (id) {
      let arrayFiltered = countsListItems.filter((item) => {
        return item.queryFolder === id;
      });
      setCountsListItemsFiltered(arrayFiltered);
    } else {
      setCountsListItemsFiltered([...countsListItems]);
    }
  }

  async function createFolder(folderName, userAccount) {
    const sameTitle = folders.find((item) => {
      return item.folderName === folderName;
    });

    if (sameTitle) {
      toast.error("Ce nom existe déjà.");
    } else {
      try {
        const result = await apiManager.createRessource("queryFolders", {
          folderName: folderName,
          userAccount: userAccount,
        });

        if (result) {
          toast.success("Dossier créé avec succès.");
          getUsersFolders(currentUser.userAccount);
        }
      } catch (e) {
        toast.error("Le champs ne peut pas être vide.");
      }
    }
  }

  const handleCreateFolderChange = (event) => {
    setNewFolderName(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    let query = event.target.value;

    if (query === "" || query === null) {
      setCountsListItemsFiltered([...countsListItems]);
    } else {
      let arrayFiltered = countsListItems.filter((item) => {
        return item.queryName
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setCountsListItemsFiltered(arrayFiltered);
    }
  };

  const handleExtractClick = (event, data) => {
    event.stopPropagation();
    setExtractionOpen(true);
    setModalData(data);
  };

  const handleModifyClick = (event, data, readOnly) => {
    event.stopPropagation();
    setModalOpen(true);
    setModalData(data);
    setModalReadyOnly(readOnly);
  };

  const handleDeleteClick = async (event, item) => {
    event.stopPropagation();

    try {
      const newFilteredArray = _.remove(
        [...countsListItemsFiltered],
        function (n) {
          return n["@id"] !== item["@id"];
        }
      );
      setCountsListItemsFiltered(newFilteredArray);

      const newArray = _.remove([...countsListItems], function (n) {
        return n["@id"] !== item["@id"];
      });
      setCountsListItems(newArray);

      await apiManager.deleteRessource(item["@id"]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleNewCampaignClick = (event, item) => {
    event.stopPropagation();
    history.push({
      pathname: "/campaigns",
      state: { queryItem: item },
    });
  };

  const updateCountsList = (id, newItem) => {
    const index = countsListItemsFiltered.findIndex((item) => {
      return item["@id"] === id;
    });

    if (index !== -1) {
      setCountsListItemsFiltered([
        ...countsListItemsFiltered.slice(0, index),
        newItem,
        ...countsListItemsFiltered.slice(index + 1),
      ]);
    }
  };

  const addToCountsList = (newItem) => {
    setCountsListItemsFiltered(_.concat(...countsListItemsFiltered, [newItem]));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="overline">Gestion des comptages</Typography>
            <Typography component="h1" variant="h4" color="initial">
              Liste des comptages
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.panelWrapper} boxShadow={3}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextBox
                  id="search-box"
                  label="Recherche rapide"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={9} />

              <Grid item xs={3}>
                <SearchBoxList
                  id="organisations-search-box"
                  loading={!organizations}
                  label="Organisations"
                  options={organizations}
                  value={organizationValue}
                  inputValue={organizationInputValue}
                  onChange={(event, newValue) => {
                    setOrganizationValue(newValue);

                    if (newValue !== null) {
                      setUsers(newValue.userAccounts);
                    }

                    if (newValue !== organizationValue) {
                      setUserValue(null);
                      setFolderValue(null);
                      setFolders([]);
                    }
                  }}
                  onInputChange={(event, newValue) => {
                    setOrganizationInputValue(newValue);
                  }}
                  getOptionLabel={(option) => option.organizationName}
                />
              </Grid>

              <Grid item xs={3}>
                <SearchBoxList
                  id="users-search-box"
                  loading={!users}
                  label="Utilisateurs"
                  options={users}
                  value={userValue}
                  inputValue={userInputValue}
                  onChange={(event, newValue) => {
                    setUserValue(newValue);

                    if (newValue !== null) {
                      getUsersFolders(newValue["@id"]);
                      getUserQueries(newValue["@id"]);
                    }

                    if (newValue !== userValue) {
                      setFolderValue(null);
                      setFolders([]);
                    }
                  }}
                  onInputChange={(event, newValue) => {
                    setUserInputValue(newValue);
                  }}
                  getOptionLabel={(option) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  noOptionsText="Séléctionnez une organisation"
                />
              </Grid>

              <Grid item xs={3}>
                <SearchBoxList
                  id="folders-search-box"
                  label="Dossiers"
                  options={folders}
                  value={folderValue}
                  inputValue={folderInputValue}
                  onChange={(event, newValue) => {
                    setFolderValue(newValue);
                    getUserQueriesByFolder(newValue && newValue["@id"]);
                  }}
                  onInputChange={(event, newValue) => {
                    setFolderInputValue(newValue);
                  }}
                  getOptionLabel={(option) => option.folderName}
                  noOptionsText="Vous n'avez pas de dossiers"
                  clearable
                />
              </Grid>

              <Grid
                item
                container
                xs={3}
                justify="center"
                alignContent="center"
              >
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  color="secondary"
                  onClick={() => {
                    if (folders.length === 0) {
                      toast.info("Vous n'avez aucun dossier de créé.");
                    } else {
                      if (folderInputValue === "") {
                        toast.error(
                          "Veuillez séléctionner un dossier pour créer une campagne."
                        );
                      } else {
                        setModalOpen(true);
                      }
                    }
                  }}
                >
                  Créer un comptage
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            className={`${classes.panelWrapper} ${classes.listWrapper}`}
            boxShadow={3}
          >
            <Breadcrumbs
              organisationName={organizationInputValue}
              userName={userInputValue}
              folderName={folderInputValue}
            />

            {userValue && (
              <Box className={classes.countsTitleWrapper}>
                <Typography variant="overline">{`${
                  countsListItemsFiltered
                    ? countsListItemsFiltered.length
                    : "..."
                } comptage(s)`}</Typography>
              </Box>
            )}

            {!userValue ? (
              <Box className={classes.loaderWrapper}>
                <Typography variant="h6" color="textSecondary" align="center">
                  Oups..
                  <br />
                  Vous devez séléctionner une organisation et un utilisateur
                  pour afficher la liste de comptages.
                </Typography>
              </Box>
            ) : listLoading ? (
              <Box className={classes.loaderWrapper}>
                <CircularProgress color="secondary" />
              </Box>
            ) : (
              <Box>
                <Grid container spacing={2}>
                  {countsListItemsFiltered &&
                    countsListItemsFiltered.map((item, index) => {
                      return (
                        <Grid key={item["@id"]} item xs={4}>
                          <Accordion
                            query={item}
                            isLocked={
                              currentUser.userAccount !== item.userAccount
                            }
                            handleExtractClick={(event) =>
                              handleExtractClick(event, item)
                            }
                            handleModifyClick={(event) =>
                              handleModifyClick(
                                event,
                                item,
                                currentUser.userAccount !== item.userAccount
                              )
                            }
                            handleDeleteClick={(event) =>
                              handleDeleteClick(event, item)
                            }
                            handleNewCampaignClick={(event) =>
                              handleNewCampaignClick(event, item)
                            }
                            userRoles={currentUser && currentUser.roles}
                            addToCountsList={addToCountsList}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            )}

            {userValue &&
              countsListItemsFiltered &&
              countsListItemsFiltered.length === 0 && (
                <Box className={classes.loaderWrapper}>
                  <Typography variant="h6" color="textSecondary" align="center">
                    Cet utilisateur n'a aucun comptage.
                  </Typography>
                </Box>
              )}
          </Box>
        </Grid>
      </Grid>

      <CountModal
        open={modalOpen}
        folders={folders}
        currentFolder={folderValue}
        modalData={modalData}
        readOnly={modalReadOnly}
        handleDrawerClose={() => {
          setModalData(null);
          setModalOpen(false);
        }}
        addToCountsList={addToCountsList}
        updateCountsList={updateCountsList}
      />

      <ExtractionModal
        open={extractionOpen}
        query={modalData}
        handleDrawerClose={() => {
          setExtractionOpen(false);
        }}
      />
    </>
  );
};
