import React, { useState, useEffect, useCallback } from "react";
import useStateWithCallback from "use-state-with-callback";
import _ from "lodash";
import { toast } from "react-toastify";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";

import MaterialTable from "material-table";

import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "../../context/authContext";
import OrderDetails from "../../components/orders/OrderDetails";
import SearchBoxList from "../../components/forms/SearchBoxList";

const useStyles = makeStyles((theme) => ({
  panelWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[2],
  },
  countsTitleWrapper: {
    margin: theme.spacing(1, 0),
  },
  listWrapper: {
    flexGrow: 1,
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4, 2),
  },
  redChip: {
    backgroundColor: "orangered",
    color: "white",
  },
  blueChip: {
    backgroundColor: "deepskyblue",
    color: "white",
  },
  greenChip: {
    backgroundColor: "lawngreen",
    color: "white",
  },
  yellowChip: {
    backgroundColor: "gold",
    color: "white",
  },
}));

const materialTableLocalization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} sur {count}",
    labelRowsSelect: "lignes",
    labelRowsPerPage: "lignes par page:",
  },
  toolbar: {
    nRowsSelected: "{0} saisie(s) séléctionnée(s)",
    exportTitle: "Exporter",
    searchPlaceholder: "Rechercher",
    searchTooltip: "Rechercher",
  },
  header: {
    actions: "Actions",
  },
  body: {
    emptyDataSourceMessage: "Aucune saisies entrées",
    filterRow: {
      filterTooltip: "Filtrer",
    },
  },
};

export default (props) => {
  const classes = useStyles();
  const { apiManager, isAdmin } = useAuth();

  const [ordersListItems, setOrdersListItems] = useStateWithCallback(
    [],
    (data) => {
      if (!data) {
        setListLoading(true);
      } else {
        setListLoading(false);
      }
    }
  );

  const [listLoading, setListLoading] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const getOrdersCallback = useCallback(getOrders, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getOrdersCallback();
    }

    return () => {
      isMounted = false;
    };
  }, [getOrdersCallback]);

  // Get orders
  async function getOrders() {
    setListLoading(true);
    try {
      const result = await apiManager.getRessource(`/api/campaign_orders`);
      const data = await result["hydra:member"];
      setListLoading(false);

      if (data) {
        setOrdersListItems(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleViewOrderDetails = (e, rowData) => {
    setModalOpen(true);
    setModalData(rowData);
  };

  const handleDrawerClose = () => {
    setModalOpen(false);
    setModalData(null);
  };

  const [statusInputValue, setStatusInputValue] = useState(null);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="overline">Gestion des commandes</Typography>
            <Typography component="h1" variant="h4" color="initial">
              Liste des commandes
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            className={`${classes.panelWrapper} ${classes.listWrapper}`}
            boxShadow={3}
          >
            <Box className={classes.countsTitleWrapper}>
              <Typography variant="overline">{`${
                ordersListItems ? ordersListItems?.length : "..."
              } commande(s)`}</Typography>
            </Box>

            {listLoading ? (
              <Box className={classes.loaderWrapper}>
                <CircularProgress color="secondary" />
              </Box>
            ) : ordersListItems?.length !== 0 ? (
              <Box>
                <Grid item xs={12}>
                  <MaterialTable
                    columns={[
                      {
                        title: "Annonceur",
                        field: "customer.CompanyName",
                        editable: "never",
                      },
                      {
                        title: "Organisation",
                        field: "userAccount.organization.organizationName",
                        editable: "never",
                      },
                      {
                        title: "Crée le",
                        render: (rowData) =>
                          moment.utc(rowData.createdAt).format("DD/MM/YYYY"),
                        editable: "never",
                        customSort: (a, b) =>
                          new Date(b.createdAt) - new Date(a.createdAt),
                        defaultSort: "asc",
                      },
                      {
                        title: "Crée par",
                        field: "userAccount.fullName",
                        editable: "never",
                      },
                      {
                        title: "N˚ de commande",
                        field: "orderNum",
                        editable: "never",
                      },
                      {
                        title: "Nom du pack",
                        field: "customerPack.packName",
                        editable: "never",
                      },
                      {
                        title: "Campagne",
                        field: "campaign.campaignName",
                        editable: "never",
                      },
                      {
                        title: "Canal",
                        field: "campaign.campaignChannel",
                        editable: "never",
                      },
                      {
                        title: "Volume",
                        field: "orderVolume",
                        editable: "never",
                      },
                      {
                        title: "Statut",
                        field: "orderStatus",
                        render: (rowData) => {
                          const status = rowData.orderStatus;

                          if (isAdmin) {
                            switch (status) {
                              case 1:
                                return (
                                  <Chip
                                    label="BAT à envoyer"
                                    className={classes.redChip}
                                  />
                                );
                              case 2:
                                return (
                                  <Chip
                                    label="BAT envoyé + Cible à faire"
                                    className={classes.redChip}
                                  />
                                );
                              case 3:
                                return (
                                  <Chip
                                    label="BAT envoyé + Cible OK"
                                    className={classes.redChip}
                                  />
                                );
                              case 4:
                                return (
                                  <Chip
                                    label="BAT OK + Cible à faire"
                                    className={classes.redChip}
                                  />
                                );
                              case 5:
                                return (
                                  <Chip
                                    label="À programmer"
                                    className={classes.blueChip}
                                  />
                                );
                              case 6:
                                return (
                                  <Chip
                                    label="Terminée"
                                    className={classes.greenChip}
                                  />
                                );
                              case 7:
                                return (
                                  <Chip
                                    label="Reporting à faire"
                                    className={classes.greenChip}
                                  />
                                );
                              case 8:
                                return (
                                  <Chip
                                    label="Reporting à valider"
                                    className={classes.greenChip}
                                  />
                                );
                              case 9:
                                return (
                                  <Chip
                                    label="En attente"
                                    className={classes.yellowChip}
                                  />
                                );
                              case 10:
                                return <Chip label="Annulée" />;
                              default:
                                return "Erreur";
                            }
                          } else {
                            if (status >= 1 && status <= 4) {
                              return (
                                <Chip
                                  label="Campagne en cours de traitement"
                                  className={classes.redChip}
                                />
                              );
                            }
                            if (status === 5) {
                              return (
                                <Chip
                                  label="Campagne programmée"
                                  className={classes.blueChip}
                                />
                              );
                            }
                            if (status >= 6 && status <= 8) {
                              return (
                                <Chip
                                  label="Campagne terminée"
                                  className={classes.greenChip}
                                />
                              );
                            }
                            if (status === 9) {
                              return (
                                <Chip
                                  label="Campagne en attente"
                                  className={classes.yellowChip}
                                />
                              );
                            }
                            if (status === 10) {
                              return <Chip label="Annulée" />;
                            }
                          }
                        },
                        lookup: isAdmin
                          ? {
                              1: "BAT à envoyer",
                              2: "BAT envoyé + Cible à faire",
                              3: "BAT envoyé + Cible OK",
                              4: "BAT OK + Cible à faire",
                              5: "À programmer",
                              6: "Terminée",
                              7: "Reporting à faire",
                              8: "Reporting à valider",
                              9: "En attente",
                              10: "Annulée",
                            }
                          : {
                              1: "Campagne en cours de traitement",
                              2: "Campagne programmée",
                              3: "Campagne terminée",
                              4: "Campagne en attente",
                              5: "Annulée",
                            },
                      },
                    ]}
                    data={ordersListItems}
                    onRowClick={handleViewOrderDetails}
                    options={{
                      exportButton: true,
                      exportAllData: true,
                      showTitle: false,
                      pageSize: 20,
                    }}
                    actions={
                      isAdmin && [
                        {
                          tooltip: "Supprimer toutes les saisies",
                          icon: "delete",
                          onClick: async (evt, data) => {
                            try {
                              toast.info(`Suppresion de ${data.campaign}..`);
                              await apiManager.deleteRessource(data["@id"]);
                              toast.success("Commande supprimée !");
                              setOrdersListItems((prevState) =>
                                _.remove(prevState, function (n) {
                                  return n["@id"] !== data["@id"];
                                })
                              );
                            } catch (e) {
                              toast.error("Erreur lors de la suppression");
                            }
                          },
                        },
                      ]
                    }
                    editable={
                      isAdmin && {
                        onRowUpdate: async (newData, oldData) => {
                          try {
                            toast.info(
                              `Mise à jour du statut de la commande..`
                            );
                            await apiManager.updateRessource(oldData["@id"], {
                              orderStatus: parseInt(newData.orderStatus),
                            });
                            toast.success(`Statut de commande mis à jour`);
                            getOrders();
                          } catch (e) {
                            toast.error(`Erreur lors de la mise à jour`);
                          }
                        },
                      }
                    }
                    localization={materialTableLocalization}
                  />
                </Grid>
              </Box>
            ) : (
              <Box className={classes.loaderWrapper}>
                <Typography variant="h6" color="textSecondary" align="center">
                  Aucune commande
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <OrderDetails
        modalData={modalData}
        open={modalOpen}
        handleDrawerClose={handleDrawerClose}
      />
    </>
  );
};
