const formMsg = {
  mixed: {
    required: 'Le champ est obligatoire',
    
  }, 
  string: {
    email: 'Le champ doit être une adresse mail valide'
  }
}

export default formMsg;