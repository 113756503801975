import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../../../context/authContext'

// Components
import MuiSelect from '../../../components/MuiSelect'

// Material-ui
import { MenuItem } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'

// Notification
import { toast } from 'react-toastify';


const useStyles = makeStyles(theme => ({
  select: {
    width: '100%'
  }
}));

/**
 * Handle the organization select input for the userAccounts form
 * The field value should be the IRI of an organization
 * @param {*} props 
 */
function OrgSelect(props){

  const { apiManager } = useAuth()
  const classes = useStyles()
  const {value, error, isSuperAdmin, handleChange, setFieldValue} = {...props}

  // Initializing orgData if not already done ()
  const [orgData, setOrgData] = useState(null)
  if(!orgData){
    apiManager.getRessources('organizations')
      .then(response => {
        setOrgData(response['hydra:member'])
      })
      .catch(e => {
        toast.error(e)
      })
  }


  /**
   * Get Organizations select items
   */
  function getOrgItems(orgData){
    const items = []

    orgData && orgData.forEach( (org, index) => {
      if(org.id === 1 ){
        items.unshift(<MenuItem key={index} value={org['@id']}>{org['organizationName']}</MenuItem>)
      }else{
        items.push(<MenuItem key={index} value={org['@id']}>{org['organizationName']}</MenuItem>)
      }
    })
    return items
  }

  return (
    <MuiSelect 
      label='Organisation'
      labelId='organization-label'
      id="organization"
      name="organization"
      value={orgData && orgData.length ? value : ''}
      error={error}
      className={classes.select}
      onChange={(e) => {
        /**
          * The possible 'roles' values depends on the selected organization. 
          * For ADLP 'org', value must be 'ROLE_ADMIN'
          * Otherwise, the user should choose a role by himslef
          */ 
         isSuperAdmin(e.target.value) ? setFieldValue('roles', 'ROLE_ADMIN') : setFieldValue('roles', '')

        // Formik handle change
        handleChange(e) 
      }}
    >
      {getOrgItems(orgData)}
    </MuiSelect>
  )

}

OrgSelect.propTypes = {
  value: PropTypes.string.isRequired, // The current selected value (an organization IRI)
  error: PropTypes.bool.isRequired, // True is the object is in error
  isSuperAdmin: PropTypes.func.isRequired, // Return true is the given organization is ADLP (facilitate ROLE_ADMIN management)
  handleChange: PropTypes.func.isRequired, // used to controlled the form input onChange
  setFieldValue: PropTypes.func.isRequired, // The formik method allowing to force the field value change
}

export default OrgSelect
