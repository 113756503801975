import React from "react";

// Material-ui
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Box, Grid } from "@material-ui/core/";

// Component
import AlertMsg from "../../../../components/AlertMsg";
import UnauthenticatedModel from "../../../../components/templates/UnauthenticatedModel";
import UserPasswordForm from "../../components/userPasswordForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  containerInfo: {
    width: "100%",
    margin: theme.spacing(2, 0, 2, 0),
  },
  paper: {
    padding: theme.spacing(4),
    margin: theme.spacing(0),
  },
}));

export default function ResetPassword() {
  const classes = useStyles();

  const pageTitle = "Réinitilisation de votre mot de passe";

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }

  let query = useQuery();

  return (
    <UnauthenticatedModel title={pageTitle}>
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid item md={12} lg={8}>
            <Box className={classes.containerInfo}>
              <AlertMsg
                severity="info"
                variant="plain"
                className={classes.boxInfo}
              >
                Vous venez d'être inscrit sur Datamatch ou vous avez demandé la
                réinitialisation de votre mot de passe. <br />
                Merci de renseigner le formulaire suivant afin de finaliser la
                création de votre mot de passe.
              </AlertMsg>
            </Box>
            <Paper className={classes.paper}>
              <UserPasswordForm
                context="resetPassword"
                token={query.get("token")}
                userId={query.get("id")}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </UnauthenticatedModel>
  );
}
