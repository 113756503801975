import React from 'react'

// Material-ui
import { Grid } from '@material-ui/core/'

// Components
import MainTemplate from '../../../../components/templates/main'
import ThemeForm from '../../components/ThemeForm'


export default function ThemeHandler(props){

  const { orgIRI, themeIRI } = { ...props }
  
  return (
    <MainTemplate 
      pageCategory="Gestion des organisations"
      pageTitle="Gestion du thème graphique"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <ThemeForm orgIRI={orgIRI} themeIRI={themeIRI} /> 
        </Grid>
      </Grid>
    </MainTemplate>
  )

}


