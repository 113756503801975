import React, { useState, useEffect, useCallback } from "react";
import useStateWithCallback from "use-state-with-callback";
import _ from "lodash";
import { toast } from "react-toastify";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import MaterialTable from "material-table";

import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "../../context/authContext";

import TemplateModal from "../../components/templatesList/TemplateModal";

const useStyles = makeStyles((theme) => ({
  panelWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[2],
  },
  countsTitleWrapper: {
    margin: theme.spacing(1, 0),
  },
  listWrapper: {
    flexGrow: 1,
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4, 2),
  },
  redChip: {
    backgroundColor: "orangered",
    color: "white",
  },
  blueChip: {
    backgroundColor: "deepskyblue",
    color: "white",
  },
  greenChip: {
    backgroundColor: "lawngreen",
    color: "white",
  },
  yellowChip: {
    backgroundColor: "gold",
    color: "white",
  },
}));

const materialTableLocalization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} sur {count}",
    labelRowsSelect: "lignes",
    labelRowsPerPage: "lignes par page:",
  },
  toolbar: {
    nRowsSelected: "{0} saisie(s) séléctionnée(s)",
    exportTitle: "Exporter",
    searchPlaceholder: "Rechercher",
    searchTooltip: "Rechercher",
  },
  header: {
    actions: "Actions",
  },
  body: {
    emptyDataSourceMessage: "Aucune saisies entrées",
    filterRow: {
      filterTooltip: "Filtrer",
    },
  },
};

export default (props) => {
  const classes = useStyles();
  const { apiManager } = useAuth();
  const tableRef = React.useRef();

  const [tableData, setTableData] = useStateWithCallback(null, (data) => {
    if (!data) {
      setListLoading(true);
    } else {
      setListLoading(false);
    }
  });

  const [listLoading, setListLoading] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const getTemplatesCallback = useCallback(getTemplates, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getTemplatesCallback();
    }

    return () => {
      isMounted = false;
    };
  }, [getTemplatesCallback]);

  // Get templates
  async function getTemplates() {
    setTableData(null);

    try {
      const result = await apiManager.getRessource(`/api/export_templates`);
      const data = await result["hydra:member"];

      if (data) {
        setTableData(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleViewOrderDetails = (e, rowData) => {
    setModalOpen(true);
    setModalData(rowData);
  };

  const handleDrawerClose = () => {
    setModalOpen(false);
  };

  const reloadTableData = () => {
    tableRef.current.onQueryChange();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="overline">Gestion des annonceurs</Typography>
            <Typography component="h1" variant="h4" color="initial">
              Liste des templates
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.panelWrapper} boxShadow={3}>
            <Grid container spacing={2}>
              <Grid item xs={3} />

              <Grid item xs={3} />

              <Grid item xs={3} />

              <Grid
                item
                container
                xs={3}
                justify="center"
                alignContent="center"
              >
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  color="secondary"
                  onClick={() => setModalOpen(true)}
                >
                  Créer une template
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            className={`${classes.panelWrapper} ${classes.listWrapper}`}
            boxShadow={3}
          >
            <Box className={classes.countsTitleWrapper}>
              <Typography variant="overline">{`${
                tableData ? tableData.length : "..."
              } template(s)`}</Typography>
            </Box>

            {listLoading ? (
              <Box className={classes.loaderWrapper}>
                <CircularProgress color="secondary" />
              </Box>
            ) : tableData.length !== 0 ? (
              <Box>
                <Grid item xs={12}>
                  <MaterialTable
                    tableRef={tableRef}
                    columns={[
                      {
                        title: "Nom",
                        field: "exportTemplateName",
                        editable: "never",
                      },
                      {
                        title: "Valeurs",
                        render: (rowData) => `${rowData.fields.join(", ")}`,
                        editable: "never",
                      },
                    ]}
                    data={tableData}
                    onRowClick={handleViewOrderDetails}
                    options={{
                      exportButton: true,
                      exportAllData: true,
                      showTitle: false,
                      pageSize: 20,
                    }}
                    actions={[
                      {
                        tooltip: "Supprimer toutes les saisies",
                        icon: "delete",
                        onClick: async (evt, data) => {
                          try {
                            toast.info(
                              `Suppresion de la template ${data.exportTemplateName}..`
                            );
                            await apiManager.deleteRessource(data["@id"]);
                            toast.success("Template supprimée !");
                            setTableData((prevState) =>
                              _.remove(prevState, function (n) {
                                return n["@id"] !== data["@id"];
                              })
                            );
                          } catch (e) {
                            toast.error("Erreur lors de la suppression");
                          }
                        },
                      },
                    ]}
                    localization={materialTableLocalization}
                  />
                </Grid>
              </Box>
            ) : (
              <Box className={classes.loaderWrapper}>
                <Typography variant="h6" color="textSecondary" align="center">
                  Aucune commande
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <TemplateModal
        open={modalOpen}
        handleDrawerClose={handleDrawerClose}
        modalData={modalData}
      />
    </>
  );
};
