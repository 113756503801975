import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../../../context/authContext";
import useStateWithCallback from "use-state-with-callback";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";

// Components
import RightPane from "../../../../components/Layout/RightPane";
import UserForm from "../../../User/scenes/Create";
import ResetPassButton from "./ResetPassButton";

// Utils
import { toast } from "react-toastify";
import MaterialTable from "material-table";
import locales from "../../../../locale/locales";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    marginLeft: theme.spacing(2),
  },
  panelWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[2],
  },
  countsTitleWrapper: {
    margin: theme.spacing(1, 0),
  },
  listWrapper: {
    flexGrow: 1,
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4, 2),
  },
}));

export default function UserListing(props) {
  const classes = useStyles();
  const { apiManager } = useAuth();
  const [userssListItems, setUserssListItems] = useStateWithCallback(
    null,
    (data) => {
      if (!data) {
        setListLoading(true);
      } else {
        setListLoading(false);
      }
    }
  );

  const [listLoading, setListLoading] = useState(true);

  const getUsersCallback = useCallback(getUsers, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getUsersCallback();
    }

    return () => {
      isMounted = false;
    };
  }, [getUsersCallback]);

  async function getUsers() {
    setUserssListItems(null);

    try {
      const result = await apiManager.getRessource(`/api/user_accounts`);
      const data = await result["hydra:member"];

      if (data) {
        setUserssListItems(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * The table ref is used so the Organization api can be triggered to respond at some event
   * For example, the table need to be refreshed when a user is created
   */
  let tableRef = React.createRef();

  const [state, setState] = useState({
    paneOpened: false, // Handle the Right panel opening
    action: null, // Depending on its value, the Rigth Pane will adapt its content
    userIRI: null, // The data of one user when editing one for example
  });

  /**
   * Handling the user form opening in the right pane
   */
  const openUserForm = (userIRI) => {
    userIRI = userIRI ? userIRI : null;
    setState({ paneOpened: true, action: "add_user", userIRI: userIRI });
  };

  /**
   * When closing the right pane, the organization lsiting is refreshed to handle new data (like new user)
   * The current implementation refresh the listing everry time the right pane is closed, but this should be improved
   * by triggering the refresh in more specific cases
   */
  function onRightPaneClose() {
    window.location.reload();
    setState({ ...state, paneOpened: false, action: null });
  }

  /**
   * Handling Organizations summary table
   */
  const columns = [
    {
      title: "Nom",
      field: "lastName",
      defaultSort: "asc",
    },
    {
      title: "Prénom",
      field: "firstName",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Organisation",
      field: "organization.organizationName",
    },
    // {
    //   title: 'Rôle',
    //   sorting: false,
    //   render: rawData => {
    //     return rawData.roles.join(', ')
    //   }
    // },
    {
      cellStyle: {
        textAlign: "right",
      },
      render: (rawData) => {
        /**
         * Handling a custom column with custom actions
         */
        let content = "";
        if (rawData) {
          content = (
            <React.Fragment>
              <ResetPassButton
                userId={rawData["id"]}
                className={classes.actionButton}
              />

              <Tooltip
                title="Modifier l'utilisateur"
                aria-label="Modifier l'utilisateur"
              >
                <Fab
                  color="primary"
                  size="small"
                  className={classes.actionButton}
                  onClick={() => openUserForm(rawData["@id"])}
                >
                  <EditIcon />
                </Fab>
              </Tooltip>
            </React.Fragment>
          );
        }
        return content;
      },
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="overline">Gestion des annonceurs</Typography>
            <Typography component="h1" variant="h4" color="initial">
              Liste des utilisateurs
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            className={`${classes.panelWrapper} ${classes.listWrapper}`}
            boxShadow={3}
          >
            <Box className={classes.countsTitleWrapper}>
              <Typography variant="overline">{`${
                userssListItems ? userssListItems.length : "..."
              } utilisateur(s)`}</Typography>
            </Box>

            {listLoading ? (
              <Box className={classes.loaderWrapper}>
                <CircularProgress color="secondary" />
              </Box>
            ) : userssListItems.length !== 0 ? (
              <Box>
                <Grid item xs={12}>
                  <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    localization={locales("materialTable")}
                    options={{
                      showTitle: false,
                      pageSize: 20,
                      emptyRowsWhenPaging: false,
                      sorting: true,
                    }}
                    data={userssListItems}
                    actions={[
                      {
                        icon: "add",
                        tooltip: "Ajouter un utilisateur",
                        isFreeAction: true,
                        onClick: (event) => openUserForm(),
                      },
                    ]}
                  />
                </Grid>
              </Box>
            ) : (
              <Box className={classes.loaderWrapper}>
                <Typography variant="h6" color="textSecondary" align="center">
                  Aucun utilisateur
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <RightPane isOpened={state.paneOpened} onClose={onRightPaneClose}>
        {state.action === "add_user" && <UserForm userIRI={state.userIRI} />}
      </RightPane>
    </>
  );
}
