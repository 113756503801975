import React from "react";
import { useAuth } from "../../../../context/authContext";

// Material-ui
import { Grid } from "@material-ui/core";

// Component
import MainTemplate from "../../../../components/templates/main";
import TextBox from "../../../../components/forms/TextBox";

// Utils
import { toast } from "react-toastify";
import MaterialTable from "material-table";
import locales from "../../../../locale/locales";

export default function FoldersListing(props) {
  const { apiManager, authManager } = useAuth();
  const currentUser = authManager.getUser();

  /**
   * The table ref is used so the Organization api can be triggered to respond at some event
   * For example, the table need to be refreshed when an organization is created
   */
  let tableRef = React.createRef();

  return (
    <MainTemplate
      pageCategory="Gestion des dossiers"
      pageTitle="Liste des dossiers"
    >
      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            tableRef={tableRef}
            columns={[
              {
                title: "Nom du dossier",
                field: "folderName",
                defaultSort: "asc",
                editComponent: (props) => {
                  return (
                    <TextBox
                      value={props.value}
                      onChange={(event) => {
                        props.onChange(event.target.value);
                      }}
                      inputProps={{ maxLength: 100 }}
                    />
                  );
                },
              },
              {
                title: "Nombre de comptage",
                field: "queries",
                editable: "never",
                render: (rawData) => {
                  return (
                    <React.Fragment>
                      {rawData && rawData.queries ? rawData.queries.length : ""}
                    </React.Fragment>
                  );
                },
              },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                var queryParam = {};
                queryParam["per_page"] = query.pageSize;
                queryParam["page"] = query.page + 1;
                queryParam["search"] = query.search;
                if (query.orderBy)
                  queryParam[`order[${query.orderBy.field}]`] =
                    query.orderDirection;

                apiManager
                  .get(
                    `/api/user_accounts/${currentUser.id}/query_folders`,
                    queryParam
                  )
                  .then((response) => {
                    resolve({
                      data: response["hydra:member"],
                      page: query.page,
                      totalCount: response["hydra:totalItems"],
                    });
                  })
                  .catch((e) => {
                    toast.error(e);
                    reject();
                  });
              })
            }
            localization={locales("materialTable")}
            options={{
              pageSize: 20,
              showTitle: false,
              emptyRowsWhenPaging: false,
              addRowPosition: "first",
            }}
            editable={{
              isDeletable: (rowData) =>
                rowData.queries && rowData.queries.length === 0, // only empty folders can be deleted
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  apiManager
                    .createRessource("queryFolders", newData)
                    .then(() => resolve())
                    .catch((e) => {
                      toast.error("Le champs ne peut pas être vide.");
                      reject();
                    });
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  apiManager
                    .updateRessource(oldData["@id"], newData)
                    .then(() => resolve())
                    .catch((e) => {
                      toast.error(e);
                      reject();
                    });
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  apiManager
                    .deleteRessource(oldData["@id"])
                    .then(() => resolve())
                    .catch((e) => {
                      toast.error(e);
                      reject();
                    });
                }),
            }}
          />
        </Grid>
      </Grid>
    </MainTemplate>
  );
}
