import React, { useState } from 'react'
import { useAuth } from '../../../../context/authContext'
import { useTheme } from '../../../../context/themeContext'

// Material-ui / dependencies
import { Grid, Switch, FormControlLabel, Box, Typography } from '@material-ui/core/'
import LoaderButton from '../../../../components/LoaderButton'
import { makeStyles } from '@material-ui/core/styles'

// Components
import ColorPicker from '../ColorPicker'
import UploadButton from '../UploadButton'

// Utils
import { themeHelper}  from '../../../../utils/datamatchHelpers'
import * as Yup from 'yup' 
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'


const useStyles = makeStyles(theme => ({
  colorPickerFormLine: {
    display:'flex',
    padding: theme.spacing(2, 0, 0, 0),
  },
  colorPickerContainer: {
    padding: theme.spacing(0, 4, 0, 0),
    display:'flex',
    alignItems: 'center'
  }, 
  colorPickerLabel: {
    margin: theme.spacing(0, 0, 0, 1)
  }
}));


/**
 * Constant definition
 */
const logoSize = { width: 239, height: 64 } // Logo allowed size
const defaultThemeOptions = themeHelper.getDefaultThemeOptions() // Default theme option

/**
 * This module allow the organization theme management with the customization of several parameters 
 * - Two colors, primaryColor and secondaryColor used everywhere in the app
 * - A logo file
 */
export default function ThemeForm(props){

  const classes = useStyles()
  const { orgIRI, themeIRI } = {...props}

  const { apiManager, authManager } = useAuth()
  const { updateTheme } = useTheme()

  const [ themeData, setThemeData] = useState(null)
  const [ logoFile, setLogoFile] = useState(null)
  const [ performLogoDelete, setPerformLogoDelete ] = useState(false)
  
  React.useEffect(() => {
    if(themeIRI && !themeData){
      // Loading customized parameters if exist
      apiManager.getRessource(themeIRI)
        .then(response => {
          setThemeData(response)
        })
        .catch(error => {toast.error(error)})
        .finally( () => {})
    }
    else{  
      // Loading default parameter if no customization registered
      const primary = defaultThemeOptions.primaryColor
      const secondary = defaultThemeOptions.secondaryColor
      setThemeData({
        isEnabled: false,
        logoUrl: null,
        primaryColor: {
          hex: primary.hex,
        }, 
        secondaryColor: {
          hex: secondary.hex,
        }
      })
    }
  }, [])

  // Performed when the user delete the logo component from the form 
  const handleLogoDelete = async (formik) => {
    return new Promise( (resolve) => {
      setLogoFile(null)
      setPerformLogoDelete(true)
      formik.setFieldValue('logo', null)
      resolve()      
    })
  }

  /**
   * Performed when the user load a new logo file
   * The loaded logo should respect a specific format otherwise it will display an error
   */ 
  const handleLogoChange = async (file) => {
    return new Promise( (resolve, reject) => {
      var img = new Image();
      var objectUrl = URL.createObjectURL(file);
      img.onload = function () {
        if(this.width !== logoSize.width && this.height !== logoSize.height){
          reject(`Veuillez charger une image PNG ou JPEG dont la taille respecte les dimensions de ${logoSize.width}x${logoSize.height}px`)
        }
        else{
          setLogoFile(file)
          resolve()
        }
        URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl; // Loading the image
    })
  }

  // Performed when the user change the primary color of the theme
  const handlePrimaryColorChange = (formik, color) => {
    formik.setFieldValue('primaryColor', {hex: color.hex})
  }

  // Performed when the user change the secondary color of the theme
  const handleSecondaryColorChange = (formik, color) => {
    formik.setFieldValue('secondaryColor', {hex: color.hex} )
  }

  // Handle the logo submition to the API
  const submitLogoFct = async (logoFile) => {

    // Building the data for the request (axios will automatically managed the headers of the request)
    let logoData = new FormData()
    logoData.append('file', logoFile, logoFile.name)
    return await apiManager.createRessource('media', logoData )

  }

  // Handle the theme submition to the API
  const sumbmitThemeFct = async (data) => {
    if( isEditMode() ){
      return await apiManager.patchRessource(themeIRI, data)
    }
    else{
      return await apiManager.createRessource('themes', data)
    }
  }

  // Handle the UI refresh 
  const refreshUi = (themeData) => {
    // Refresh current theme if we are dealing we the organization theme of the logged in user
    if(themeData.organization === authManager.getUser().organization){
      if(themeData.isEnabled){
        updateTheme(themeData) 
      }
      else{
        updateTheme(defaultThemeOptions)
      }
    }
  }

  /**
   * Handle the form submition 
   * The submition is done in two step : 
   * 1 - First, if the user has defined a logo, the logo is uploaded to a generic API endpoint (that handle generic file upload). 
   *     The IRI of the logo is provided by the API when successfully uploaded, this IRI is used to enrich the theme data
   * 2 - Second, the theme is registered (with or without logoIRI)
   * 
   * Finally, is we are dealing with the organization of the loggend in user, we refresh the UI whith the newly registered theme
   * 
   */
  const handleFormSubmit = async (newData, actions) => {

    // Handle submitting state for formik
    actions.setSubmitting(true)

    /**
     * Handling the logo first, then registering the theme 
     * Must resolve a logoIRI or null
     */
    const handleLogo = async () => {
      return new Promise( (resolve) => {
        // A new logo has been loaded by user
        if(logoFile){
          submitLogoFct(logoFile)
            .then(response => {
              resolve(response['@id'])
            })
            .catch( () => {
              resolve(null)
              toast.error("Une erreur est survenue pendant l'enregistrement du logo.")
            })
        }
        else{
          // If no logo loaded by the user, we resolve the existing logoIRI or null if the user ask for the logo deletion
          if(performLogoDelete){
            // User ask for user deletion
            resolve(null)
          }
          else{
            // User didn't do anything about the logo, we keep the existing logoIRI value
            resolve(themeData.logo)
          }
          
        }
      })
    }

    // Handling logo sumbition / deletion
    const logoIRI = await handleLogo()

    // Handle theme update
    sumbmitThemeFct({...newData, logo: logoIRI})
      .then( (response) => {
        toast.success('Thème enregistré avec succès')
        setThemeData(response)
        refreshUi(response) // Refreshing the UI 
      })
      .finally( () => { actions.setSubmitting(false) })
      
  }

  /**
   * Tell if the form is in edit or create mode
   */
  const isEditMode = () => { return themeIRI ? true : false }

  /**
   * Handling form validation
   */
  const validationSchema = Yup.object().shape({
    isEnabled: Yup.bool().required(),
    primaryColor: Yup.object().required(),
    secondaryColor: Yup.object().required(),
    organization: Yup.string().required(), 
    logo: Yup.string().nullable()
  })

  const initialValues = {
    isEnabled: themeData && themeData.isEnabled ? themeData.isEnabled : false,
    primaryColor: themeData && themeData.primaryColor ?  themeData.primaryColor : '', 
    secondaryColor: themeData && themeData.secondaryColor ?  themeData.secondaryColor : '',
    organization: orgIRI,
    logo: themeData && themeData.logo
  }

  return (
    <Formik 
      key={themeData ? themeData['@id'] : 'newThemeData'}
      initialValues={ initialValues }
      onSubmit={(values, actions) => { handleFormSubmit(values, actions) }}
      validationSchema={validationSchema}
      render={(formik) => (
        <Form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.isEnabled}
                    onChange={formik.handleChange}
                    value={formik.values.isEnabled}
                    name='isEnabled'
                    id='isEnabled'
                    color='primary'
                  />
                }
                label={ formik.values.isEnabled ? 'Désactiver la customisation graphique pour l\'organisation' : 'Activer la customisation graphique pour l\'organisation'}
              />
            </Grid>
          {
            formik.values.isEnabled ? (
              
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography gutterBottom>Votre logo (PNG ou JPEG de dimensions : {logoSize.width}x{logoSize.height}px)</Typography>
                  <UploadButton 
                    fileUrl={themeData.logoUrl} 
                    accept=".jpg, .png"
                    onChange={ (file) => handleLogoChange(file) } 
                    onDelete={ () => handleLogoDelete(formik) } 
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.colorPickerFormLine}>
                    <Box className={classes.colorPickerContainer}>
                      <ColorPicker color={formik.values.primaryColor} onChange={ (color) => handlePrimaryColorChange(formik, color) } />
                      <Typography className={classes.colorPickerLabel}>Couleur primaire</Typography>
                    </Box>
                    <Box className={classes.colorPickerContainer}>
                      <ColorPicker color={formik.values.secondaryColor} onChange={ (color) => handleSecondaryColorChange(formik, color) } />
                      <Typography className={classes.colorPickerLabel}>Couleur secondaire</Typography>
                    </Box>
                  </Box>
                </Grid>
              </React.Fragment>
              
            ) 
            : null
          }
          <Grid item xs={12}>
            <LoaderButton 
              isSubmitting={formik.isSubmitting} 
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              disabled={formik.isSubmitting}
            >
              Enregistrer le thème
            </LoaderButton>
          </Grid>
          </Grid>
        </Form>
      )}
    />
  )
}