import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core/'


const useStyles = makeStyles(theme => ({
  
  root:{
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row', 
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column', 
    },
  },
  titleBox: {
    display: 'flex',
    background: theme.palette.secondary.main,
    width: '30%', 
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      width: '100%',  
      justifyContent: 'flex-start',
      padding: theme.spacing(2)
    },
  }, 
  appTitle:{
    color: '#ffffff',
    textTransform: 'uppercase',
    margin: theme.spacing(2, 4, 0, 0), 
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
    }
  },
  contentLayout:{
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }, 
  contentBox:{
    padding: theme.spacing(2),
  }
}));


export default function UnauthenticatedModel(props) {
  
  const classes = useStyles()
  const { title } = {...props}

  return (
    <Box className={classes.root}>
      <Box className={classes.titleBox} boxShadow={4}>
        <Typography className={classes.appTitle} component='h1' variant='h4' >Datamatch</Typography>
      </Box>
      <Box className={classes.contentLayout}>
        <Box className={classes.contentBox}>
          <Typography component='h1' variant='h4' gutterBottom>{title}</Typography>
          {props.children}
        </Box>
      </Box>
    </Box>    
  );
}

