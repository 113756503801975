import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { toast } from "react-toastify";
import MaterialTable from "material-table";
import _ from "lodash";
import moment from "moment";
import { parsePhoneNumber } from "libphonenumber-js";

import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import TextBox from "../forms/TextBox";
import SearchBoxList from "../forms/SearchBoxList";

import { useAuth } from "../../context/authContext";

const drawerWidth = window.innerWidth;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowY: "hidden",
  },
  content: {
    padding: theme.spacing(2),
    marginTop: 48,
    marginBottom: 68,
    overflowY: "auto",
  },
  errorText: {
    color: "red",
    marginLeft: theme.spacing(1),
    fontWeight: 600,
  },
  controlBannerWrapperStyle: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
    zIndex: 500,
    backgroundColor: "#FFF",
  },
}));

const materialTableLocalization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} sur {count}",
    labelRowsSelect: "lignes",
    labelRowsPerPage: "lignes par page:",
  },
  toolbar: {
    nRowsSelected: "{0} saisie(s) séléctionnée(s)",
    exportTitle: "Exporter",
    searchPlaceholder: "Rechercher",
    searchTooltip: "Rechercher",
  },
  header: {
    actions: "Actions",
  },
  body: {
    emptyDataSourceMessage: "Aucune saisies entrées",
    filterRow: {
      filterTooltip: "Filtrer",
    },
  },
};

const CHANNELS_DATA = [
  {
    id: "sms",
    value: "SMS",
  },
  {
    id: "email",
    value: "E-mail",
  },
  {
    id: "phone",
    value: "Téléphone",
  },
];

const CampaignModal = ({
  open,
  handleDrawerClose,
  modalData,
  readOnly,
  addToCampaignsList,
  updateCampaignsList,
}) => {
  const classes = useStyles();
  const { authManager, apiManager } = useAuth();
  const currentUser = authManager.getUser();

  const [context, setContext] = useState("");
  const [iri, setIri] = useState("");
  const [type, setType] = useState("");

  const [id, setID] = React.useState("");
  const [companyName, setCompanyName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [employeeID, setEmployeeID] = useState("");
  const [subAccount, setSubAccount] = useState("");
  const [email, setEmail] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [comment, setComment] = useState("");
  const [organization, setOrganization] = useState("");
  const [customerPacks, setCustomerPacks] = useState([]);
  const [customerPacksIri, setCustomerPacksIri] = useState("");

  const [volume, setVolume] = useState("");
  const [packDesc, setPackDesc] = useState("");

  const [error, setError] = useState("");
  const [isAdmin, setIsAdmin] = useState(null);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && open) {
      checkIfUserIsAdmin();

      if (modalData) {
        fillModalData();
      } else {
        emptyModalData();
      }
    }

    return () => {
      isMounted = false;
    };
  }, [open]);

  const checkIfUserIsAdmin = () => {
    const result = currentUser.roles.find((item) => item === "ROLE_ADMIN");

    setIsAdmin(result === "ROLE_ADMIN");
  };

  const fillModalData = async () => {
    setContext("");
    setIri(modalData["@id"]);
    setType(modalData["@type"]);
    setID(modalData.id);
    setCompanyName(modalData.CompanyName);
    setBrandName(modalData.BrandName);
    setLastName(modalData.LastName);
    setFirstName(modalData.FirstName);
    setEmployeeID(modalData.EmployeeID);
    setSubAccount(modalData.SubAccount);
    setEmail(modalData.Email);
    setMobilePhone(modalData.MobilePhone);
    setComment(modalData.Comment);
    setOrganization(currentUser.organization);
    setCustomerPacksIri(modalData.customerPacks);

    getCustomerPacks();
  };

  const emptyModalData = () => {
    setContext("");
    setIri("");
    setType("");
    setID("");
    setCompanyName("");
    setBrandName("");
    setLastName("");
    setFirstName("");
    setEmployeeID("");
    setSubAccount("");
    setEmail("");
    setMobilePhone("");
    setComment("");
    setOrganization("");
    setCustomerPacks([]);
    setCustomerPacksIri("");
    setError("");
  };

  const advertiserFormHasError = () => {
    const phoneNumber = mobilePhone && parsePhoneNumber(mobilePhone, "FR");

    if (companyName === "") {
      setError("La raison sociale est obligatoire pour continuer.");
      return true;
    } else if (!lastName.match("^[-a-zA-Z ]*$")) {
      setError("Le nom n'est pas valide.");
      return true;
    } else if (!firstName.match("^[-a-zA-Z ]*$")) {
      setError("Le nom n'est pas valide.");
      return true;
    } else if (phoneNumber && !phoneNumber.isValid()) {
      setError("Le numéro de téléphone n'est pas valide.");
      return true;
    } else {
      setError("");
      return false;
    }
  };

  const packFormHasError = () => {
    if (volume === "") {
      setError("Le volume du pack est obligatoire pour continuer.");
      return true;
    } else if (packDesc === "") {
      setError("La description du pack est obligatoire pour continuer.");
      return true;
    } else {
      setError("");
      return false;
    }
  };

  const handleCreate = async () => {
    if (!advertiserFormHasError()) {
      try {
        toast.info("Création en cours..");

        const result = await apiManager.createRessource("customers", {
          CompanyName: companyName,
          BrandName: brandName,
          LastName: lastName,
          FirstName: firstName,
          EmployeeID: employeeID,
          SubAccount: subAccount,
          Email: email,
          MobilePhone: mobilePhone,
          Comment: comment,
          Organization: currentUser.organization,
          customerPacks: customerPacks,
        });
        setIri(result["@id"]);
        setID(result.id);
        addToCampaignsList(result);
        toast.success("Annonceur créé !");
      } catch (e) {
        console.log(e);
        toast.error("Erreur lors de la création");
      }
    }
  };

  const handleUpdate = async () => {
    if (!advertiserFormHasError()) {
      const ADVERTISER_ITEM = {
        "@context": context,
        "@id": iri,
        "@type": type,
        id: id,
        CompanyName: companyName,
        BrandName: brandName,
        LastName: lastName,
        FirstName: firstName,
        EmployeeID: employeeID,
        SubAccount: subAccount,
        Email: email,
        MobilePhone: mobilePhone,
        Comment: comment,
        Organization: organization,
        customerPacks: customerPacks.length !== 0 ? customerPacksIri : [],
      };

      try {
        toast.info("Sauvegarde en cours..");
        const modifiedAdvertiser = await apiManager.updateRessource(
          iri,
          ADVERTISER_ITEM
        );
        updateCampaignsList(iri, modifiedAdvertiser);
        toast.success("Sauvegardé !");
      } catch (e) {
        console.log(e);
        toast.error("Erreur lors de la sauvegarde");
      }
    }
  };

  const handleCreatePack = async () => {
    if (!advertiserFormHasError() && !packFormHasError()) {
      if (!iri || !id) {
        setError("Veuillez enregistrer l'annonceur avant de créer le pack");
      } else {
        setError("");
        try {
          toast.info("Création du pack en cours..");

          await apiManager.createRessource("customerPacks", {
            packChannel: "sms",
            volume: volume,
            packDesc: packDesc,
            customer: iri,
            createdBy: currentUser.userAccount,
          });

          setPackDesc("");
          setVolume("");

          getCustomerPacks();
          toast.success("Pack créé !");
        } catch (e) {
          console.log(e);
          toast.error("Erreur lors de la création");
        }
      }
    }
  };

  const getCustomerPacks = async () => {
    try {
      const result = await apiManager.getRessource(`/api/customer_packs`);
      if (result) {
        const customerPacks = result["hydra:member"].filter(
          (customerPack) => customerPack.customer["@id"] === modalData["@id"]
        );
        setCustomerPacks(customerPacks);
        setCustomerPacksIri(
          customerPacks.map((customerPack) => customerPack["@id"])
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    handleDrawerClose();
    emptyModalData();
  };

  const handleCustomerIDChange = (event) => {
    setEmployeeID(event.target.value);
  };

  const handleSubAccountChange = (event) => {
    setSubAccount(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleMobilePhoneChange = (event) => {
    let value = event.target.value;
    let onlyNums = value.replace(/[^0-9]/g, "");
    setMobilePhone(onlyNums);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleBrandNameChange = (event) => {
    setBrandName(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleVolumeChange = (event) => {
    setVolume(event.target.value);
  };

  const handlePackDescChange = (event) => {
    setPackDesc(event.target.value);
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={handleClose}
    >
      <AppBar position="absolute" color="secondary">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="overline">
              Création d'un nouvel annonceur
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Contact annonceur</Typography>
          </Grid>

          <Grid item xs={6}>
            <TextBox
              id="company-name"
              label="Raison sociale *"
              value={companyName}
              onChange={handleCompanyNameChange}
              inputProps={{ maxLength: 100 }}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={6}>
            <TextBox
              id="brand-name"
              label="Enseigne"
              value={brandName}
              onChange={handleBrandNameChange}
              inputProps={{ maxLength: 100 }}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={6}>
            <TextBox
              id="employee-registration-number"
              label="N° identifiant client"
              value={employeeID}
              onChange={handleCustomerIDChange}
              inputProps={{ maxLength: 50 }}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={6}>
            <TextBox
              id="sub-account"
              label="Sous compte"
              value={subAccount}
              onChange={handleSubAccountChange}
              inputProps={{ maxLength: 50 }}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={6}>
            <TextBox
              id="last-name"
              label="Nom"
              value={lastName}
              onChange={handleLastNameChange}
              inputProps={{ maxLength: 100 }}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={6}>
            <TextBox
              id="first-name"
              label="Prénom"
              value={firstName}
              onChange={handleFirstNameChange}
              inputProps={{ maxLength: 100 }}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={6}>
            <TextBox
              id="phone-number"
              label="Téléphone portable"
              value={mobilePhone}
              onChange={handleMobilePhoneChange}
              inputProps={{ maxLength: 20 }}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={6}>
            <TextBox
              id="email"
              label="Email"
              value={email}
              onChange={handleEmailChange}
              inputProps={{ maxLength: 200 }}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBox
              id="comments"
              label="Commentaire"
              value={comment}
              onChange={handleCommentChange}
              disabled={readOnly}
              multiline
              rows={4}
              rowsMax={10}
            />
          </Grid>

          <Grid item xs={12}>
            {isAdmin && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading} variant="h6">
                    Créer un pack
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SearchBoxList
                        id="pack-channel-search-box"
                        label="Channel"
                        options={CHANNELS_DATA}
                        value="sms"
                        inputValue="SMS"
                        // onChange={(event, newValue) => {
                        //   setPackChannelValue(newValue);
                        // }}
                        // onInputChange={(event, newValue) => {
                        //   setPackChannelInputValue(newValue);
                        // }}
                        getOptionLabel={(option) => option.value}
                        noOptionsText="Séléctionnez un canal"
                        clearable
                        disabled
                        readOnly={readOnly}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        id="volume"
                        label="Volume *"
                        value={volume}
                        onChange={handleVolumeChange}
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox
                        id="pack-description"
                        label="Description *"
                        value={packDesc}
                        onChange={handlePackDescChange}
                        disabled={readOnly}
                        multiline
                        rows={4}
                        rowsMax={10}
                      />
                    </Grid>
                    <Grid item xs={10} />
                    <Grid item xs={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleCreatePack}
                      >
                        Créer un pack
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>

          <Grid item xs={12}>
            <MaterialTable
              isLoading={!customerPacks}
              columns={[
                {
                  title: "Crée le",
                  render: (rowData) =>
                    `${moment.utc(rowData.createdAt).format("DD/MM/YYYY")}`,
                  editable: "never",
                },
                {
                  title: "Channel",
                  field: "packChannel",
                  editable: false,
                },
                {
                  title: "Volume",
                  field: "volume",
                  emptyValue: "0",
                },
                {
                  title: "Volume restant",
                  field: "volumeLeft",
                  editable: false,
                },
                {
                  title: "Nom",
                  field: "packName",
                  emptyValue: "Ajouter un nom",
                },
                {
                  title: "Description",
                  field: "packDesc",
                  emptyValue: "Ajouter une description",
                },
                {
                  title: "Validité",
                  render: (rowData) => {
                    const now = moment();
                    const creationDate = moment(rowData.createdAt).add(1, "y");
                    const IS_VALID = now.isSameOrBefore(creationDate);

                    if (IS_VALID) {
                      return "Valide";
                    } else {
                      return "Expiré";
                    }
                  },
                },
              ]}
              data={customerPacks}
              title="Packs"
              options={{
                exportButton: true,
                exportAllData: true,
              }}
              actions={
                isAdmin && [
                  {
                    tooltip: "Supprimer toutes les saisies",
                    icon: "delete",
                    onClick: async (evt, data) => {
                      setCustomerPacks((prevState) =>
                        _.remove(prevState, function (n) {
                          return n["@id"] !== data["@id"];
                        })
                      );
                      await apiManager.deleteRessource(data["@id"]);
                    },
                  },
                ]
              }
              editable={
                isAdmin && {
                  onRowUpdate: async (newData, oldData) => {
                    try {
                      const dataUpdate = [...customerPacks];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      toast.info("Mise à jour du pack en cours..");

                      await apiManager.updateRessource(oldData["@id"], {
                        packChannel: "sms",
                        volume: newData.volume,
                        packName: newData.packName,
                        packDesc: newData.packDesc,
                        customer: iri,
                        createdBy: currentUser.userAccount,
                      });

                      setCustomerPacks([...dataUpdate]);
                      toast.success("Pack sauvegardé !");
                    } catch (e) {
                      console.log(e);
                      toast.error("Erreur lors de la mise à jour le pack");
                    }
                  },
                }
              }
              localization={materialTableLocalization}
            />
          </Grid>

          <Box className={classes.controlBannerWrapperStyle} boxShadow={6}>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography variant="body2" className={classes.errorText}>
                  {error}
                </Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end" spacing={0}>
                <Grid item>
                  {modalData ? (
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleUpdate}
                    >
                      Sauvegarder l'annonceur
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleCreate}
                    >
                      Créer un annonceur
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default CampaignModal;
