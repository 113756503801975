import React from 'react'
import {AuthProvider} from './authContext'
import {ThemeProvider} from './themeContext'

function AppProviders({children}) {
  return (
    <AuthProvider>
      <ThemeProvider>
        {children}
      </ThemeProvider>
    </AuthProvider>
  )
}

export default AppProviders
