import React, { useState } from "react";
import { useAuth } from "../../../../context/authContext";

// Material-ui
import { Typography } from "@material-ui/core";

// Utils
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { toast } from "react-toastify";

const chartDataUrl = "/api/stats/age-genders";

export default function GenderPotentialByAgeRangeChart(props) {
  const { colors } = { ...props };

  const [chartData, setChartData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const { apiManager } = useAuth();

  React.useEffect(() => {
    function getChartData() {
      setIsPending(true);
      apiManager
        .get(chartDataUrl)
        .then((data) => {
          setChartData(data);
        })
        .catch((e) => {
          setChartData([]);
          toast.error(e);
        })
        .finally(() => setIsPending(false));
    }

    // Initializing data
    getChartData();
  }, [apiManager]); // apiManager will never change during the lycicle of the comoonent, so it should never re-run the effect

  /**
   * Notes :
   * - Witdh 99% on ResponsiveContainer to make responsive work. See : https://github.com/recharts/recharts/issues/1423
   */
  return isPending ? (
    "Chargement en cours"
  ) : (
    <div style={{ width: "100%", height: 300 }}>
      <Typography variant="h6" component="h3">
        Potentiel par genre et par tranche d'âge
      </Typography>
      <ResponsiveContainer width="99%">
        <BarChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="TRANCHE_AGE" />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          <Bar name="Femmes" dataKey="F" stackId="a" fill={colors[0]} />
          <Bar name="Hommes" dataKey="H" stackId="a" fill={colors[1]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
