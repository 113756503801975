import React, { useState, useEffect, useCallback } from "react";
import useStateWithCallback from "use-state-with-callback";
import _ from "lodash";
import { toast } from "react-toastify";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import MaterialTable from "material-table";

import SearchBoxList from "../../components/forms/SearchBoxList";
import AdvertiserModal from "../../components/advertisersList/AdvertiserModal";

import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "../../context/authContext";

const useStyles = makeStyles((theme) => ({
  panelWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[2],
  },
  countsTitleWrapper: {
    margin: theme.spacing(1, 0),
  },
  listWrapper: {
    flexGrow: 1,
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4, 2),
  },
}));

const materialTableLocalization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} sur {count}",
    labelRowsSelect: "lignes",
    labelRowsPerPage: "lignes par page:",
  },
  toolbar: {
    nRowsSelected: "{0} saisie(s) séléctionnée(s)",
    exportTitle: "Exporter",
    searchPlaceholder: "Rechercher",
    searchTooltip: "Rechercher",
  },
  header: {
    actions: "Actions",
  },
  body: {
    emptyDataSourceMessage: "Aucune saisies entrées",
    filterRow: {
      filterTooltip: "Filtrer",
    },
  },
};

export default (props) => {
  const classes = useStyles();

  const { authManager, apiManager } = useAuth();
  const currentUser = authManager.getUser();

  const [advertisersListItems, setAdvertisersListItems] = useStateWithCallback(
    null,
    (data) => {
      if (!data) {
        setListLoading(true);
      } else {
        setListLoading(false);
      }
    }
  );

  const [advertisersListItemsFiltered, setAdvertisersListItemsFiltered] =
    useStateWithCallback(null, (data) => {
      if (!data) {
        setListLoading(true);
      } else {
        setListLoading(false);
      }
    });

  const [listLoading, setListLoading] = useState(true);

  const [organizations, setOrganizations] = useState(null);
  const [organizationValue, setOrganizationValue] = useState(null);
  const [organizationInputValue, setOrganizationInputValue] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalReadOnly, setModalReadyOnly] = useState(null);

  const getOrganizationsCallback = useCallback(getOrganizations, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getOrganizationsCallback();
      getCustomers();
    }

    return () => {
      isMounted = false;
    };
  }, [getOrganizationsCallback]);

  async function getOrganizations() {
    try {
      const result = await apiManager.getRessources("organizations", {
        "order[organizationName]": "ASC",
      });

      if (result) {
        setOrganizations(result["hydra:member"]);

        const myOrganization = result["hydra:member"].find((result) => {
          return result["@id"] === `${currentUser.organization}`;
        });

        setOrganizationValue(myOrganization);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // Get customers
  async function getCustomers() {
    setAdvertisersListItemsFiltered(null);

    try {
      const result = await apiManager.getRessource(`/api/customers`);
      const data = await result["hydra:member"];

      if (data) {
        const listFiltered = data.filter(
          (item) => item.Organization === `${currentUser.organization}`
        );
        setAdvertisersListItems(data);
        setAdvertisersListItemsFiltered(listFiltered);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleModifyClick = (event, data, readOnly) => {
    event.stopPropagation();
    setModalOpen(true);
    setModalData(data);
    // setModalReadyOnly(readOnly);
  };

  const updateCampaignsList = (id, newItem) => {
    const index = advertisersListItemsFiltered.findIndex((item) => {
      return item["@id"] === id;
    });

    if (index !== -1) {
      setAdvertisersListItemsFiltered([
        ...advertisersListItemsFiltered.slice(0, index),
        newItem,
        ...advertisersListItemsFiltered.slice(index + 1),
      ]);
    }
  };

  const addToCampaignsList = (newItem) => {
    setAdvertisersListItemsFiltered(
      _.concat(...advertisersListItemsFiltered, [newItem])
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="overline">Gestion des annonceurs</Typography>
            <Typography component="h1" variant="h4" color="initial">
              Liste des annonceurs
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.panelWrapper} boxShadow={3}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SearchBoxList
                  id="organisations-search-box"
                  loading={!organizations}
                  label="Organisations"
                  options={organizations}
                  value={organizationValue}
                  inputValue={organizationInputValue}
                  onChange={(event, newValue) => {
                    setOrganizationValue(newValue);
                    const listFiltered = advertisersListItems.filter(
                      (item) => item.Organization === newValue["@id"]
                    );
                    setAdvertisersListItemsFiltered(listFiltered);
                  }}
                  onInputChange={(event, newValue) => {
                    setOrganizationInputValue(newValue);
                  }}
                  getOptionLabel={(option) => option.organizationName}
                />
              </Grid>

              <Grid item xs={3} />

              <Grid item xs={3} />

              <Grid
                item
                container
                xs={3}
                justify="center"
                alignContent="center"
              >
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  color="secondary"
                  onClick={() => setModalOpen(true)}
                >
                  Créer un annonceur
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            className={`${classes.panelWrapper} ${classes.listWrapper}`}
            boxShadow={3}
          >
            <Box className={classes.countsTitleWrapper}>
              <Typography variant="overline">{`${
                advertisersListItemsFiltered
                  ? advertisersListItemsFiltered.length
                  : "..."
              } annonceur(s)`}</Typography>
            </Box>

            {listLoading ? (
              <Box className={classes.loaderWrapper}>
                <CircularProgress color="secondary" />
              </Box>
            ) : advertisersListItemsFiltered.length !== 0 ? (
              <Box>
                <Grid item xs={12}>
                  <MaterialTable
                    columns={[
                      {
                        title: "Nom de l'annonceur",
                        field: "CompanyName",
                      },
                      {
                        title: "Nom du contact",
                        field: "LastName",
                      },
                      {
                        title: "Numéro du contact",
                        field: "MobilePhone",
                      },
                      {
                        title: "Créé le",
                        render: (rowData) =>
                          rowData.createdAt
                            ? moment.utc(rowData.createdAt).format("DD/MM/YYYY")
                            : "-",
                      },
                    ]}
                    data={advertisersListItemsFiltered}
                    onRowClick={handleModifyClick}
                    options={{
                      exportButton: true,
                      exportAllData: true,
                      showTitle: false,
                      pageSize: 20,
                    }}
                    actions={[
                      {
                        tooltip: "Supprimer toutes les saisies",
                        icon: "delete",
                        onClick: async (evt, data) => {
                          try {
                            toast.info(`Suppresion de ${data.CompanyName}..`);
                            await apiManager.deleteRessource(data["@id"]);
                            toast.success("Annonceur supprimé !");
                            setAdvertisersListItemsFiltered((prevState) =>
                              _.remove(prevState, function (n) {
                                return n["@id"] !== data["@id"];
                              })
                            );
                          } catch (e) {
                            toast.error(
                              "Suppression impossible, vérifier que l'annonceur n'a pas de pack ou de campagne(s) associée(s)"
                            );
                          }
                        },
                      },
                    ]}
                    localization={materialTableLocalization}
                  />
                </Grid>
              </Box>
            ) : (
              <Box className={classes.loaderWrapper}>
                <Typography variant="h6" color="textSecondary" align="center">
                  Aucun annonceur
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <AdvertiserModal
        open={modalOpen}
        modalData={modalData}
        readOnly={modalReadOnly}
        handleDrawerClose={() => {
          setModalData(null);
          setModalOpen(false);
        }}
        addToCampaignsList={addToCampaignsList}
        updateCampaignsList={updateCampaignsList}
      />
    </>
  );
};
