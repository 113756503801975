const arrayHelper = (() => {

  const shuffle = arr => arr
    .map(a => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map(a => a[1])

  /**
   * Remove an object entry from an array of object based on a key-value pair condition
   * Note: if several element matches the condition, only the first element found is removed.
   * 
   * @param {*} arr - The haystack array containing the element to remove
   * @param {*} key - The key to check on
   * @param {*} value - The value 
   */
  const removeObjectFromArray = (arr, key, value) => {
    try{
      if( !Array.isArray(arr) ) { throw new Error('[removeObjectFromArray] "arr" parameter is not an array')}
      if( !key ) { throw new Error('[removeObjectFromArray] "key" parameter is not defined ')}
      if( !value ) { throw new Error('[removeObjectFromArray] "value" parameter is not defined ')}

      function matchesEl(el) { return el[key] === value }
      return arr.splice(arr.findIndex(matchesEl), 1);
    }
    catch (e){
      console.error(e.message)
    }
  }

  return {
    shuffle, 
    removeObjectFromArray,
  }

})()


const fileHelper = (() => {

  /**
   * Build a valide fileName from a random string
   * - Sanitize : will remove all forbidden charater
   * - Then we replace every space by a '-' character
   * - Plus, the filename is lowercased
   */
  const getFilenameFromString = (s) => {
    const sanitize = require("sanitize-filename")
    const filename = sanitize(s).replace(/ /g, '-').toLowerCase() 
    return sanitize(filename)
  }
  
  return { getFilenameFromString }
})()


const numberHelper = ( () => {

  function formatToDecimal(value) {
    return new Intl.NumberFormat('fr-FR', { style: 'decimal' }).format(value)
  }

  return {
    formatToDecimal: formatToDecimal
  }
}) ()


const dateHelper = (() => {
  
  /**
   * Used to format a Date to the format yyyymmdd
   * @param {*} theDate - Can be a string or a Date Object. The date string is expected to be GTM
   */
  const formatDateToYYYYMMDD = (theDate) => {
    try{
      if(typeof theDate !== Date) theDate = new Date(theDate)

      let fullYear = String(theDate.getFullYear())

      let fullMonth = String(theDate.getMonth() + 1)
      fullMonth = fullMonth.length < 2 ? '0'+fullMonth : fullMonth

      let fullDay = String(theDate.getDate())
      fullDay = fullDay.length < 2 ? '0'+fullDay : fullDay

      return fullYear+fullMonth+fullDay
    }
    catch(e){
      throw new Error('[formatDate] : '+e)
    }
  }

  return { formatDateToYYYYMMDD }
})()



/**
 * The theme helper provide functions to manage the theme customization
 * In the application we manipulate two themes concepts : 
 * - The theme options (color, logo, etc. ... ) which can be defined ion two ways (static default values || dynamic values corresponding to organization customization)
 * - A generated theme used by Material UI
 * 
 */
const themeHelper = (() => {
  

  const getDefaultThemeOptions = () => {
    return {
      primaryColor: {hex: '#2B2D42'},
      secondaryColor: {hex: '#E63462'},
      logoUrl: null
    }
  }

  /**
   * Return the MUITheme description
   */
  const getMuiThemeOptions = (themeOptions = null) => {

    if(!themeOptions) { themeOptions = getDefaultThemeOptions()}

    return {
      palette: {
        primary: {
          main: themeOptions.primaryColor.hex,
        },
        secondary: {
          main: themeOptions.secondaryColor.hex
        },
        error: {
          main: "#ee0131"
        },
        warning: {
          main: '#FF8C42'
        }, 
        success: {
          main: '#4CC963'
        }, 
        info: {
          main: '#2196f3'
        }
      }
    }
  }

  return { getMuiThemeOptions, getDefaultThemeOptions }

})()

export { arrayHelper, fileHelper, numberHelper, dateHelper, themeHelper }