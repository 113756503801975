import React, { useState } from "react";
import { useAuth } from "../../../../../context/authContext";

// Material-ui
import MuiSelect from "../../../../../components/MuiSelect";
import { MenuItem } from "@material-ui/core";

// Notification
import { toast } from "react-toastify";

export default function ServerSelectList(props) {
  const { apiManager } = useAuth();
  const { formik, className } = { ...props };

  const [isLoading, setIsLoading] = useState(false);
  const [serverList, setServerList] = useState(null);

  // Data initialization
  React.useEffect(() => {
    setIsLoading(true);
    apiManager
      .getRessources("servers")
      .then((response) => {
        setServerList(response["hydra:member"]);
        const ADLPServer = response["hydra:member"].find(
          (item) => item["@id"] === "/servers/1"
        );

        if (ADLPServer) {
          formik.setFieldValue("server", ADLPServer["@id"]);
        }
      })
      .catch((error) => {
        setServerList([]);
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [apiManager]);

  function getSelectListItem() {
    if (serverList && serverList) {
      return serverList.map((server, index) => {
        return (
          <MenuItem key={index} value={server["@id"]}>
            {server["serverName"]}
          </MenuItem>
        );
      });
    }
    return [];
  }

  return (
    <MuiSelect
      label="Liste des serveurs SFTP"
      labelId="server-select-label"
      id="server"
      name="server"
      margin="dense"
      value={formik.values.server}
      fullWidth
      disabled={isLoading}
      onChange={formik.handleChange}
      className={className}
      error={
        Boolean(formik.touched.server) && Boolean(formik.errors.server)
          ? formik.errors.server
          : ""
      }
    >
      {getSelectListItem()}
    </MuiSelect>
  );
}
