import React from 'react';
import locales from '../../../../locale/locales'

// Material-ui
import { Chip, Avatar } from '@material-ui/core'

// Utils
import MaterialTable from 'material-table'
import { numberHelper } from '../../../../utils/datamatchHelpers'

export default function PotentialByPartnersDataTable (props) {

  const {data} = {...props}

  /**
   * Enrich the data with a "TOTAL" line for each column
   * TODO: this method is a hack because the material-component is not able to display a symmary line yet (such as a 'total' line)
   */
  function getFullData(){
    var finalData = data.slice() // Cloning inital data
    var total = { ID_PART: 'TOTAL', PARTENAIRE: 'TOTAL', NB_POSTAL: 0, NB_EMAIL: 0, NB_SMS: 0, NB_TEL: 0 }
    finalData.forEach( (entry) =>  {
      total.NB_POSTAL += entry.NB_POSTAL
      total.NB_EMAIL += entry.NB_EMAIL
      total.NB_SMS += entry.NB_SMS
      total.NB_TEL += entry.NB_TEL
    })
    finalData.push(total)
    return finalData
  }

  function getTotalCellStyle(){
    return { fontWeight:  'bold' }
  }

  /**
   * Handling Organizations summary table
   */
  const columns = [
    { 
      title: 'Partenaire', 
      field: 'PARTENAIRE',
      cellStyle: (cellData, rowData) => { if(rowData.ID_PART === 'TOTAL'){ return getTotalCellStyle() }},
    },
    { 
      title: 'Postal', 
      field: 'NB_POSTAL',
      type: 'numeric',
      cellStyle: (cellData, rowData) => { if(rowData.ID_PART === 'TOTAL'){ return getTotalCellStyle() }},
      render: (data) => {return numberHelper.formatToDecimal(data.NB_POSTAL)}
    },
    { 
      title: 'Email', 
      field: 'NB_EMAIL',
      type: 'numeric',
      cellStyle: (cellData, rowData) => { if(rowData.ID_PART === 'TOTAL'){ return getTotalCellStyle() }},
      render: (data) => {return numberHelper.formatToDecimal(data.NB_EMAIL)}
    },
    { 
      title: 'SMS', 
      field: 'NB_SMS',
      type: 'numeric',
      cellStyle: (cellData, rowData) => { if(rowData.ID_PART === 'TOTAL'){ return getTotalCellStyle() }},
      render: (data) => {return numberHelper.formatToDecimal(data.NB_SMS)}
    },
    { 
      title: 'Téléphone', 
      field: 'NB_TEL',
      type: 'numeric',
      cellStyle: (cellData, rowData) => { if(rowData.ID_PART === 'TOTAL'){ return getTotalCellStyle() }},
      render: (data) => {return numberHelper.formatToDecimal(data.NB_TEL)}
    },
    {
      title: 'Date MAJ',
      field: 'DT_UPDATE',
      render: (data) => {
        if(data.ID_PART !== 'TOTAL'){
          return new Date(data.DT_UPDATE).toLocaleDateString()
        }
      }
    },
    // Display a badge to tell if the data are expired
    {
      title: '',
      render: (data) => {
        const lapsingInterval = (1000*60*60*24*30) * 3 // Duration in seconds for 3 month
        const updatedAt = new Date(data.DT_UPDATE)
        const now = new Date(Date.now())
        if( (now-updatedAt) > lapsingInterval){
          return <Chip
            label='Données périmées'
            color='secondary'
            size='small'
            avatar={<Avatar>!</Avatar>}
          />
        }
      }
    }
  ]

  return (
    <MaterialTable
      title=''
      columns={columns}
      data={getFullData()}
      localization={locales('materialTable')}
      components={{
        Container: props => <div>{props.children}</div>,
        //Container: React.Fragment
      }}
      options={{
        padding: 'dense', 
        showTitle: false, 
        paging: false,
        sorting: false,
        maxBodyHeight: '450px', 
        minBodyHeight: '450px', 
        exportAllData: true,
        exportButton: true,
        exportFileName: 'potential-by-partners',
        emptyRowsWhenPaging: false,
        exportDelimiter: ';',
        rowStyle: rowData => {
          if(rowData.ID_PART === 'TOTAL' ){
            return { backgroundColor:  '#EEE'}
          }
        }
      }}
    />
  );
  
}
