import React from "react";
import PropTypes from "prop-types";

import MuiSelect from "../../../components/MuiSelect";

// Material-UI
import { Input, MenuItem } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
  },
}));

/**
 * Handle the role select input for the userAccounts form
 * The input handle 3 values : ROLE_ADMIn, ROLE_ORG_ADMIN, ROLE_USER
 *
 * The available values depends on the selected organization
 * - ROLE_ADMIN is the reserved role for organisation "ADLP" (the organization carry the 1 ID)
 * - The other roles are for any other organisation
 * @param {*} props
 */
function RoleSelect(props) {
  const classes = useStyles();

  const { organization, value, handleChange, error, isSuperAdmin } = {
    ...props,
  };

  /**
   * Handle the Rôle input depending on the selected organization
   */
  function getRoleFormInput(props) {
    const { error, organization, handleChange } = { ...props };

    let roleInput;
    const [name, id] = ["roles", "roles"];
    if (isSuperAdmin(organization)) {
      roleInput = (
        <Input
          name={name}
          id={id}
          onChange={handleChange}
          value="ROLE_ADMIN"
          type="hidden"
          error={Boolean(error)}
        />
      );
    } else {
      roleInput = (
        <MuiSelect
          label="Rôle"
          labelId="role-label"
          id={id}
          className={classes.select}
          name={name}
          value={value}
          onChange={handleChange}
          error={error}
        >
          <MenuItem key={1} value="ROLE_ORG_ADMIN">
            ROLE_ORG_ADMIN
          </MenuItem>
          <MenuItem key={2} value="ROLE_USER">
            ROLE_USER
          </MenuItem>
          <MenuItem key={3} value="ROLE_COUNTER">
            ROLE_COUNTER
          </MenuItem>
        </MuiSelect>
      );
    }
    return roleInput;
  }

  return getRoleFormInput({
    organization: organization,
    value: value,
    handleChange: handleChange,
    error: error,
  });
}

RoleSelect.propTypes = {
  value: PropTypes.string.isRequired, // The current role selected value ,
  handleChange: PropTypes.func.isRequired, // To control the input onChange
  error: PropTypes.bool.isRequired, // If the field is in error
  organization: PropTypes.string.isRequired, // The current selected organisation (an organization IRI),
  isSuperAdmin: PropTypes.func.isRequired, // Return true is the given organization is ADLP (facilitate ROLE_ADMIN management)
};

export default RoleSelect;
