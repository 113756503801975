import React from 'react';

// Material-ui 
import {Typography} from '@material-ui/core'

// Utils
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';


function CustomizedAxisTick(props) {
  
  const {x, y, payload} = {...props};
  
  return (
    <g transform={`translate(${x-10},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">{payload.value}</text>
    </g>
  );
  
};

export default function PotentialByPartnersChart (props) {

  const {data} = {...props}

  /**
   * Notes : 
   * - Witdh 99% on ResponsiveContainer to make responsive work. See : https://github.com/recharts/recharts/issues/1423 
   * {ID_PART: "1", PARTENAIRE: "FA", NB_POSTAL: "3957444", NB_EMAIL: "3774327", NB_SMS: "4467016", …}
   */
  return (
    <div style={{ width: '100%', height: 550 }}>
      <Typography variant="h6" component="h3" >Potentiel par partenaire</Typography>
      <ResponsiveContainer width="99%"> 
        <BarChart
          data={data}
          margin={{
            top: 60, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="PARTENAIRE" tick={<CustomizedAxisTick/>} height={130} interval={0} />
          <YAxis />
          <Tooltip />
          <Legend  verticalAlign="top" height={36} />
          <Bar name="Postal" dataKey="NB_POSTAL" stackId="a" fill="#0088FE" />
          <Bar name="Email" dataKey="NB_EMAIL" stackId="a" fill="#00C49F" />
          <Bar name="SMS" dataKey="NB_SMS" stackId="a" fill="#FFBB28" />
          <Bar name="Tel." dataKey="NB_TEL" stackId="a" fill="#FF8042" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
  
}
