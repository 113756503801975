import React, { useState } from "react";

import * as apiClient from "../utils/apiClient";
import * as authClient from "../utils/authClient";

const AuthContext = React.createContext();

/**
 * The AuthProvider is a HOC that will conduct the app behaviour depending on the user auth state
 * It is the main interlocutor of the App component.
 *
 * The AuthProvider provide to the app :
 * - A way to manage the user authentication : login / logout / refresh token
 * - It will also provide a way to the app to make all the API call as this API call will be enteriley dependant on the uset authentication status.
 *
 * # Note about Authentication & RefreshToken
 * When a user log in, the API provides two different tokens :
 * 1- The main token : handle the user data and data about his current session (bt mostly the session duration)
 * 2- A refreshtoken : the refresh token provide a way to extend the session duration of the user, when this one is active on the application.
 *
 *
 * # Note about API call
 * The AuthProvider should be the only entry point to make API call so that if the user make a request while his session is expired
 * the App can be notified and adapt its behaviour (For example, redirect the user to the login page)
 *
 */
function AuthProvider(props) {
  const [authState, setAuthState] = useState({
    isInitialized: false,
    isExpired: false,
    isAuthenticated: false,
  });

  const isAdmin = authClient.isAdmin();
  const isCounter = authClient.isCounter();
  const currentUser = authClient.getUser();

  const authTokenManager = authClient.authTokenManager;
  const refreshTokenManager = authClient.refreshTokenManager;

  const isUserAdmin = () => {
    const result = currentUser.roles.find((item) => item === "ROLE_ADMIN");

    return result === "ROLE_ADMIN";
  };

  const isUserOrgAdmin = () => {
    const result = currentUser.roles.find((item) => item === "ROLE_ORG_ADMIN");

    return result === "ROLE_ORG_ADMIN";
  };

  /**
   * The API manager expose every methods required by the App to make actual API call
   */
  const apiManager = (function () {
    const withCheckAuth = async (callback) => {
      try {
        const checkAuthRes = await authManager.checkAuth();
        if (checkAuthRes) {
          if (authTokenManager.get()) {
            return new Promise((resolve, reject) => {
              callback()
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          } else {
            return new Promise((resolve, reject) => {
              reject("Le token d'authentification est introuvable");
            });
          }
        } else {
          // TODO ???
        }
      } catch (error) {
        throw error;
      }
    };

    // GET API call Without authentication
    const unauthenticatedGet = async (endpoint, data) => {
      return apiClient.get(false, endpoint, data);
    };
    
    const unauthenticatedPost = async (endpoint, data) => {
      return apiClient.getPostedData(true, endpoint, data);
    };

    // [Authenticated] Simple GET
    const get = async (endpoint, data) => {
      return withCheckAuth(() => {
        return apiClient.get(true, endpoint, data);
      });
    };

    // [Authenticated] get based on an IRI
    const getRessource = async (IRI) => {
      return withCheckAuth(() => {
        return apiClient.getRessource(true, IRI);
      });
    };

    // [Authenticated] Fetch the API to GET all ressources based on an entity name
    const getRessources = async (entity, data) => {
      return withCheckAuth(() => {
        return apiClient.getRessources(true, entity, data);
      });
    };

    const getPostedData = async (entity, data) => {
      return withCheckAuth(() => {
        return apiClient.getPostedData(true, entity, data);
      });
    };

    // [Authenticated] Create a ressource based on a entity name and the new entity data
    const createRessource = async (entity, data) => {
      return withCheckAuth(() => {
        return apiClient.createRessource(true, entity, data);
      });
    };

    // [Authenticated] Update a ressource based on the entity IRI and the new data
    const updateRessource = async (IRI, data) => {
      return withCheckAuth(() => {
        return apiClient.updateRessource(true, IRI, data);
      });
    };

    // [Authenticated] Patch a ressource based on the entity IRI and the new data
    const patchRessource = async (IRI, data) => {
      return withCheckAuth(() => {
        return apiClient.patchRessource(true, IRI, data);
      });
    };

    // [Authenticated] Delete a ressource based on the entity IRI
    const deleteRessource = async (IRI, data) => {
      return withCheckAuth(() => {
        return apiClient.deleteRessource(true, IRI);
      });
    };

    return {
      unauthenticatedGet: unauthenticatedGet,
      get: get,
      getRessource: getRessource,
      getRessources: getRessources,
      createRessource: createRessource,
      updateRessource: updateRessource,
      patchRessource: patchRessource,
      deleteRessource: deleteRessource,
      getPostedData: getPostedData,
      unauthenticatedPost
    };
  })();

  /**
   * Session manager : Expose all methods usefull to manage user session : login, loggout, getUser ...
   * TODO : the authManager should probably also handle the methods isAuthenticated, isInitialized...
   * and maybe the authState ?
   */
  const authManager = (function () {
    /**
     * Check if the user authentication is still valid and renew the authentication is possible.
     * 1. Check if an auth token is defined
     * 2. Check if the auth token is still valid (not expired) and try to renew it
     * 3. To renew a token, the refresh_token need to be defined
     *
     * checkAuth must
     */
    const checkAuth = async function () {
      return new Promise((resolve, reject) => {
        // Is authentication expired ?
        if (authTokenManager.get() && authTokenManager.isExpired()) {
          refreshToken()
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              reject(false);
            });
        } else {
          // Token not expired, everything is fine, show must go one
          resolve(true);
        }
      });
    };

    const handleRefreshResponse = (response) => {
      authTokenManager.set(response["token"]);
      refreshTokenManager.set(response["refresh_token"]);
    };

    const refreshToken = async () => {
      if (refreshTokenManager.get()) {
        return new Promise((resolve, reject) => {
          refreshTokenManager
            .refreshToken()
            .then((response) => {
              // Processing handle refreshReponse
              handleRefreshResponse(response);
              resolve();
            })
            .catch(() => {
              setAuthState((prevState) => {
                return {
                  ...prevState,
                  isExpired: true,
                  isAuthenticated: false,
                };
              });
              reject("Votre session a expirée, merci de vous reconnecter.");
            });
        });
      } else {
        setAuthState((prevState) => {
          return { ...prevState, isExpired: true, isAuthenticated: false };
        });
        return new Promise((resolve, reject) => reject("No refresh token"));
      }
    };

    // Initialize the user session base on tokens
    // TODO: should probably be merged with checkAuth so that refresh_token is refreshed at initialization
    const initializeAuthState = async () => {
      if (!authState.isInitialized) {
        // Manage authToken : deleting token if exists & if expired
        if (authTokenManager.get()) {
          if (authTokenManager.isExpired()) {
            authTokenManager.delete();
            refreshToken()
              .then(() => {
                setAuthState((prevState) => {
                  return {
                    ...prevState,
                    isInitialized: true,
                    isAuthenticated: true,
                  };
                });
              })
              .catch(() => {
                setAuthState((prevState) => {
                  return {
                    ...prevState,
                    isInitialized: true,
                    isAuthenticated: false,
                    isExpired: true,
                  };
                });
              });
          } else {
            setAuthState((prevState) => {
              return {
                ...prevState,
                isInitialized: true,
                isAuthenticated: true,
              };
            });
          }
        } else {
          setAuthState((prevState) => {
            return {
              ...prevState,
              isInitialized: true,
              isAuthenticated: false,
            };
          });
        }
      }
    };

    /**
     * Log the user : trigger the API and store the response (user session management tokens)
     */
    const login = async ({ username, password }) => {
      return new Promise((resolve, reject) => {
        authClient
          .login({ username, password })
          .then((response) => {
            authTokenManager.set(response["token"]);
            refreshTokenManager.set(response["refresh_token"]);
            setAuthState((prevState) => {
              return { ...prevState, isExpired: false, isAuthenticated: true };
            });
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    const logout = async () => {
      await authClient.logout();
      setAuthState((prevState) => {
        return { ...prevState, user: null, isAuthenticated: false };
      });
    };

    const getUser = () => {
      return authClient.getUser();
    };

    /**
     * Initialization treatment
     */
    initializeAuthState();

    /**
     * Module interface
     */
    return {
      getUser: getUser,
      login: login,
      logout: logout,
      checkAuth: checkAuth,
    };
  })();

  // Check if session is initialized
  const isSessionInitialized = () => {
    return authState.isInitialized;
  };

  // Check if the user session has expired
  const isSessionExpired = () => {
    return authState.isExpired;
  };

  // Check authState to verify if the app is still in authentication mode
  const isAuthenticated = () => {
    return authState.isAuthenticated;
  };

  return (
    <AuthContext.Provider
      value={{
        isSessionInitialized,
        isSessionExpired,
        isAuthenticated,
        isAdmin,
        isCounter,
        apiManager,
        authManager,
        isUserAdmin,
        isUserOrgAdmin,
        daysLeftBeforePasswordExpiration: currentUser?.daysLeft,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
