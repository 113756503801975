import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

import CloseIcon from "@material-ui/icons/Close";

import ExtractionForm from "../../scenes/Query/scenes/ExtractionForm/ExtractionForm";

const drawerWidth = window.innerWidth / 1.25;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowY: "hidden",
  },
  content: {
    padding: theme.spacing(2),
    marginTop: 48,
    overflowY: "auto",
  },
}));

const ExtractionModal = ({ open, handleDrawerClose, query }) => {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={handleDrawerClose}
    >
      <AppBar position="absolute" color="secondary">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={classes.content}>
        <ExtractionForm queryData={query} />
      </Box>
    </Drawer>
  );
};

export default ExtractionModal;
