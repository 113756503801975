import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core/";

const useStyle = makeStyles((theme) => ({
  text: {
    whiteSpace: "pre-line",
  },
  root: {
    "&.plain": {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[500],
      color: "#ffffff",

      "&.success": {
        backgroundColor: theme.palette.success.main,
      },
      "&.info": {
        backgroundColor: theme.palette.info.main,
      },
      "&.warning": {
        backgroundColor: theme.palette.warning.main,
      },
      "&.error": {
        backgroundColor: theme.palette.error.main,
      },
    },

    "&.bordered": {
      padding: theme.spacing(2, 2, 2, 3),
      borderLeft: `5px solid ${theme.palette.grey[500]}`,
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[500],

      "&.success": {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
      },
      "&.info": {
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main,
      },
      "&.warning": {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
      },
      "&.error": {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
      },
    },
  },
}));

function AlertMsg(props) {
  const classes = useStyle();
  const className = props.className || "";
  const severity = props.severity || "info";
  const variant = props.variant || "bordered";

  return (
    <Box className={`${classes.root} ${severity} ${variant} ${className}`}>
      <Typography className={classes.text}>{props.children}</Typography>
    </Box>
  );
}

export default AlertMsg;
