import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles'

import {Grid, Typography, Box} from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  headerContainer:{
    margin: theme.spacing(0, 0, 2, 0)
  },  
}));

export default function MainTemplate(props) {

  const classes = useStyles()
  const {pageTitle, pageCategory, subtitle} = {...props}

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box className={classes.headerContainer}>
          <Typography variant="overline" display="block">{pageCategory}</Typography>
          <Typography component='h1' variant='h4' gutterBottom>{pageTitle}</Typography>
          {subtitle ? ( <Box className={classes.subtitle}>{subtitle}</Box> ) : null }
        </Box>
      </Grid>
      
      <Grid item xs={12} >
        {props.children}
      </Grid>
    </Grid>
  )
}

MainTemplate.propTypes = {
  pageTitle: PropTypes.string.isRequired, 
  pageCategory: PropTypes.string.isRequired,
  subtitle: PropTypes.node
}