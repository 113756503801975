import React from 'react'

import { useAuth } from '../../context/authContext'

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core/'


const useStyles = makeStyles(theme => ({ 
  root : {
    background: theme.palette.info.main,
    color: '#ffffff',
    padding: theme.spacing(2),
    textAlign: 'center'
  }

}))

export default function AuthBar() {
  
  const classes = useStyles()
  const { isSessionExpired } =  useAuth();
  
  function getExpiredSessionMsg(){
    return "Votre session a expirée, veuillez-vous reconnecter."
  }

  return (
    isSessionExpired() ? (
      <Box className={classes.root}>{getExpiredSessionMsg()}</Box>
    ) : ''
  )

}