import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Drawer, Box, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  backdropPropsRoot: {
  },
  paperPropsRoot : {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '90%',  
    },
  }, 
  paneHeader: {
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2, 1, 2),
    backgroundColor: theme.palette.secondary.main
  },
  paneHeaderBtn:{
    color: '#ffffff'
  },
  paneContent:{
    padding: theme.spacing(2, 3, 2, 3),
  },
}));


export default function RightPane(props) {

  const classes = useStyles();
  const {onClose} = {...props}

  const [opened, setOpened] = useState(props.isOpened)

  // Initialization
  React.useEffect(() => {
    setOpened(props.isOpened)
  }, [props])

  function handleDrawerClose(){
    setOpened(false)
    if(onClose) onClose() // Trigger a callback if specified
  }

  return (
  
    <Drawer 
      open={opened}
      anchor="right"
      onClose={onClose}
      className={classes.root}
      ModalProps={{
        BackdropProps: {
          classes: {
            root: classes.backdropPropsRoot
          }
        }
      }}
      PaperProps={{
        classes: {
          root: classes.paperPropsRoot
        }
      }}
    >
      <Box className={classes.paneHeader}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="primary"
          size="small"
          onClick={handleDrawerClose}
          className={classes.paneHeaderBtn}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box id="right-pane" className={classes.paneContent}>
        { props.children }
      </Box>
    </Drawer >

  );
}


RightPane.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
}