import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Breadcrumbs as MuiBreadcrumbs } from "@material-ui/core/";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import FolderIcon from "@material-ui/icons/Folder";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import TelegramIcon from "@material-ui/icons/Telegram";

const useStyles = makeStyles((theme) => ({
  breadcrumbItem: {
    display: "flex",
    alignItems: "center",
  },
  breadcrumbItemIcon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
  breadcrumbItemTitle: {
    fontWeight: 500,
  },
}));

export default function Breadcrumbs({
  organisationName,
  userName,
  folderName,
  channelName,
  displayChannel,
  queryName,
}) {
  const classes = useStyles();
  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" color="inherit" />}
      aria-label="counts-list-breadcrumb"
    >
      <Box className={classes.breadcrumbItem}>
        <BusinessIcon
          className={classes.breadcrumbItemIcon}
          fontSize="small"
          color="inherit"
        />
        <Typography
          className={classes.breadcrumbItemTitle}
          variant="body1"
          color="inherit"
        >
          {organisationName || "Toutes les entreprises"}
        </Typography>
      </Box>
      <Box className={classes.breadcrumbItem}>
        <PeopleIcon
          className={classes.breadcrumbItemIcon}
          fontSize="small"
          color="inherit"
        />
        <Typography
          className={classes.breadcrumbItemTitle}
          variant="body1"
          color="inherit"
        >
          {userName || "Tous les utilisateurs"}
        </Typography>
      </Box>

      {displayChannel ? (
        <Box className={classes.breadcrumbItem}>
          <FolderIcon
            className={classes.breadcrumbItemIcon}
            fontSize="small"
            color="inherit"
          />
          <Typography
            className={classes.breadcrumbItemTitle}
            variant="body1"
            color="inherit"
          >
            {queryName || "Tous les comptages"}
          </Typography>
        </Box>
      ) : (
        <Box className={classes.breadcrumbItem}>
          <FolderIcon
            className={classes.breadcrumbItemIcon}
            fontSize="small"
            color="inherit"
          />
          <Typography
            className={classes.breadcrumbItemTitle}
            variant="body1"
            color="inherit"
          >
            {folderName || "Tous les dossiers"}
          </Typography>
        </Box>
      )}
      {displayChannel && (
        <Box className={classes.breadcrumbItem}>
          <WifiTetheringIcon
            className={classes.breadcrumbItemIcon}
            fontSize="small"
            color="inherit"
          />
          <Typography
            className={classes.breadcrumbItemTitle}
            variant="body1"
            color="inherit"
          >
            {channelName || "Tous les canaux"}
          </Typography>
        </Box>
      )}
    </MuiBreadcrumbs>
  );
}
