import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

// Contexts
import { useAuth } from "./context/authContext";
import { useTheme } from "./context/themeContext";

// Components
import AuthBar from "./components/Layout/AuthBar";
import AuthenticatedApp from "./authenticatedApp";
import UnauthenticatedApp from "./unauthenticatedApp";

// Theme
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

// Locale
import "./utils/yupLocale"; // Forms validation messages

// Toast notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  toastContainer: {
    width: "initial",
    bottom: 68,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",

    "&  > .Toastify__toast": {
      borderRadius: theme.spacing(0.5),
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 2),

      "& > .Toastify__toast-body": {
        color: "#FFF",
        fontFamily: theme.typography.fontFamily,
      },
    },
  },
}));

function App() {
  const classes = useStyles();
  const { isSessionInitialized, isAuthenticated } = useAuth();
  const { getMuiThemeOptions, isPending } = useTheme();

  const datamatchTheme = createMuiTheme(getMuiThemeOptions());

  return isSessionInitialized() && !isPending ? (
    <Router>
      <MuiThemeProvider theme={datamatchTheme}>
        <AuthBar />
        {isAuthenticated() ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        <ToastContainer
          position="bottom-right"
          autoClose={2500}
          hideProgressBar
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          className={classes.toastContainer}
          closeButton={false}
        />
      </MuiThemeProvider>
    </Router>
  ) : (
    ""
  ); // TODO : global loader ?
}

export default App;
