import React from 'react'

import {makeStyles } from '@material-ui/core/styles'
import {Button, CircularProgress} from '@material-ui/core'

const useStyle =  makeStyles( (theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}))

function LoaderButton(props){

  const {className, isSubmitting, disabled, type, fullWidth, variant, color, onClick, size}= {...props }

  const classes = useStyle()

  /**
   * TODO : Unused code that could be used to style MUI component with not standard 'palette color' (or maybe other theme parameters)
   * This Wrapped button is a working component that handle only a custome 'color' attribute which is provided by the theme. 
   * However, the code throw console 'errors' because MUIButton doesn't accept specific 'color' attribute
   * More help here : https://github.com/mui-org/material-ui/issues/14185
   */
  /*
  const WrappedButton = withStyles(theme => {
    
    color = color ? color : 'primary'

    return {
    root: props =>
      props.variant === "contained"
        ? {
            color: '#ffffff',
            backgroundColor: theme.palette[color].main,
            "&:hover": {
              backgroundColor: theme.palette[color].dark,
              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                backgroundColor: theme.palette[color].main
              }
            }
          }
        : {}
    }
  })(MuiButton);*/



  return (
    <div className={className} style={{position: 'relative'}}>
      <Button
        type={type}
        fullWidth={fullWidth}
        variant={variant}
        color={color}
        onClick={onClick}
        disabled={disabled}
        size={size}
      >
        {props.children}
      </Button>
      {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}



export default LoaderButton