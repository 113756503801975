import React from "react";
import PropTypes from "prop-types";

import {
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core/";

function MuiSelect(props) {
  const {
    id,
    name,
    className,
    label,
    labelId,
    fullWidth,
    error,
    value,
    onChange,
    multiple,
    renderValue,
    disabled,
    startAdornment,
    endAdornment,
  } = { ...props };

  /**
   * Handling selects input + label
   */
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl
      variant="outlined"
      className={className}
      fullWidth={fullWidth}
      error={Boolean(error)}
    >
      <InputLabel ref={inputLabel} id={labelId} margin="dense">
        {label}
      </InputLabel>
      <Select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        labelWidth={labelWidth}
        margin="dense"
        multiple={multiple}
        renderValue={renderValue}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
      >
        {props.children}
      </Select>
      {Boolean(error) ? <FormHelperText>{error}</FormHelperText> : ""}
    </FormControl>
  );
}

MuiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  value: PropTypes.node,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default MuiSelect;
