import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { toast } from "react-toastify";
import moment from "moment";

import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";

import { useAuth } from "../../context/authContext";

import Button from "@material-ui/core/Button";

import "moment/locale/fr";

moment.locale("fr");

const drawerWidth = window.innerWidth;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowY: "hidden",
  },
  content: {
    padding: theme.spacing(2),
    marginTop: 48,
    marginBottom: 68,
    overflowY: "auto",
  },
  nameInput: {
    color: "initial !important",
    ...theme.typography.h4,
  },
  nameInputError: {
    color: "red",
  },
  errorText: {
    color: "red",
    marginLeft: theme.spacing(1),
    fontWeight: 600,
  },
  tooltip: {
    fontSize: 14,
  },
  controlBannerWrapperStyle: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
    zIndex: 500,
    backgroundColor: "#FFF",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const TemplateModal = ({ open, handleDrawerClose, modalData }) => {
  const classes = useStyles();
  const { apiManager } = useAuth();

  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [fields, setFields] = useState([]);
  const [fieldsData, setFieldsData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && open) {
      getFields();
      if (modalData) {
        setName(modalData.exportTemplateName);
        setFields(modalData.fields);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [open]);

  const formHasError = () => {
    if (name === "") {
      setError("Le nom est obligatoire pour continuer.");
      return true;
    } else if (fields.length === 0) {
      setError("Il n'y a pas de champs à exporter.");
      return true;
    } else {
      setError("");
      return false;
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const getFields = async () => {
    try {
      const result = await apiManager.getRessources("ExportFields");
      setFieldsData(result["hydra:member"]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCreateTemplate = async () => {
    if (!formHasError()) {
      try {
        toast.info("Création de la template en cours..");

        await apiManager.createRessource("exportTemplates", {
          exportTemplateName: name,
          fields: fields,
        });
        toast.success("Template créée !");
        handleDrawerClose();
        setTimeout(() => {
          window.location.reload(false);
        }, 500);
      } catch (e) {
        console.log(e);
        toast.error("Erreur lors de la création");
      }
    }
  };

  const handleSaveTemplate = async () => {
    if (!formHasError()) {
      try {
        toast.info("Création de la template en cours..");

        await apiManager.updateRessource(modalData["@id"], {
          exportTemplateName: name,
          fields: fields,
        });
        toast.success("Template sauvegardée !");
        handleDrawerClose();
        setTimeout(() => {
          window.location.reload(false);
        }, 500);
      } catch (e) {
        console.log(e);
        toast.error("Erreur lors de la sauvegarde");
      }
    }
  };

  const handleClose = () => {
    handleDrawerClose();
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleFieldsChange = (event) => {
    setFields(event.target.value);
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={handleClose}
    >
      <AppBar position="absolute" color="secondary">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="overline">Template</Typography>
          </Grid>

          <Grid item xs={6}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="Entrez le nom du comptage ici."
              arrow
              placement="top"
            >
              <InputBase
                id="standard-basic"
                classes={{
                  root: classes.nameInput,
                  error: classes.nameInputError,
                }}
                fullWidth
                value={name}
                onChange={handleNameChange}
                placeholder="Entrer le nom de la template"
                inputProps={{ "aria-label": "nom-template" }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel shrink labelId="fields-mutiple-chip-label">
                Champs à exporter
              </InputLabel>
              <Select
                color="secondary"
                labelId="fields-mutiple-chip-label"
                id="fields-mutiple-chip"
                multiple
                value={fields}
                onChange={handleFieldsChange}
                margin="dense"
                input={
                  <Input
                    id="fields-select-multiple-chip"
                    style={{ minHeight: 50 }}
                    color="secondary"
                  />
                }
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((item, index) => {
                      return (
                        <Chip
                          key={index}
                          label={item}
                          className={classes.chip}
                        />
                      );
                    })}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {fieldsData.map((item, index) => (
                  <MenuItem key={index} value={item.columnName}>
                    {item.columnName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Box className={classes.controlBannerWrapperStyle} boxShadow={6}>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography variant="body2" className={classes.errorText}>
                  {error}
                </Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end" spacing={2}>
                <Grid item>
                  {modalData ? (
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleSaveTemplate}
                    >
                      Sauvegarder la template
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleCreateTemplate}
                    >
                      Créér la template
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default TemplateModal;
