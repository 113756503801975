import * as React from "react";

import TextField from "@material-ui/core/TextField";

export default function TextBox(props) {
  return (
    <TextField
      {...props}
      id={props.id}
      variant="outlined"
      margin="dense"
      fullWidth
      inputProps={props.inputProps}
    />
  );
}
