import React from 'react';

//Material-ui
import {Grid, Paper} from '@material-ui/core'

// Components
import GlobalGenderPotentialChart from './GlobalPotentialChart'
import GenderPotentialByAgeRangeChart from './PotentialByAgeRangeChart'


import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  }
}));

const colors = ['#8884d8', '#00C49F'];

export default function PotentialByGenders() {

  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Grid container >
        <Grid item xs={12} md={4} >
          <GlobalGenderPotentialChart colors={colors} />
        </Grid>
        <Grid item xs={12} md={8} >
          <GenderPotentialByAgeRangeChart colors={colors} />
        </Grid>
      </Grid>
    </Paper>
  )
}