import React, { useEffect } from "react";
import { useAuth } from "./context/authContext";
import { toast } from "react-toastify";

import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";

import Layout from "./components/Layout";
import Dashboard from "./scenes/Dashboard/scenes";
import FolderListing from "./scenes/QueryFolders/scenes/Listing";
import UserListing from "./scenes/User/scenes/Listing";
import Profile from "./scenes/User/scenes/Profile";
import NotFound from "./scenes/404";

//

import CountsList from "./newScenes/countsList/countsList";
import CampaignsList from "./newScenes/campaignsList/campaignsList";
import AdvertisersList from "./newScenes/advertisers/advertisersList";
import OrdersList from "./newScenes/orders/ordersList";
import OrganizationsList from "./newScenes/organizations/organizationsList";
import TemplatesList from "./newScenes/templates/templatesList";
import PacksList from "./newScenes/packs/packsList";

function AuthenticatedApp() {
  const { isAdmin, daysLeftBeforePasswordExpiration, isCounter } = useAuth();

  useEffect(() => {
    if (daysLeftBeforePasswordExpiration <= 0) {
      toast.error(`Votre mot de passe a expiré, veuillez-le changer.`, {
        closeOnClick: true,
        autoClose: false,
      });
    } else {
      if (daysLeftBeforePasswordExpiration <= 10) {
        toast.warning(
          `Votre mot de passe expire dans ${daysLeftBeforePasswordExpiration} jours`,
          {
            closeOnClick: true,
            autoClose: false,
            closeButton: true,
          }
        );
      } else {
        toast.info(
          `Votre mot de passe expire dans ${daysLeftBeforePasswordExpiration} jours`,
          {
            hideProgressBar: false,
          }
        );
      }
    }
  }, [daysLeftBeforePasswordExpiration]);

  return (
    <div className="App">
      <Layout>
        {isAdmin && (
          <Switch>
            {daysLeftBeforePasswordExpiration <= 0 && (
              <Redirect exact from="/login" to="/profile" />
            )}
            <Redirect exact from="/login" to="/" />
            <PrivateRoute path="/" exact component={Dashboard} />
            <PrivateRoute path="/queries" exact component={CountsList} />
            <PrivateRoute path="/campaigns" exact component={CampaignsList} />
            <PrivateRoute path="/folders" exact component={FolderListing} />
            <PrivateRoute path="/users" exact component={UserListing} />
            <PrivateRoute path="/profile" exact component={Profile} />
            <PrivateRoute
              path="/advertisers"
              exact
              component={AdvertisersList}
            />
            <PrivateRoute path="/orders" exact component={OrdersList} />
            <PrivateRoute
              path="/organizations"
              exact
              component={OrganizationsList}
            />
            <PrivateRoute path="/packs" exact component={PacksList} />
            <PrivateRoute path="/templates" exact component={TemplatesList} />

            <Route exact={true} component={NotFound} />
          </Switch>
        )}

        {!isAdmin && (
          <>
            {isCounter ? (
              <Switch>
                {daysLeftBeforePasswordExpiration <= 0 && (
                  <Redirect exact from="/login" to="/profile" />
                )}
                <Redirect exact from="/login" to="/" />
                <Redirect exact from="/" to="/queries" />
                <PrivateRoute path="/queries" exact component={CountsList} />
                <PrivateRoute path="/folders" exact component={FolderListing} />
                <PrivateRoute path="/profile" exact component={Profile} />
                <Route exact={true} component={NotFound} />
              </Switch>
            ) : (
              <Switch>
                {daysLeftBeforePasswordExpiration <= 0 && (
                  <Redirect exact from="/login" to="/profile" />
                )}
                <Redirect exact from="/" to="/queries" />
                <Redirect exact from="/login" to="/queries" />
                <PrivateRoute path="/queries" exact component={CountsList} />
                <PrivateRoute
                  path="/campaigns"
                  exact
                  component={CampaignsList}
                />
                <PrivateRoute path="/folders" exact component={FolderListing} />
                <PrivateRoute path="/profile" exact component={Profile} />
                <PrivateRoute
                  path="/advertisers"
                  exact
                  component={AdvertisersList}
                />
                <PrivateRoute path="/orders" exact component={OrdersList} />
                <Route exact={true} component={NotFound} />
              </Switch>
            )}
          </>
        )}
      </Layout>
    </div>
  );
}

export default withRouter(AuthenticatedApp);
