import * as React from "react";
import moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";

import { Accordion as MuiAccordion } from "@material-ui/core/";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PhoneIcon from "@material-ui/icons/Phone";
import SmsIcon from "@material-ui/icons/Sms";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import PeopleIcon from "@material-ui/icons/People";
import AddIcon from "@material-ui/icons/Add";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useAuth } from "../../context/authContext";
import { formatNumber } from "../../helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  accordionSummaryRoot: {
    padding: theme.spacing(0, 1),
    paddingRight: 0,
    position: "relative",
  },
  queryCountWrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  accordionContent: {
    width: `calc(100% - 40px)`,
  },
  expandMoreIcon: {
    padding: theme.spacing(1),
    marginRight: 0,
  },
  menuIcon: {
    padding: theme.spacing(1),
  },
  summaryContentIconWrapper: {
    backgroundColor: theme.palette.secondary.main,
  },
  summaryContentTextWrapper: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
  },
  summaryDetails: {
    flexDirection: "column",
    padding: theme.spacing(0, 1, 1, 1),
    backgroundColor: theme.palette.grey[50],
  },
  criterionTitle: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
  criteriaList: {
    width: "100%",
    listStylePosition: "inside",
    margin: 0,
    paddingLeft: theme.spacing(1),
    listStyleType: "none",
    borderLeft: `2px solid ${theme.palette.grey[300]}`,
  },
  criteraRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  criterionSubtitle: {
    fontWeight: 500,
  },
  readOnlySubtitle: {
    pointerEvents: "none",
    position: "absolute",
    right: 0,
    bottom: 0,
    marginTop: theme.spacing(0.5),
    fontSize: "1rem",
    display: "flex",
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 0.5),
  },
}));

export default function Accordion({
  query,
  isLocked,
  handleExtractClick,
  handleModifyClick,
  handleDeleteClick,
  handleNewCampaignClick,
  userRoles,
  addToCountsList,
}) {
  const classes = useStyles();
  const { apiManager } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleMenuClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const renderChannel = (channel) => {
    switch (channel) {
      case "sms":
        return <SmsIcon />;
      case "phone":
        return <PhoneIcon />;
      case "email":
        return <AlternateEmailIcon />;
      default:
        return null;
    }
  };

  const renderGeoChoice = (choice) => {
    switch (choice) {
      case "cityCodes":
        return "Codes communes";
      case "deptCodes":
        return "Codes départements";
      case "irisCodes":
        return "Codes IRIS";
      case "address":
        return "Adresses";
      case "none":
        return "Aucun";
      default:
        return "Aucun";
    }
  };

  const renderCSP = (choice) => {
    if (choice === "none" || (choice && choice.length === 0)) {
      return "Aucun";
    } else {
      return _.join(choice, ", ")
        .replace("1", "CSP -")
        .replace("2", "CSP Moyen")
        .replace("3", "CSP +");
    }
  };

  const renderHomeType = (choice) => {
    if (choice === "none" || (choice && choice.length === 0)) {
      return "Aucun";
    } else {
      return _.join(choice, ", ")
        .replace("1", "Maison")
        .replace("2", "Appartement");
    }
  };

  const renderHomeStatus = (choice) => {
    if (choice === "none" || (choice && choice.length === 0)) {
      return "Aucun";
    } else {
      return _.join(choice, ", ")
        .replace("1", "Locataire")
        .replace("2", "Propriétaire");
    }
  };

  const isAdmin = _.includes(userRoles, "ROLE_ADMIN");

  const handleDuplicate = async (event) => {
    event.stopPropagation();

    try {
      toast.info("Duplication en cours...");

      const QUERY_ITEM = {
        queryName: `${query.queryName.replace("- copie", "")} - copie`,
        channel: query.channel,
        runDuration: query.runDuration,
        lastRun: query.lastRun,
        queryResultBy: query.queryResultBy,
        queryParams: query.queryParams,
        queryFolder: query.queryFolder,
        createdAt: query.createdAt,
        updatedAt: query.updatedAt,
        queryExports: query.queryExports,
        userAccount: query.userAccount,
      };

      const result = await apiManager.createRessource("queries", QUERY_ITEM);

      addToCountsList(result);
      toast.success("Duplication effectuée");
    } catch (e) {
      console.log(e);
      toast.error("Erreur lors de la duplication");
    }
  };

  return (
    <div className={classes.root}>
      <MuiAccordion>
        <AccordionSummary
          classes={{
            root: classes.accordionSummaryRoot,
            expandIcon: classes.expandMoreIcon,
            content: classes.accordionContent,
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container spacing={0} alignItems="center">
            <Avatar className={classes.summaryContentIconWrapper}>
              {renderChannel(query.channel)}
            </Avatar>

            <Grid item xs zeroMinWidth>
              <Box className={classes.summaryContentTextWrapper}>
                <Typography variant="h6" component="p" color="secondary" noWrap>
                  {query.queryName}
                </Typography>
                <Box className={classes.queryCountWrapper}>
                  <PeopleIcon fontSize="small" />
                  <Typography color="textSecondary" noWrap>
                    &nbsp;&nbsp;
                    {query?.queryCount ? formatNumber(query.queryCount) : "n/a"}
                  </Typography>
                </Box>
                <Box className={classes.readOnlySubtitle}>
                  <Typography variant="caption" color="inherit" noWrap>
                    {moment.utc(query.createdAt).format("DD/MM/YYYY")}
                    {isLocked && " - Lecture seule"}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <React.Fragment>
              <IconButton
                className={classes.menuIcon}
                aria-label="Options du comptage"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="query-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {isAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      handleExtractClick(event);
                      setAnchorEl(null);
                    }}
                  >
                    <React.Fragment>
                      <ListItemIcon aria-label="Demande d'extraction">
                        <ImportExportIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        Demande d'extraction
                      </Typography>
                    </React.Fragment>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={(event) => {
                    handleModifyClick(event);
                    setAnchorEl(null);
                  }}
                >
                  {isLocked ? (
                    <React.Fragment>
                      <ListItemIcon aria-label="Voir le résumé du comptage">
                        <VisibilityIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">Voir le résumé</Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ListItemIcon aria-label="Modifier le comptage">
                        <EditIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        Modifier le comptage
                      </Typography>
                    </React.Fragment>
                  )}
                </MenuItem>

                <MenuItem
                  onClick={(event) => {
                    handleDuplicate(event);
                    setAnchorEl(null);
                  }}
                  disabled={!query.queryCount}
                >
                  <ListItemIcon aria-label="Voir le résumé du comptage">
                    <FileCopyIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">
                    Dupliquer le comptage
                  </Typography>
                </MenuItem>

                {!isLocked && (
                  <MenuItem
                    onClick={(event) => {
                      handleDeleteClick(event);
                      setAnchorEl(null);
                    }}
                  >
                    <React.Fragment>
                      <ListItemIcon aria-label="Supprimer le comptage">
                        <DeleteIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        Supprimer le comptage
                      </Typography>
                    </React.Fragment>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={(event) => {
                    handleNewCampaignClick(event);
                    setAnchorEl(null);
                  }}
                >
                  <React.Fragment>
                    <ListItemIcon aria-label="Créer une campagne">
                      <AddIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      Créer une campagne
                    </Typography>
                  </React.Fragment>
                </MenuItem>
              </Menu>
            </React.Fragment>
          </Grid>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.summaryDetails }}>
          <Box>
            <Typography
              className={classes.criterionTitle}
              variant="overline"
              display="inline"
            >
              Critères géographiques
            </Typography>
            <ul className={classes.criteriaList}>
              <li>
                <Typography
                  className={classes.criterionValue}
                  variant="caption"
                  display="inline"
                >
                  {renderGeoChoice(query.queryParams.geoChoice)}
                </Typography>
              </li>
            </ul>
          </Box>

          <Box>
            <Typography
              className={classes.criterionTitle}
              variant="overline"
              display="block"
            >
              Critères socio-démographiques
            </Typography>
            <ul className={classes.criteriaList}>
              <li className={classes.criteraRow}>
                <Typography
                  className={classes.criterionSubtitle}
                  variant="caption"
                  display="inline"
                >
                  Genre :
                </Typography>
                <Typography variant="caption" display="inline">
                  {query.queryParams.gender ? (
                    <>
                      {query.queryParams.gender.M && "Homme"}
                      {query.queryParams.gender.M &&
                        query.queryParams.gender.F &&
                        "/"}
                      {query.queryParams.gender.F && "Femme"}
                      {!query.queryParams.gender.M &&
                        !query.queryParams.gender.F &&
                        "Tous les genres"}
                    </>
                  ) : (
                    "Tous les genres"
                  )}
                </Typography>
              </li>
              <li className={classes.criteraRow}>
                <Typography
                  className={classes.criterionSubtitle}
                  variant="caption"
                  display="inline"
                >
                  Age :
                </Typography>
                <Typography variant="caption" display="inline">
                  {query.queryParams.age
                    ? query.queryParams.age.checkAge
                      ? `Entre ${query.queryParams.age.min} et ${query.queryParams.age.max} ans`
                      : "Tous les âges"
                    : "Tous les âges"}
                </Typography>
              </li>
              <li className={classes.criteraRow}>
                <Typography
                  className={classes.criterionSubtitle}
                  variant="caption"
                  display="inline"
                >
                  CSP :
                </Typography>
                <Typography variant="caption" display="inline">
                  {renderCSP(query.queryParams.csp)}
                </Typography>
              </li>
              <li className={classes.criteraRow}>
                <Typography
                  className={classes.criterionSubtitle}
                  variant="caption"
                  display="inline"
                >
                  Type d'hab. :
                </Typography>
                <Typography variant="caption" display="inline">
                  {renderHomeType(query.queryParams.homeType)}
                </Typography>
              </li>
              <li className={classes.criteraRow}>
                <Typography
                  className={classes.criterionSubtitle}
                  variant="caption"
                  display="inline"
                >
                  Statut d'hab. :
                </Typography>
                <Typography variant="caption" display="inline" align="right">
                  {renderHomeStatus(query.queryParams.homeStatus)}
                </Typography>
              </li>
            </ul>
          </Box>
        </AccordionDetails>
      </MuiAccordion>
    </div>
  );
}
