import React, { useState } from "react";
import { useAuth } from "../../../../context/authContext";

//Material-ui
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Components
import PotentialByPartnersChart from "./PotentialByPartnersChart";
import PotentialByPartnersDataTable from "./PotentialByPartnersDataTable";

// Utils
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
}));

const chartDataUrl = "/api/stats/partners";

export default function PotentialByGenders() {
  const classes = useStyles();
  const { apiManager } = useAuth();

  const [isPending, setIsPending] = useState(true);
  const [partnerData, setPartnerData] = useState([]);

  React.useEffect(() => {
    function getChartData() {
      setIsPending(true);
      apiManager
        .get(chartDataUrl)
        .then((data) => {
          setPartnerData(data);
        })
        .catch((e) => {
          setPartnerData([]);
          toast.error(e);
        })
        .finally(() => setIsPending(false));
    }

    // Initializing data
    getChartData();
  }, [apiManager]); // apiManager will never change during the lycicle of the comoonent, so it should never re-run the effect

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          {isPending ? (
            "Chargement en cours"
          ) : (
            <PotentialByPartnersChart data={partnerData} />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          {isPending ? (
            "Chargement en cours"
          ) : (
            <PotentialByPartnersDataTable data={partnerData} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
