import React from 'react';

export default function RightPane(props) {

  const classes = props.useStyles();

  return (
    <main className={classes.content} align='left'>
      <div className={classes.toolbar} />
      {props.children}
    </main>

  );
}
