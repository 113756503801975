import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 160,

    "&:hover": {
      cursor: "pointer",

      "& > div": {
        border: `1px solid ${theme.palette.secondary.main}`,

        "& .MuiSvgIcon-root": {
          color: theme.palette.secondary.main,
        },
      },

      "& > p": {
        color: theme.palette.secondary.main,
      },
    },
  },
  rootSelected: {
    "& > div": {
      border: `1px solid ${theme.palette.secondary.main}`,

      "& .MuiSvgIcon-root": {
        color: theme.palette.secondary.main,
      },
    },

    "& > p": {
      color: theme.palette.secondary.main,
    },
  },
  box: {
    width: 160,
    height: 160,
    border: `1px solid ${theme.palette.grey[200]}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .MuiSvgIcon-root": {
      fontSize: "3rem",
    },
  },
  title: {
    fontSize: "1.15rem",
    marginTop: theme.spacing(1),
  },
}));

export default function ChannelItem({ icon, title, onClick, selected }) {
  const classes = useStyles();

  const rootClasses = clsx({
    [classes.root]: classes.root,
    [classes.rootSelected]: selected,
  });

  return (
    <div className={rootClasses} onClick={onClick}>
      <Box className={classes.box}>{icon}</Box>
      <Typography
        className={classes.title}
        variant="body2"
        color="initial"
        align="center"
      >
        {title}
      </Typography>
    </div>
  );
}
