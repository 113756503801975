import * as React from "react";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";

import { Accordion as MuiAccordion } from "@material-ui/core/";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Grid from "@material-ui/core/Grid";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DoneIcon from '@material-ui/icons/Done';
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PhoneIcon from "@material-ui/icons/Phone";
import SmsIcon from "@material-ui/icons/Sms";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import FolderIcon from "@material-ui/icons/Folder";

import { formatNumber } from "../../helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  accordionSummaryRoot: {
    padding: theme.spacing(0, 1),
    paddingRight: 0,
    position: "relative",
  },
  queryCountWrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  accordionContent: {
    width: `calc(100% - 40px)`,
  },
  expandMoreIcon: {
    padding: theme.spacing(1),
    marginRight: 0,
  },
  menuIcon: {
    padding: theme.spacing(1),
  },
  summaryContentIconWrapper: {
    backgroundColor: theme.palette.secondary.main,
  },
  summaryContentTextWrapper: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
  },
  summaryDetails: {
    flexDirection: "column",
    padding: theme.spacing(0, 1, 1, 1),
    backgroundColor: theme.palette.grey[50],
  },
  criterionTitle: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
  criteriaList: {
    width: "100%",
    listStylePosition: "inside",
    margin: 0,
    paddingLeft: theme.spacing(1),
    listStyleType: "none",
    borderLeft: `2px solid ${theme.palette.grey[300]}`,
  },
  criteraRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  criterionSubtitle: {
    fontWeight: 500,
  },
  readOnlySubtitle: {
    pointerEvents: "none",
    position: "absolute",
    right: 0,
    bottom: 0,
    marginTop: theme.spacing(0.5),
    fontSize: "1rem",
    display: "flex",
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 0.5),
  },
}));

export default function Accordion({
  query,
  isLocked,
  handleOrderPress,
  handleModifyClick,
  handleDeleteClick,
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleMenuClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const renderChannel = (channel) => {
    switch (channel) {
      case "sms":
        return <SmsIcon />;
      case "phone":
        return <PhoneIcon />;
      case "email":
        return <AlternateEmailIcon />;
      default:
        return null;
    }
  };

  const renderChannelInformation = (channel) => {
    switch (channel) {
      case "sms":
        return "SMS";
      case "phone":
        return "Téléphone";
      case "email":
        return "E-mail";
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <MuiAccordion>
        <AccordionSummary
          classes={{
            root: classes.accordionSummaryRoot,
            expandIcon: classes.expandMoreIcon,
            content: classes.accordionContent,
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container spacing={0} alignItems="center">
            <Avatar className={classes.summaryContentIconWrapper}>
              {renderChannel(query.campaignChannel)}
            </Avatar>

            <Grid item xs zeroMinWidth>
              <Box className={classes.summaryContentTextWrapper}>
                <Typography variant="h6" component="p" color="secondary" noWrap>
                  {query.campaignName}
                </Typography>
                <Box className={classes.queryCountWrapper}>
                  <FolderIcon fontSize="small" />
                  <Typography color="textSecondary" noWrap>
                    &nbsp;&nbsp;{query ? query.query.queryName : "n/a"}
                  </Typography>
                </Box>
                <Box className={classes.readOnlySubtitle}>
                  <Typography variant="caption" color="inherit" noWrap>
                    {moment.utc(query.createdAt).format("DD/MM/YYYY")}
                    {isLocked && " - Lecture seule"}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <React.Fragment>
              <IconButton
                className={classes.menuIcon}
                aria-label="Options du comptage"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="query-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={(event) => {
                    handleModifyClick(event);
                    setAnchorEl(null);
                  }}
                >
                  {isLocked ? (
                    <React.Fragment>
                      <ListItemIcon aria-label="Voir le résumé du comptage">
                        <VisibilityIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">Voir le résumé</Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ListItemIcon aria-label="Modifier la campagne">
                        <EditIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        Modifier la campagne
                      </Typography>
                    </React.Fragment>
                  )}
                </MenuItem>
                {!isLocked && (
                  <>
                    <MenuItem
                      onClick={(event) => {
                        handleDeleteClick(event);
                        setAnchorEl(null);
                      }}
                    >
                      <React.Fragment>
                        <ListItemIcon aria-label="Supprimer la campagne">
                          <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          Supprimer la campagne
                        </Typography>
                      </React.Fragment>
                    </MenuItem>
                    {/* <MenuItem
                      onClick={(event) => {
                        handleOrderPress(event);
                        setAnchorEl(null);
                      }}
                    >
                      <React.Fragment>
                        <ListItemIcon aria-label="Commander la campagne">
                          <DoneIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          Commander
                        </Typography>
                      </React.Fragment>
                    </MenuItem> */}
                  </>
                )}
                {/* <DeleteQueryBtn queryId={queryData['id']} queryIRI={queryData['@id']} closeParentMenu={handleClose} onDelete={onQueryDelete} /> */}
              </Menu>
            </React.Fragment>
          </Grid>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.summaryDetails }}>
          <Box>
            <Typography
              className={classes.criterionTitle}
              variant="overline"
              display="inline"
            >
              Information
            </Typography>
            <ul className={classes.criteriaList}>
              <li className={classes.criteraRow}>
                <Typography
                  className={classes.criterionSubtitle}
                  variant="caption"
                  display="inline"
                >
                  Canal :
                </Typography>
                <Typography variant="caption" display="inline">
                  {renderChannelInformation(query.campaignChannel)}
                </Typography>
              </li>
              <li className={classes.criteraRow}>
                <Typography
                  className={classes.criterionSubtitle}
                  variant="caption"
                  display="inline"
                >
                  Volume :
                </Typography>
                <Typography variant="caption" display="inline">
                  {formatNumber(query.campaignVolume)   || "n/a"}
                </Typography>
              </li>
              <li className={classes.criteraRow}>
                <Typography
                  className={classes.criterionSubtitle}
                  variant="caption"
                  display="inline"
                >
                  Commence le :
                </Typography>
                <Typography variant="caption" display="inline">
                  {query.campaignStartDate
                    ? moment.utc(query.campaignStartDate).format("DD/MM/YYYY")
                    : "n/a"}
                </Typography>
              </li>
            </ul>
          </Box>
        </AccordionDetails>
      </MuiAccordion>
    </div>
  );
}
