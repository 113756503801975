import client from "./apiClient";
import decode from "jwt-decode";

const localStorage = {
  authTokenKey: "__datamatch_token__",
  refreshTokenKey: "__datamatch_refresh_token__",
};

/**
 * Tokens managemement
 * setter / getter / eraser
 */
function setAuthToken(token) {
  window.localStorage.setItem(localStorage.authTokenKey, token);
}
function getAuthToken() {
  return window.localStorage.getItem(localStorage.authTokenKey);
}
function deleteAuthToken() {
  window.localStorage.removeItem(localStorage.authTokenKey);
}
function isAuthTokenExpired() {
  return getAuthToken() ? isTokenExpired(getAuthToken()) : true;
}

const authTokenManager = {
  set: setAuthToken,
  get: getAuthToken,
  delete: deleteAuthToken,
  isExpired: isAuthTokenExpired,
};

function setRefreshToken(token) {
  window.localStorage.setItem(localStorage.refreshTokenKey, token);
}
function getRefreshToken() {
  return window.localStorage.getItem(localStorage.refreshTokenKey || null);
}
function deleteRefreshToken() {
  window.localStorage.removeItem(localStorage.refreshTokenKey);
}

// Async method to refresh the user token
async function refreshToken() {
  return client(false, "/token/refresh", {
    data: { refresh_token: getRefreshToken() },
  });
}

const refreshTokenManager = {
  set: setRefreshToken,
  get: getRefreshToken,
  delete: deleteRefreshToken,
  refreshToken: refreshToken,
};

function getUser() {
  const token = getAuthToken();
  try {
    const decoded = token ? decode(token) : null;
    if (decoded) {
      return decoded;
    }
    return null;
  } catch (err) {
    throw err;
  }
}

/**
 * Authentication manager
 * login / logout / refresh token / helper function
 */

// Async method to authenticate the user
async function login({ username, password }) {
  return client(false, "/token/authentication", {
    data: { email: username, password: password },
  });
}

// Logout method : delete every auth tokens
async function logout() {
  deleteAuthToken();
  deleteRefreshToken();
  return Promise.resolve();
}

// Generic method to check if a JWT token is expired or not
function isTokenExpired(token) {
  try {
    const decoded = decode(token);

    /**
     * The token expiration is determined based on the comparison between its expiration timestamp and Now() timestamp
     * -> However we should handle a period delay due to the communication time between the frontend and the backend,
     * so we advance the expiration time of 3 seconds
     */
    const expirationLimit = Date.now() / 1000 + 3;

    const isTokenExpired = decoded.exp < expirationLimit; // Checking if token is expired (token is timestamp in seconds)
    return isTokenExpired;
  } catch (err) {
    throw err;
  }
}

// TODO : should be moved out, to be placed inside a dedicated role manager
function isAdmin() {
  const user = getUser();
  return (
    user && user["roles"] !== undefined && user["roles"].includes("ROLE_ADMIN")
  );
}

function isCounter() {
  const user = getUser();
  return (
    user &&
    user["roles"] !== undefined &&
    user["roles"].includes("ROLE_COUNTER")
  );
}

export {
  login,
  logout,
  getAuthToken,
  getUser,
  refreshToken,
  isAdmin,
  isCounter,
  authTokenManager,
  refreshTokenManager,
};
