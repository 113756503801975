import React, { useState, useEffect, useCallback } from "react";
import useStateWithCallback from "use-state-with-callback";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import MaterialTable from "material-table";

import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "../../context/authContext";

const useStyles = makeStyles((theme) => ({
  panelWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[2],
  },
  countsTitleWrapper: {
    margin: theme.spacing(1, 0),
  },
  listWrapper: {
    flexGrow: 1,
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4, 2),
  },
  redChip: {
    backgroundColor: "orangered",
    color: "white",
  },
  blueChip: {
    backgroundColor: "deepskyblue",
    color: "white",
  },
  greenChip: {
    backgroundColor: "lawngreen",
    color: "white",
  },
  yellowChip: {
    backgroundColor: "gold",
    color: "white",
  },
}));

const materialTableLocalization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} sur {count}",
    labelRowsSelect: "lignes",
    labelRowsPerPage: "lignes par page:",
  },
  toolbar: {
    nRowsSelected: "{0} saisie(s) séléctionnée(s)",
    exportTitle: "Exporter",
    searchPlaceholder: "Rechercher",
    searchTooltip: "Rechercher",
  },
  header: {
    actions: "Actions",
  },
  body: {
    emptyDataSourceMessage: "Aucune saisies entrées",
    filterRow: {
      filterTooltip: "Filtrer",
    },
  },
};

export default (props) => {
  const classes = useStyles();
  const { apiManager } = useAuth();

  const [ordersListItems, setOrdersListItems] = useStateWithCallback(
    [],
    (data) => {
      if (!data) {
        setListLoading(true);
      } else {
        setListLoading(false);
      }
    }
  );

  const [listLoading, setListLoading] = useState(true);

  const getPacksCallback = useCallback(getPacks, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getPacksCallback();
    }

    return () => {
      isMounted = false;
    };
  }, [getPacksCallback]);

  // Get packs
  async function getPacks() {
    setListLoading(true);
    try {
      const result = await apiManager.getRessource(`/api/customer_packs`);
      const data = await result["hydra:member"];
      setListLoading(false);

      if (data) {
        setOrdersListItems(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="overline">Gestion des packs</Typography>
            <Typography component="h1" variant="h4" color="initial">
              Liste des packs
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            className={`${classes.panelWrapper} ${classes.listWrapper}`}
            boxShadow={3}
          >
            <Box className={classes.countsTitleWrapper}>
              <Typography variant="overline">{`${
                ordersListItems ? ordersListItems?.length : "..."
              } commande(s)`}</Typography>
            </Box>

            {listLoading ? (
              <Box className={classes.loaderWrapper}>
                <CircularProgress color="secondary" />
              </Box>
            ) : ordersListItems?.length !== 0 ? (
              <Box>
                <Grid item xs={12}>
                  <MaterialTable
                    columns={[
                      {
                        title: "Nom",
                        field: "fullPackName",
                      },
                      {
                        title: "Crée le",
                        render: (rowData) =>
                          moment.utc(rowData.createdAt).format("DD/MM/YYYY"),
                        editable: "never",
                        customSort: (a, b) =>
                          new Date(b.createdAt) - new Date(a.createdAt),
                        defaultSort: "asc",
                      },
                      {
                        title: "Créé par",
                        field: "createdBy.fullName",
                      },
                      {
                        title: "Volume",
                        field: "volume",
                        type: "numeric",
                      },
                      {
                        title: "Canal",
                        field: "packChannel",
                      },
                      {
                        title: "Client",
                        field: "customer.CompanyName",
                      },
                    ]}
                    data={ordersListItems}
                    options={{
                      exportButton: true,
                      exportAllData: true,
                      showTitle: false,
                      pageSize: 20,
                    }}
                    localization={materialTableLocalization}
                  />
                </Grid>
              </Box>
            ) : (
              <Box className={classes.loaderWrapper}>
                <Typography variant="h6" color="textSecondary" align="center">
                  Aucune commande
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
