import React from "react";
import { useAuth } from "../../../../context/authContext";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Material-ui
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormLabel,
  InputAdornment,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core/";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

// Utils
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { fileHelper, dateHelper } from "../../../../utils/datamatchHelpers";

// Components
import ServerSelectList from "./components/ServerSelectList";
import ExportFieldSelectList from "./components/ExportFieldSelectList";
import LoaderButton from "../../../../components/LoaderButton";
import AlertMsg from "../../../../components/AlertMsg";

import TextBox from "../../../../components/forms/TextBox";
import { FormatLineSpacing } from "@material-ui/icons";

import SearchBoxList from "../../../../components/forms/SearchBoxList";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "600px",
  },
  resetButton: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  deleteIconButton: {
    padding: theme.spacing(0.25),
  },
  tableLoaderWrapper: {
    display: "table-row",
  },
  tableLoaderInnerWrapper: {
    padding: theme.spacing(2),
    display: "table-cell",
    textAlign: "center",
  },
}));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});
// Generate a default fileName for the current export with this format : "aaammdd-[queryName]"
function getDefaultFileName(queryName) {
  const baseName = fileHelper.getFilenameFromString(queryName);
  const fileName = `${dateHelper.formatDateToYYYYMMDD(Date.now())}-${baseName}`;
  return fileName;
}

/**
 * Variable usefull to handle the fileName constraint
 */
const fileNameRegex = /^[a-zA-Z0-9_-]*$/; // The regex the fiilename must match
const fileNameHelpText = // The message to help the user
  (
    <AlertMsg severity="info">
      <strong>
        Lors de la saisie du nom du fichier d'export, seuls les caractères
        suivants sont autorisés :
      </strong>
      <ul>
        <li>
          <strong>[ a-z ] [ A-Z ] </strong> <br />
          Les caractères alphabétiques non accentués (en minuscule ou majuscule)
        </li>
        <li>
          <strong>[ 0-9 ]</strong> <br />
          Les caractères numériques
        </li>
        <li>
          <strong>[ - ] [ _ ]</strong>
          <br />
          Les séparateurs "underscore" [ _ ] et "tiret" [ - ]
        </li>
      </ul>
    </AlertMsg>
  );

function ExtractionForm(props) {
  // Initialization
  const { apiManager } = useAuth();
  const classes = useStyles();
  const { queryData } = { ...props };
  const [tableData, setTableData] = React.useState(null);
  const [tableDataBase, setTableDataBase] = React.useState(null);
  const [templates, setTemplates] = React.useState(null);
  const [templateValue, setTemplateValue] = React.useState(null);
  const [templateInputValue, setTemplateInputValue] = React.useState("");
  const [tableLoading, setTableLoading] = React.useState(false);
  const [volume, setVolume] = React.useState(
    queryData ? queryData.queryCount : null
  );

  let defaultFileName = getDefaultFileName(queryData.queryName);

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getPartners();
      getTemplates();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = (values, actions) => {
    const sentValues = {
      ...values,
      exportFields: templateValue.fields,
      exportPartners: tableData,
      exportVolume: volume,
    };

    let queryExtract = null;
    apiManager
      .createRessource("queryExports", sentValues)
      .then((response) => {
        toast.success("Votre demande d'extraction à bien été prise en compte");
        queryExtract = response;
        if (queryExtract != null) {
          apiManager.getRessource(`/api/query_exports/submit/${queryExtract.id}`);
        }
        actions.resetForm();
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  function handleFileNameChange(formik, e) {
    let newValue = e.target.value;

    if (newValue.match(fileNameRegex) != null) {
      formik.setFieldValue("fileName", newValue);
    }
  }

  // Form validation schema
  const validationSchema = Yup.object().shape({
    fileName: Yup.string()
      .matches(fileNameRegex, {
        message: "Le nom de fichier contient des caractères non autorisés.",
      })
      .required(),
    // exportFields: Yup.array().min(1),
    server: Yup.string().required(),
  });

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      tableData,
      result.source.index,
      result.destination.index
    );

    setTableData(items);
  }

  const handleVolumeChange = (event, formik) => {
    setVolume(`${event.target.value}`);
    formik.setFieldValue("exportVolume", `${event.target.value}`);
  };

  async function getTemplates() {
    try {
      const result = await apiManager.getRessource(`/api/export_templates`);
      const data = await result["hydra:member"];

      if (data) {
        setTemplates(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const getPartners = async () => {
    setTableLoading(true);
    try {
      const result = await apiManager.getRessource(
        `/api/partners/${queryData.id}`
      );
      setTableData(result);
      setTableDataBase(result);
    } catch (e) {
      console.log(e);
    }
    setTableLoading(false);
  };

  const onDeletePartnerClick = (item) => {
    const newFilteredArray = _.remove(tableData, function (n) {
      return n.ID_PART !== item.ID_PART;
    });

    setTableData(newFilteredArray);
  };

  const hideResetTableButton = _.isEqual(
    _.sortBy(tableDataBase),
    _.sortBy(tableData)
  );
  const handleTemplateChange = (event, newValue) => {
    setTemplateInputValue(newValue);
  };
  return (
    <Formik
      initialValues={{
        query: queryData["@id"],
        fileName: defaultFileName,
        exportFields: [],
        exportsPartners: [],
        server: "",
        exportVolume: "",
        // TODO: remove the field below that line (should be handle on API side)
        exportStatus: 0,
        createdAt: new Date().toUTCString(),
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      render={(formik) => {
        return (
          <Form className={classes.root} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {fileNameHelpText}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  id="fileName"
                  label="Nom du fichier d'export"
                  name="fileName"
                  autoComplete="off"
                  autoFocus
                  error={
                    formik.touched.fileName && Boolean(formik.errors.fileName)
                  }
                  onChange={(e) => handleFileNameChange(formik, e)}
                  value={formik.values.fileName}
                  helperText={
                    formik.errors.fileName &&
                    formik.touched.fileName && (
                      <FormLabel error>{formik.errors.fileName}</FormLabel>
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">.CSV</InputAdornment>
                    ),
                  }}
                />
                <Button
                  size="small"
                  variant="contained"
                  className={classes.resetButton}
                  color="secondary"
                  disabled={defaultFileName === formik.values.fileName}
                  onClick={() =>
                    formik.setFieldValue("fileName", defaultFileName)
                  }
                >
                  Réinitialiser
                </Button>
                {formik.values.fileName !== defaultFileName && (
                  <Typography
                    variant="caption"
                    display="inline"
                  >{`Nom par défault : "${defaultFileName}"`}</Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <ServerSelectList formik={formik} />
              </Grid>
              {/* <Grid item xs={12}>
                <ExportFieldSelectList formik={formik} />
              </Grid> */}
              <Grid item xs={12}>
                <SearchBoxList
                  id="template-search-box"
                  label="Template"
                  options={templates}
                  value={templateValue}
                  inputValue={templateInputValue}
                  onChange={(event, newValue) => {
                    setTemplateValue(newValue);
                  }}
                  onInputChange={handleTemplateChange}
                  getOptionLabel={(option) => `${option.exportTemplateName}`}
                  noOptionsText="Aucune template correspondante"
                  clearable
                />
              </Grid>
              <Grid item xs={12}>
                <TextBox
                  id="volume-box"
                  label="Volume"
                  value={volume}
                  onChange={(event) => handleVolumeChange(event, formik)}
                />
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Position</TableCell>
                        <TableCell>Partenaire</TableCell>
                        <TableCell>Potentiel</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    {tableData ? (
                      <TableBody component={DroppableComponent(onDragEnd)}>
                        {tableData.map((item, index) => {
                          return (
                            <TableRow
                              component={DraggableComponent(
                                `item-${item.ID_PART}`,
                                index
                              )}
                              key={`item-${item.ID_PART}`}
                            >
                              <TableCell scope="row">{index + 1}</TableCell>
                              <TableCell>{item.LB_PART}</TableCell>
                              <TableCell>{item.COUNT}</TableCell>
                              <TableCell>
                                <IconButton
                                  className={classes.deleteIconButton}
                                  aria-label="delete"
                                  size="small"
                                  disableRipple
                                  disableFocusRipple
                                  onClick={() => onDeletePartnerClick(item)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <div className={classes.tableLoaderWrapper}>
                        <div
                          className={classes.tableLoaderInnerWrapper}
                          colspan="4"
                        >
                          <CircularProgress size={24} color="secondary" />
                        </div>
                      </div>
                    )}
                  </Table>
                </TableContainer>
                {!hideResetTableButton && (
                  <Button
                    size="small"
                    variant="contained"
                    className={classes.resetButton}
                    color="secondary"
                    onClick={getPartners}
                  >
                    Réinitialiser
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                <LoaderButton
                  isSubmitting={formik.isSubmitting}
                  className={classes.submit}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                >
                  Lancer l'extraction
                </LoaderButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    />
  );
}

const DraggableComponent = (id, index) => (props) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

const DroppableComponent = (onDragEnd) => (props) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={"1"} direction="vertical">
        {(provided) => {
          return (
            <TableBody
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...props}
            >
              {props.children}
            </TableBody>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default ExtractionForm;
