import React, { useState } from 'react'


// Material-ui / dependencies
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, FormControl, FormHelperText } from '@material-ui/core/'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  thumbnail:{
    width: `calc(${theme.spacing(1)}px + 239px + 6px)`,
    height: `calc(${theme.spacing(1)}px + 64px + 6px)`,
    padding: theme.spacing(0.5),
    border: `3px dashed ${theme.palette.grey[300]}`,
    display: 'inline-block',
    margin: theme.spacing(0, 1, 0, 0),
    '&:hover':{
      background: theme.palette.grey[50],
      border: `3px dashed ${theme.palette.grey[400]}`,
    }
  },
  input: {
    display: 'none',
  },
  button: {
    margin: theme.spacing(0, 1, 0, 0)
  }
}));


export default function UploadButton(props){
  
  const classes = useStyles()
  const { onChange, onDelete, accept } = {...props}
  const [ errors, setErrors ] = useState(false)

  const [ fileUrl, setFileUrl ] = useState(props.fileUrl) 

  // Creating a ref for the input file element
  let inputFileRef = React.createRef();

  // Clicking on the thumbnail trigger the input file click
  const handleThumbanilClick = () => {
    inputFileRef.current.click()
  }

  // Provide a way to delete current file selection
  const handleFileRemove = (e) => {
    inputFileRef.current.value = '' // Reseting the file input (if exists)
    onDelete()
      .then( () => {
        setFileUrl(null) 
      })
  }

  // Handle the logo change event (handle the change event of the input file)
  const handleChange = (e) => {
    setErrors(false)
    if ( !(e.target.files && e.target.files.length) ) { return; }
    
    const file = e.target.files[0]

    onChange(file)
      .then( (response) => {
        setFileUrl(getFileUrl(file) )
      })
      .catch( (response) => {
        handleFileRemove(e)
        setErrors(response)
      })
  
  }

  // Return the file URL from a File Object
  function getFileUrl(file){ return URL.createObjectURL(file) }
  

  return (
    <FormControl error={Boolean(errors)} >
      <Box className={ `${classes.root}` }>
        <Box className={classes.thumbnail} onClick={handleThumbanilClick}>
          { fileUrl ? <img src={fileUrl} alt='' /> : null }
        </Box>
        <input
          accept={accept}
          className={classes.input}
          id="contained-button-file"
          onChange={handleChange}
          type="file"
          ref={inputFileRef}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="secondary" component='span' className={classes.button}>Sélectionner</Button>
        </label>
        {fileUrl && <Button variant="contained" onClick={handleFileRemove} className={classes.button}>Supprimer</Button> }
      </Box>
      { errors && <FormHelperText>{errors}</FormHelperText> }
    </FormControl>
  )

}