import React from "react";

// Material-ui
import { makeStyles } from "@material-ui/core/styles";

// Utils
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";

// Components
import Dashboard from "./scenes/Dashboard/scenes";
import Login from "./scenes/Login";
import ResetPassword from "./scenes/User/scenes/ResetPassword";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
}));

function UnAuthenticatedApp() {
  const classes = useStyles();

  return (
    <Router>
      <div className={classes.root}>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/resetpass" exact component={ResetPassword} />
          <PrivateRoute path="/" exact component={Dashboard} />
          <Redirect from="/" to="/login" />
        </Switch>
      </div>
    </Router>
  );
}

export default UnAuthenticatedApp;
