import React from 'react';
//import PropTypes from 'prop-types'

import { useAuth } from '../../../../context/authContext'
import MainTemplate from '../../../../components/templates/main'

// Material-ui
import { makeStyles } from '@material-ui/core/styles'
import {Grid, Typography, Divider, Paper} from '@material-ui/core'

// Components
// import UserForm from '../../components/userForm'
import UserPasswordForm from '../../components/userPasswordForm'

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    textTransform: 'uppercase',
    fontWeight: 'normal',
  },
  divider: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  paper:{
    padding: theme.spacing(2)
  }
}));


function UserProfile(props){
  const classes=useStyles()

  const { authManager } = useAuth()
  const user = authManager.getUser()

  return (
    <MainTemplate 
      pageCategory="Gestion des utilisateurs"
      pageTitle="Modifier mon profil"
    >
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant='h6' component='h3'>Mes information personnelles</Typography>
            <Divider />
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant='h6' component='h3' gutterBottom className={classes.sectionTitle}>Changer mon mot de passe</Typography>
            <Divider className={classes.divider} />
            <Grid item xs={6}>
              <UserPasswordForm userId={user['id']} context='userProfile' />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </MainTemplate>
  )

}

export default UserProfile;
