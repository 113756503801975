import React, { useState, useEffect, useCallback } from "react";
import useStateWithCallback from "use-state-with-callback";
import { toast } from "react-toastify";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import RightPane from "../../components/Layout/RightPane";
import TextBox from "../../components/forms/TextBox";
import UserForm from "../../scenes/User/scenes/Create";
import ThemeForm from "../../scenes/Theme/scenes/Create";

import ColorLensIcon from "@material-ui/icons/ColorLens";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import MaterialTable from "material-table";

import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "../../context/authContext";

const useStyles = makeStyles((theme) => ({
  panelWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[2],
  },
  countsTitleWrapper: {
    margin: theme.spacing(1, 0),
  },
  listWrapper: {
    flexGrow: 1,
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4, 2),
  },
  actionButton: {
    marginLeft: theme.spacing(2),
  },
}));

const materialTableLocalization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} sur {count}",
    labelRowsSelect: "lignes",
    labelRowsPerPage: "lignes par page:",
  },
  toolbar: {
    nRowsSelected: "{0} saisie(s) séléctionnée(s)",
    exportTitle: "Exporter",
    searchPlaceholder: "Rechercher",
    searchTooltip: "Rechercher",
  },
  header: {
    actions: "Actions",
  },
  body: {
    emptyDataSourceMessage: "Aucune saisies entrées",
    filterRow: {
      filterTooltip: "Filtrer",
    },
  },
};

export default (props) => {
  const classes = useStyles();
  const { apiManager } = useAuth();
  let tableRef = React.useRef();

  const [organizationsListItems, setOrganizationsListItems] =
    useStateWithCallback(null, (data) => {
      if (!data) {
        setListLoading(true);
      } else {
        setListLoading(false);
      }
    });

  const [listLoading, setListLoading] = useState(true);

  const [paneState, setPaneState] = useState({
    paneOpened: false, // Handle the Right panel opening
    action: null, // Depending on its value, the Rigth Pane will adapt its content
    orgIRI: null,
  });

  const getOrganizationsCallback = useCallback(getOrganisations, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getOrganizationsCallback();
    }

    return () => {
      isMounted = false;
    };
  }, [getOrganizationsCallback]);

  // Get orders
  async function getOrganisations() {
    setOrganizationsListItems(null);
    setListLoading(true);
    try {
      const result = await apiManager.getRessource(`/api/organizations`);
      const data = await result["hydra:member"];

      if (data) {
        setOrganizationsListItems(data);
        setListLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * When closing the right pane, the organization lsiting is refreshed to handle new data (like new user)
   * The current implementation refresh the listing everry time the right pane is closed, but this should be improved
   * by triggering the refresh in more specific cases
   */
  const onRightPaneClose = async () => {
    setListLoading(true);
    await getOrganizationsCallback();
    setListLoading(false);
    setPaneState({ paneOpened: false, action: null });
  };

  /**
   * Handling the theme form opening in the right pane
   */
  const openThemeForm = (orgIRI, themeIRI) => {
    setPaneState({
      paneOpened: true,
      action: "manage_theme",
      orgIRI: orgIRI,
      themeIRI: themeIRI,
    });
  };

  /**
   * Handling the user form opening in the right pane
   */
  const openUserForm = (orgIRI) => {
    setPaneState({ paneOpened: true, action: "add_user", orgIRI: orgIRI });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="overline">Gestion des annonceurs</Typography>
            <Typography component="h1" variant="h4" color="initial">
              Liste des organisations
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            className={`${classes.panelWrapper} ${classes.listWrapper}`}
            boxShadow={3}
          >
            <Box className={classes.countsTitleWrapper}>
              <Typography variant="overline">{`${
                organizationsListItems ? organizationsListItems.length : "..."
              } organisation(s)`}</Typography>
            </Box>

            {listLoading ? (
              <Box className={classes.loaderWrapper}>
                <CircularProgress color="secondary" />
              </Box>
            ) : organizationsListItems?.length !== 0 ? (
              <Box>
                <Grid item xs={12}>
                  <MaterialTable
                    tableRef={tableRef}
                    columns={[
                      {
                        title: "Nom de l'organisation",
                        field: "organizationName",
                        defaultSort: "asc",
                        editComponent: (props) => {
                          return (
                            <TextBox
                              value={props.value}
                              onChange={(event) => {
                                props.onChange(event.target.value);
                              }}
                              inputProps={{ maxLength: 100 }}
                            />
                          );
                        },
                      },
                      {
                        align: "right",
                        title: "Nombre d'utilisateurs",
                        field: "nbOfUserAccounts",
                        editable: false,
                      },
                      {
                        cellStyle: {
                          textAlign: "right",
                        },
                        render: (rawData) => {
                          /**
                           * Handling a custom column with custom actions
                           */
                          let content = "";
                          if (rawData) {
                            content = (
                              <React.Fragment>
                                <Tooltip
                                  title="Gérer le thème"
                                  aria-label="Gérer le thème"
                                >
                                  <Fab
                                    size="small"
                                    className={classes.actionButton}
                                    onClick={() =>
                                      openThemeForm(
                                        rawData["@id"],
                                        rawData.theme
                                      )
                                    }
                                  >
                                    <ColorLensIcon />
                                  </Fab>
                                </Tooltip>
                                <Tooltip
                                  title="Ajouter un utilisateur"
                                  aria-label="Ajouter un utilisateur"
                                >
                                  <Fab
                                    color="primary"
                                    size="small"
                                    className={classes.actionButton}
                                    onClick={() => openUserForm(rawData["@id"])}
                                  >
                                    <PersonAddIcon />
                                  </Fab>
                                </Tooltip>
                              </React.Fragment>
                            );
                          }
                          return content;
                        },
                      },
                    ]}
                    data={organizationsListItems}
                    options={{
                      showTitle: false,
                      pageSize: 20,
                      addRowPosition: "first",
                    }}
                    editable={{
                      onRowAdd: async (newData) => {
                        try {
                          await apiManager.createRessource("organizations", {
                            organizationName: newData.organizationName,
                          });
                          getOrganizationsCallback();
                        } catch (e) {
                          console.log(e);
                        }
                      },
                      onRowUpdate: async (newData, oldData) => {
                        try {
                          await apiManager.updateRessource(
                            oldData["@id"],
                            newData
                          );
                          getOrganizationsCallback();
                        } catch (e) {
                          console.log(e);
                        }
                      },
                      onRowDelete: async (data, oldData) => {
                        try {
                          await apiManager.deleteRessource(data["@id"]);
                          getOrganizationsCallback();
                        } catch (e) {
                          console.log(e);
                          toast.error(
                            "Impossible de supprimer l'organisation car elle contient des utilisateurs."
                          );
                        }
                      },
                    }}
                    localization={materialTableLocalization}
                  />
                </Grid>
              </Box>
            ) : (
              <Box className={classes.loaderWrapper}>
                <Typography variant="h6" color="textSecondary" align="center">
                  Aucune commande
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <RightPane isOpened={paneState.paneOpened} onClose={onRightPaneClose}>
        {paneState.action === "add_user" && (
          <UserForm orgIRI={paneState.orgIRI} />
        )}
        {paneState.action === "manage_theme" && (
          <ThemeForm orgIRI={paneState.orgIRI} themeIRI={paneState.themeIRI} />
        )}
      </RightPane>
    </>
  );
};
