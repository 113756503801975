import React, { useState, useEffect, useCallback } from "react";
import useStateWithCallback from "use-state-with-callback";
import _ from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import SearchIcon from "@material-ui/icons/Search";

import TextBox from "../../components/forms/TextBox";
import SearchBoxList from "../../components/forms/SearchBoxList";

import Breadcrumbs from "../../components/general/Breadcrumbs";

import Accordion from "../../components/campaignsList/Accordion";
import CampaignModal from "../../components/campaignsList/CampaignModal";

import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "../../context/authContext";

const useStyles = makeStyles((theme) => ({
  panelWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[2],
  },
  countsTitleWrapper: {
    margin: theme.spacing(1, 0),
  },
  listWrapper: {
    flexGrow: 1,
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4, 2),
  },
}));

const CHANNELS_DATA = [
  {
    id: "sms",
    value: "SMS",
  },
  {
    id: "email",
    value: "E-mail",
  },
  {
    id: "phone",
    value: "Téléphone",
  },
];

export default (props) => {
  const classes = useStyles();

  const { authManager, apiManager } = useAuth();
  const currentUser = authManager.getUser();

  const [campaignsListItems, setCampaignsListItems] = useStateWithCallback(
    null,
    (data) => {
      if (!data) {
        setListLoading(true);
      } else {
        setListLoading(false);
      }
    }
  );

  const [campaignsListItemsFiltered, setCampaignsListItemsFiltered] =
    useStateWithCallback(null, (data) => {
      if (!data) {
        setListLoading(true);
      } else {
        setListLoading(false);
      }
    });

  const [listLoading, setListLoading] = useState(true);

  const [organizations, setOrganizations] = useState(null);
  const [organizationValue, setOrganizationValue] = useState(null);
  const [organizationInputValue, setOrganizationInputValue] = useState("");

  const [users, setUsers] = useState(null);
  const [userValue, setUserValue] = useState(null);
  const [userInputValue, setUserInputValue] = useState("");

  const [queries, setQueries] = useState(null);
  const [queryValue, setQueryValue] = useState(null);
  const [queryInputValue, setQueryInputValue] = useState("");

  const [queriesBis, setQueriesBis] = useState(null);
  const [queryValueBis, setQueryValueBis] = useState(null);
  const [queryInputValueBis, setQueryInputValueBis] = useState("");

  const [channels, setChannels] = useState(CHANNELS_DATA);
  const [channelValue, setChannelValue] = useState(null);
  const [channelInputValue, setChannelInputValue] = useState("");

  const [searchQuery, setSearchQuery] = useState("");

  const [isMe, setIsMe] = useState(null);

  const [extractionOpen, setExtractionOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [shouldOpenOrder, setShouldOpenOrder] = useState(null);
  const [modalReadOnly, setModalReadyOnly] = useState(null);

  let location = useLocation();
  let history = useHistory();

  const getOrganizationsAndUsersCallback = useCallback(
    getOrganizationsAndUsers,
    []
  );

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getOrganizationsAndUsersCallback();

      if (location && location.state) {
        const data = location.state.queryItem;
        setModalOpen(true);
        setModalReadyOnly(false);

        setModalData({
          "@context": null,
          "@id": null,
          "@type": null,
          id: null,
          campaignName: data.queryName,
          query: null,
          createdAt: null,
          updatedAt: null,
          campaignChannel: data.channel,
          campaignStartDate: null,
          campaignBody: null,
          campaignStatus: null,
          campaignVolume: data.queryCount,
          userAccount: null,
          queryIri: data["@id"],
        });
        history.replace("/campaigns");
      }
    }

    return () => {
      isMounted = false;
    };
  }, [getOrganizationsAndUsersCallback]);

  async function getOrganizationsAndUsers() {
    try {
      const result = await apiManager.getRessources("organizations", {
        "order[organizationName]": "ASC",
      });

      if (result) {
        setOrganizations(result["hydra:member"]);

        const myOrganization = result["hydra:member"].find((result) => {
          return result["@id"] === `${currentUser.organization}`;
        });

        setOrganizationValue(myOrganization);

        try {
          const result = await apiManager.getRessource(
            `/api/organizations/${myOrganization.id}/user_accounts`
          );

          if (result) {
            setUsers(result["hydra:member"]);

            const me = result["hydra:member"].find((result) => {
              return result["@id"] === `${currentUser.userAccount}`;
            });

            setUserValue(me);
          }
        } catch (e) {
          console.log(e);
        }

        getUserQueries(currentUser.userAccount);
        getUserCampaign(currentUser.userAccount);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // Get user campaigns
  async function getUserCampaign(id) {
    setCampaignsListItemsFiltered(null);
    setIsMe(currentUser.userAccount === id);

    try {
      const result = await apiManager.getRessource(`${id}/campaigns`);
      const data = await result["hydra:member"];

      if (data) {
        setCampaignsListItems(data);
        setCampaignsListItemsFiltered(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // Get queries
  async function getUserQueries(id) {
    setCampaignsListItemsFiltered(null);
    setIsMe(currentUser.userAccount === id);

    try {
      const result = await apiManager.getRessource(`${id}/queries`);
      const data = await result["hydra:member"];
      setQueries(data);
      setQueriesBis(data);
    } catch (e) {
      console.log(e);
    }
  }

  // Get user campaigns by query
  function getUserCampaignsByQuery(id) {
    if (id) {
      let arrayFiltered = campaignsListItems.filter((item) => {
        return item.query["@id"] === id;
      });
      setCampaignsListItemsFiltered(arrayFiltered);
    } else {
      setCampaignsListItemsFiltered([...campaignsListItems]);
      setChannelInputValue("");
      setChannelValue(null);
    }
  }

  function getUserCampaignsByChannel(value) {
    let queriesFiltered = campaignsListItems.filter((item) => {
      return item.query["@id"] === queryValue["@id"];
    });

    if (value) {
      let arrayFiltered = queriesFiltered.filter((item) => {
        return item.campaignChannel === value;
      });

      setCampaignsListItemsFiltered(arrayFiltered);
    } else {
      setCampaignsListItemsFiltered(queriesFiltered);
    }
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    let query = event.target.value;

    if (query === "" || query === null) {
      setCampaignsListItemsFiltered([...campaignsListItems]);
    } else {
      let arrayFiltered = campaignsListItems.filter((item) => {
        return item.campaignName
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setCampaignsListItemsFiltered(arrayFiltered);
    }
  };

  const handleExtractClick = (event, data) => {
    event.stopPropagation();
    setExtractionOpen(true);
    setModalData(data);
  };

  const handleModifyClick = (event, data, readOnly) => {
    event.stopPropagation();
    setModalOpen(true);
    setModalData(data);
    setModalReadyOnly(readOnly);
  };

  const handleOrderClick = (event, data, readOnly) => {
    event.stopPropagation();
    setModalOpen(true);
    setModalData(data);
    setModalReadyOnly(readOnly);
    setShouldOpenOrder(true);
  };

  const handleDeleteClick = async (event, item) => {
    event.stopPropagation();

    try {
      const newFilteredArray = _.remove(
        [...campaignsListItemsFiltered],
        function (n) {
          return n["@id"] !== item["@id"];
        }
      );
      setCampaignsListItemsFiltered(newFilteredArray);

      const newArray = _.remove([...campaignsListItems], function (n) {
        return n["@id"] !== item["@id"];
      });
      setCampaignsListItems(newArray);

      await apiManager.deleteRessource(item["@id"]);
    } catch (e) {
      toast.error(
        "Suppression impossible, vérifier que la campagne n'a pas de commande(s) associée(s)"
      );
    }
  };

  const handleNewCampaignClick = () => {
    setModalOpen(true);
    setModalReadyOnly(false);

    setModalData({
      "@context": null,
      "@id": null,
      "@type": null,
      id: null,
      query: null,
      createdAt: null,
      updatedAt: null,
      campaignName: queryValueBis.queryName,
      campaignChannel: queryValueBis.channel,
      campaignStartDate: null,
      campaignBody: null,
      campaignStatus: null,
      campaignVolume: queryValueBis.queryCount,
      userAccount: null,
      queryIri: queryValueBis["@id"],
    });
  };

  const updateCampaignsList = (id, newItem) => {
    const index = campaignsListItemsFiltered.findIndex((item) => {
      return item["@id"] === id;
    });

    if (index !== -1) {
      setCampaignsListItemsFiltered([
        ...campaignsListItemsFiltered.slice(0, index),
        newItem,
        ...campaignsListItemsFiltered.slice(index + 1),
      ]);
    }
  };

  const addToCampaignsList = (newItem) => {
    setCampaignsListItemsFiltered(
      _.concat(...campaignsListItemsFiltered, [newItem])
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="overline">Gestion des campagnes</Typography>
            <Typography component="h1" variant="h4" color="initial">
              Liste des campagnes
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.panelWrapper} boxShadow={3}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextBox
                  id="search-box"
                  label="Recherche rapide"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3} />

              <Grid item xs={3}>
                <SearchBoxList
                  id="queries-search-box"
                  label="Mes comptages"
                  options={queriesBis}
                  loading={!queriesBis}
                  value={queryValueBis}
                  inputValue={queryInputValueBis}
                  onChange={(event, newValue) => {
                    setQueryValueBis(newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    setQueryInputValueBis(newValue);
                  }}
                  getOptionLabel={(option) => option.queryName}
                  noOptionsText="Séléctionnez un comptages"
                  clearable
                />
              </Grid>

              <Grid
                item
                container
                xs={3}
                justify="center"
                alignContent="center"
              >
                <Button
                  disabled={!isMe || !queryValueBis}
                  variant="contained"
                  size="large"
                  fullWidth
                  color="secondary"
                  onClick={handleNewCampaignClick}
                >
                  Créer une campagne
                </Button>
              </Grid>

              <Grid item xs={3}>
                <SearchBoxList
                  id="organisations-search-box"
                  loading={!organizations}
                  label="Organisations"
                  options={organizations}
                  value={organizationValue}
                  inputValue={organizationInputValue}
                  onChange={(event, newValue) => {
                    setOrganizationValue(newValue);

                    if (newValue !== null) {
                      setUsers(newValue.userAccounts);
                    }

                    if (newValue !== organizationValue) {
                      setUserValue(null);
                      setQueryValue(null);
                      setQueries([]);
                    }
                  }}
                  onInputChange={(event, newValue) => {
                    setOrganizationInputValue(newValue);
                  }}
                  getOptionLabel={(option) => option.organizationName}
                />
              </Grid>

              <Grid item xs={3}>
                <SearchBoxList
                  id="users-search-box"
                  loading={!users}
                  label="Utilisateurs"
                  options={users}
                  value={userValue}
                  inputValue={userInputValue}
                  onChange={(event, newValue) => {
                    setUserValue(newValue);

                    if (newValue !== null) {
                      getUserCampaign(newValue["@id"]);
                    }

                    if (newValue !== userValue) {
                      setQueryValue(null);
                      setQueries([]);
                    }
                  }}
                  onInputChange={(event, newValue) => {
                    setUserInputValue(newValue);
                  }}
                  getOptionLabel={(option) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  noOptionsText="Séléctionnez une organisation"
                />
              </Grid>

              <Grid item xs={3}>
                <SearchBoxList
                  id="queries-filter-box"
                  label="Comptages"
                  loading={!queries}
                  options={queries}
                  value={queryValue}
                  inputValue={queryInputValue}
                  onChange={(event, newValue) => {
                    setQueryValue(newValue);
                    getUserCampaignsByQuery(newValue && newValue["@id"]);
                  }}
                  onInputChange={(event, newValue) => {
                    setQueryInputValue(newValue);
                  }}
                  getOptionLabel={(option) => option.queryName}
                  noOptionsText="Séléctionnez un comptages"
                  clearable
                />
              </Grid>

              <Grid item xs={3}>
                <SearchBoxList
                  id="channel-search-box"
                  label="Canaux"
                  options={channels}
                  value={channelValue}
                  inputValue={channelInputValue}
                  onChange={(event, newValue) => {
                    setChannelValue(newValue);
                    getUserCampaignsByChannel(newValue && newValue.id);
                  }}
                  onInputChange={(event, newValue) => {
                    setChannelInputValue(newValue);
                  }}
                  getOptionLabel={(option) => option.value}
                  noOptionsText="Séléctionnez un canal"
                  clearable
                  disabled={!queryInputValue}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            className={`${classes.panelWrapper} ${classes.listWrapper}`}
            boxShadow={3}
          >
            <Breadcrumbs
              organisationName={organizationInputValue}
              userName={userInputValue}
              displayChannel
              channelName=""
              queryName={queryInputValue}
            />

            {userValue && (
              <Box className={classes.countsTitleWrapper}>
                <Typography variant="overline">{`${
                  campaignsListItemsFiltered
                    ? campaignsListItemsFiltered.length
                    : "..."
                } campagnes(s)`}</Typography>
              </Box>
            )}

            {!userValue ? (
              <Box className={classes.loaderWrapper}>
                <Typography variant="h6" color="textSecondary" align="center">
                  Oups..
                  <br />
                  Vous devez séléctionner une organisation et un utilisateur
                  pour afficher la liste de campagnes.
                </Typography>
              </Box>
            ) : listLoading ? (
              <Box className={classes.loaderWrapper}>
                <CircularProgress color="secondary" />
              </Box>
            ) : (
              <Box>
                <Grid container spacing={2}>
                  {campaignsListItemsFiltered &&
                    campaignsListItemsFiltered.map((item, index) => {
                      return (
                        <Grid key={index} item xs={3}>
                          <Accordion
                            query={item}
                            isLocked={
                              currentUser.userAccount !== item.userAccount
                            }
                            handleExtractClick={(event) =>
                              handleExtractClick(event, item)
                            }
                            handleModifyClick={(event) =>
                              handleModifyClick(
                                event,
                                item,
                                currentUser.userAccount !== item.userAccount
                              )
                            }
                            handleOrderPress={(event) =>
                              handleOrderClick(
                                event,
                                item,
                                currentUser.userAccount !== item.userAccount
                              )
                            }
                            handleDeleteClick={(event) =>
                              handleDeleteClick(event, item)
                            }
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            )}

            {userValue &&
              campaignsListItemsFiltered &&
              campaignsListItemsFiltered.length === 0 && (
                <Box className={classes.loaderWrapper}>
                  <Typography variant="h6" color="textSecondary" align="center">
                    Cet utilisateur n'a aucune campagne.
                  </Typography>
                </Box>
              )}
          </Box>
        </Grid>
      </Grid>

      <CampaignModal
        open={modalOpen}
        modalData={modalData}
        readOnly={modalReadOnly}
        handleDrawerClose={() => {
          setModalData(null);
          setModalOpen(false);
          setShouldOpenOrder(false);
        }}
        addToCampaignsList={addToCampaignsList}
        updateCampaignsList={updateCampaignsList}
        shouldOpenOrder={shouldOpenOrder}
      />
    </>
  );
};
