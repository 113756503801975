import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { toast } from "react-toastify";
import moment from "moment";

import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import InputBase from "@material-ui/core/InputBase";

import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import WarningIcon from "@material-ui/icons/Warning";

import CloseIcon from "@material-ui/icons/Close";

import TextBox from "../forms/TextBox";
import SearchBoxList from "../forms/SearchBoxList";

import { useAuth } from "../../context/authContext";

import iphoneMockup from "../../assets/img/iphone-mockup.jpg";
import Button from "@material-ui/core/Button";

import "moment/locale/fr";

moment.locale("fr");

const drawerWidth = window.innerWidth;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowY: "hidden",
  },
  content: {
    padding: theme.spacing(2),
    marginTop: 48,
    marginBottom: 68,
    overflowY: "auto",
  },
  nameInput: {
    color: "initial !important",
    ...theme.typography.h4,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(0, 1.75),
  },
  nameInputError: {
    color: "red",
  },
  errorText: {
    color: "red",
    marginLeft: theme.spacing(1),
    fontWeight: 600,
  },
  iphoneWrapper: {
    position: "relative",
    width: 425,
    display: "block",
    margin: "0 auto",
  },
  iphoneMockup: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  iPhoneSender: {
    position: "absolute",
    color: "#FFF",
    top: 180,
    left: 0,
    right: 0,
    textAlign: "center",
    fontFamily: "SFProText",
    fontSize: 12,
  },
  iPhoneDate: {
    position: "absolute",
    top: 220,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iphoneDateTextBold: {
    fontFamily: "SFProText",
    fontWeight: 600,
    fontSize: 11,
    color: "#FFF",
  },
  iphoneDateTextRegular: {
    fontFamily: "SFProText",
    fontSize: 11,
    color: "#FFF",
  },
  iPhoneDateInnerWrapper: {
    display: "flex",
  },
  textMessageWrapper: {
    position: "absolute",
    top: 265,
    left: 43,
    maxWidth: "calc(100% - 86px - 90px)",
    borderRadius: 17.5,
    backgroundColor: "#26252B",
    padding: "8px 13px",
  },
  textMessage: {
    width: "100%",
    fontFamily: "SFProText",
    fontSize: 15,
    color: "#FFF",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  tooltip: {
    fontSize: 14,
  },
  controlBannerWrapperStyle: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
    zIndex: 500,
    backgroundColor: "#FFF",
  },
}));

function getSmsLength(v) {
  if (v) {
    return v.replace(/([{}\[\]\\|\^~€])/g, "\\$1").replace(/\n/g, "--").length;
  } else {
    return 0;
  }
}

const CampaignModal = ({
  open,
  handleDrawerClose,
  modalData,
  readOnly,
  addToCampaignsList,
  updateCampaignsList,
  shouldOpenOrder,
}) => {
  const classes = useStyles();
  const { apiManager } = useAuth();
  const orderContainerRef = React.useRef(null);

  const [context, setContext] = React.useState(null);
  const [iri, setIRI] = React.useState(null);
  const [queryIRI, setQueryIRI] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [id, setID] = React.useState(null);
  const [campaignName, setCampaignName] = React.useState(null);
  const [query, setQuery] = React.useState(null);

  const [customers, setCustomers] = useState(null);

  const [createdAt, setCreatedAt] = React.useState(null);
  const [updatedAt, setUpdatedAt] = React.useState(null);
  const [campaignChannel, setCampaignChannel] = React.useState(null);
  const [campaignStartDate, setCampaignStartDate] = React.useState(new Date());
  const [campaignStatus, setCampaignStatus] = React.useState(null);
  const [userAccount, setUserAccount] = React.useState(null);

  const [volume, setVolume] = useState("");
  const [sender, setSender] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [unsubscribe, setUnsubscribe] = useState("STOP AU 36105");

  const [customerValue, setCustomerValue] = useState(null);
  const [customerInputValue, setCustomerInputValue] = useState("");

  const [interlocutor, setInterlocutor] = useState("");
  const [comment, setComment] = useState("");

  const [packs, setPacks] = useState(null);
  const [packValue, setPackValue] = useState(null);
  const [packInputValue, setPackInputValue] = useState("");

  const [error, setError] = useState("");

  const [orderModalOpen, setOrderModalOpen] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && open) {
      getCustomers();

      if (modalData) {
        fillModalData();
      } else {
        emptyModalData();
      }
    }

    return () => {
      isMounted = false;
    };
  }, [open, shouldOpenOrder, modalData]);

  const smsCampaignHasError = () => {
    if (campaignName === "" || campaignName === null) {
      setError("Le nom est obligatoire pour continuer.");
      return true;
    } else if (!campaignStartDate) {
      setError("Vous devez choisir une date et heure d'envoi.");
      return true;
    } else if (
      moment(campaignStartDate).hour() > 19 ||
      moment(campaignStartDate).hour() < 9
    ) {
      setError("Vous ne pouvez pas envoyer un SMS de 19h à 9h.");
      return true;
    } else if (
      (moment(campaignStartDate).hour() === 19 &&
        moment(campaignStartDate).minute() > 0) ||
      moment(campaignStartDate).hour() < 9
    ) {
      setError("Vous ne pouvez pas envoyer un SMS de 19h à 9h.");
      return true;
    } else if (volume === "" || volume === null) {
      setError("Le volume est obligatoire pour continuer.");
      return true;
    } else if (getSmsLength(sender) > 11) {
      setError("L'émetteur ne peut pas contenir plus de 11 caractères.");
      return true;
    } else if (message === "" || message === null) {
      setError("Le message est obligatoire pour continuer.");
      return true;
    } else if (
      url !== ""
        ? 27 + getSmsLength(message + unsubscribe) > 160
        : getSmsLength(message + unsubscribe) > 160
    ) {
      setError("Le message ne peut pas contenir plus de 160 caractères.");
      return true;
    } else if (moment(campaignStartDate).day() === 0) {
      setError("La date choisie ne peut pas être un dimanche.");
      return true;
    } else {
      setError("");
      return false;
    }
  };

  const fillModalData = () => {
    setContext(modalData["@context"]);
    setIRI(modalData["@id"]);
    setType(modalData["@type"]);
    setID(modalData.id);
    setQuery(modalData.query);
    setCreatedAt(modalData.createdAt);
    setUpdatedAt(modalData.updatedAt);
    setCampaignChannel(modalData.campaignChannel);
    setCampaignStartDate(modalData.campaignStartDate);
    setCampaignName(modalData.campaignName);
    setCampaignStatus(modalData.campaignStatus);
    setUserAccount(modalData.userAccount);
    setVolume(modalData.campaignVolume || "");
    setInterlocutor(
      modalData?.shootingRecipients && modalData.shootingRecipients.join(", ")
    );
    setComment(modalData?.comment);
    modalData.campaignBody &&
      modalData.campaignBody.map((item) => {
        setSender(item.msgSender || "");
        setMessage(item.msgBody || "");
        setUnsubscribe(item.unsubscribe || "STOP AU 36105");
        setUrl(item.msgURL || "");
      });
    modalData.queryIri && setQueryIRI(modalData.queryIri);
  };

  const emptyModalData = () => {
    setContext(null);
    setIRI(null);
    setType(null);
    setID(null);
    setQuery(null);
    setCreatedAt(null);
    setUpdatedAt(null);
    setCampaignChannel(null);
    setCampaignStartDate(new Date());
    setCampaignStatus(null);
    setUserAccount(null);
    setVolume(null);
    setSender(null);
    setMessage(null);
    setUnsubscribe(null);
    setUrl(null);
    setPacks(null);
    setPackValue(null);
    setPackInputValue(null);
    setInterlocutor(null);
    setComment(null);
    setCustomerValue(null);
    setCustomerInputValue(null);
    setCampaignName(null);
  };

  const handleCreate = async () => {
    if (!smsCampaignHasError()) {
      try {
        toast.info("Création en cours..");

        const result = await apiManager.createRessource("campaigns", {
          campaignName: campaignName,
          campaignChannel: campaignChannel,
          campaignBody: [
            {
              msgSender: sender,
              msgBody: message,
              msgURL: url,
              msgSTOP: unsubscribe,
              testPhone: "0612345678",
            },
          ],
          campaignVolume: volume,
          query: queryIRI,
          shootingRecipients: [interlocutor],
          comment: comment,
        });

        setContext(result["@context"]);
        setIRI(result["@id"]);
        setID(result.id);
        setCampaignName(result.campaignName);
        setQuery(result.query);
        setCreatedAt(result.createdAt);
        setUpdatedAt(result.updatedAt);
        setCampaignChannel(result.campaignChannel);
        setCampaignStartDate(result.campaignStartDate);
        setSender(result.campaignBody[0].msgSender);
        setMessage(result.campaignBody[0].msgBody);
        setUrl(result.campaignBody[0].msgURL);
        setUnsubscribe(result.campaignBody[0].msgSTOP);
        setCampaignStatus(result.campaignStatus);
        setVolume(result.campaignVolume);
        setUserAccount(result.userAccount);

        addToCampaignsList(result);
        toast.success("Campagne créée !");
      } catch (e) {
        console.log(e);
        toast.error("Erreur lors de la création");
      }
    }
  };

  const handleUpdate = async () => {
    if (!smsCampaignHasError()) {
      const CAMPAIGN_ITEM = {
        "@context": context,
        "@id": iri,
        "@type": type,
        id: id,
        campaignName: campaignName,
        query: query["@id"],
        createdAt: createdAt,
        updatedAt: updatedAt,
        campaignChannel: campaignChannel,
        campaignStartDate: moment.utc(campaignStartDate),
        campaignBody: [
          {
            msgSender: sender,
            msgBody: message,
            msgURL: url,
            msgSTOP: unsubscribe,
            testPhone: "0612345678",
          },
        ],
        campaignStatus: campaignStatus,
        campaignVolume: volume,
        userAccount: userAccount,
        shootingRecipients: [interlocutor],
        comment: comment,
      };

      try {
        toast.info("Sauvegarde en cours..");
        const modifiedCampaign = await apiManager.updateRessource(
          iri,
          CAMPAIGN_ITEM
        );
        updateCampaignsList(iri, modifiedCampaign);
        toast.success("Sauvegardé !");
      } catch (e) {
        console.log(e);
        toast.error("Erreur lors de la sauvegarde");
      }
    }
  };

  const handleCampaignNameChange = (event) => {
    setCampaignName(event.target.value);
  };

  const handleSenderChange = (event) => {
    setSender(event.target.value);
  };

  const handleVolumeChange = (event) => {
    setVolume(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleUnsubscribeChange = (event) => {
    setUnsubscribe(event.target.value);
  };

  const getCustomers = async () => {
    try {
      const result = await apiManager.getRessource(`/api/customers`);
      const data = await result["hydra:member"];

      if (data) {
        setCustomers(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    handleDrawerClose();
    emptyModalData();
  };

  const getCustomerPacks = async (id) => {
    try {
      const result = await apiManager.getRessource(`/api/customer_packs`);

      const customerPacks = result["hydra:member"].filter((customerPack) =>
        customerPack.customer["@id"].includes(id)
      );

      if (result) {
        setPacks(customerPacks);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleinterlocutorChange = (event) => {
    setInterlocutor(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const formHasError = () => {
    if (customerInputValue === "") {
      setError("Le choix de l'annonceur est obligatoire pour continuer.");
      return true;
    } else if (moment(campaignStartDate).day() === 0) {
      setError("La date choisie ne peut pas être un dimanche.");
      return true;
    } else if (moment(campaignStartDate).isBefore(moment(new Date()))) {
      setError("La date choisie est dépassée.");
      return true;
    } else if (
      moment(campaignStartDate).diff(moment(new Date()), "hours") <= 48
    ) {
      setError("La date choisie est dans moins de 48h.");
      return true;
    } else {
      setError("");
      return false;
    }
  };

  const handleCreateOrder = async () => {
    if (smsCampaignHasError()) {
      orderContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (!formHasError() && !smsCampaignHasError()) {
      try {
        await handleUpdate();
        toast.info("Création de la commande en cours..");

        if (packValue) {
          const result = await apiManager.getRessource(`/api/customer_packs`);

          const data = result["hydra:member"];

          const selectedPack = data.find(
            (item) => item["@id"] === packValue["@id"]
          );

          if (parseInt(selectedPack.volumeLeft) >= parseInt(volume)) {
            await apiManager.createRessource("campaignOrders", {
              campaign: iri,
              orderVolume: volume,
              customer: customerValue["@id"],
              customerPack: packValue && packValue["@id"],
            });
            await apiManager.updateRessource(iri, {
              campaignStatus: 1,
            });
            await apiManager.updateRessource(selectedPack["@id"], {
              volumeLeft: `${selectedPack.volumeLeft - volume}`,
            });
            toast.success("Commande créée !");
          } else {
            toast.error(
              "Le volume choisi est supérieur au volume du pack sélectionné."
            );
          }
        } else {
          await apiManager.createRessource("campaignOrders", {
            campaign: iri,
            orderVolume: volume,
            customer: customerValue["@id"],
            customerPack: packValue && packValue["@id"],
          });
          await apiManager.updateRessource(iri, {
            campaignStatus: 1,
          });
          toast.success("Commande créée !");
        }
      } catch (e) {
        console.log(e);
        toast.error("Erreur lors de la création");
      }
    }
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleClose}
      >
        <AppBar position="absolute" color="secondary">
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="overline">
                Création d'une nouvelle campagne
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={
                  "Entrez le nom de la campagne ici sous le format\nNomAnnonceur_DD/MM/AA"
                }
                arrow
                placement="top"
              >
                <InputBase
                  id="standard-basic"
                  classes={{
                    root: classes.nameInput,
                    error: classes.nameInputError,
                  }}
                  fullWidth
                  value={campaignName}
                  onChange={handleCampaignNameChange}
                  placeholder="Entrer le nom de la campagne"
                  inputProps={{ "aria-label": "nom-campagne" }}
                  disabled
                  maxLength={255}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6} />

            <Grid item xs={6}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={MomentUtils}
                locale="fr"
              >
                <DateTimePicker
                  ampm={false}
                  value={campaignStartDate}
                  onChange={setCampaignStartDate}
                  label="Date et heure d'envoi"
                  inputVariant="outlined"
                  margin="dense"
                  format="dddd DD MMMM YYYY [à] HH[h]mm"
                  fullWidth
                  minutesStep={15}
                  disabled={readOnly}
                />
              </MuiPickersUtilsProvider>
              <TextBox
                id="volume-box"
                label="Volume envoyé"
                value={volume}
                onChange={handleVolumeChange}
                disabled={readOnly}
              />
              <TextBox
                id="sender-box"
                label="Emetteur"
                value={sender}
                onChange={handleSenderChange}
                disabled={readOnly}
                inputProps={{ maxLength: 11 }}
              />
              <Typography variant="body1" color="initial" align="right">
                {`${getSmsLength(sender)}/11`}
              </Typography>
              <TextBox
                id="message-box"
                label="Message"
                value={message}
                onChange={handleMessageChange}
                multiline
                rows={5}
                rowsMax={20}
                disabled={readOnly}
              />
              <Typography variant="body1" color="initial" align="right">
                {getSmsLength(message)}
                {url !== "" ? `/${145 - 27}` : "/145"}
              </Typography>
              <TextBox
                id="url-box"
                label="URL"
                value={url}
                placeholder="http://b.hcnx.eu/3XxXX"
                onChange={handleUrlChange}
                disabled={readOnly}
              />
              <TextBox
                id="unsubscribe-box"
                label="Désinscription"
                value={unsubscribe}
                onChange={handleUnsubscribeChange}
                disabled
              />
              <Typography variant="body1" color="initial" align="right">
                {`15/15`}
              </Typography>

              <TextBox
                id="interlocutor-box"
                label="Interlocuteurs BAT"
                value={interlocutor}
                onChange={handleinterlocutorChange}
              />

              <TextBox
                id="comments-box"
                label="Commentaire"
                value={comment}
                onChange={handleCommentChange}
                multiline
                rows={5}
                rowsMax={20}
              />

              <Typography variant="h6" color="initial">
                Nombre total de caractères :
              </Typography>
              <Typography variant="h6" color="initial">
                {`${
                  url !== ""
                    ? 27 + getSmsLength(message) + 15
                    : getSmsLength(message) + 15
                }/160`}
              </Typography>
              <br />
              <WarningIcon />
              <Typography variant="body1" color="initial">
                Attention il faut respecter un délai de 48h pour la mise en
                place de la campagne à partir de la demande.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.iphoneWrapper}>
                <img
                  src={iphoneMockup}
                  className={classes.iphoneMockup}
                  alt="iPhone with SMS preview"
                />
                <span className={classes.iPhoneSender}>
                  {sender || "Emetteur"}
                </span>
                <div className={classes.iPhoneDate}>
                  <span className={classes.iphoneDateTextBold}>SMS</span>
                  <div className={classes.iPhoneDateInnerWrapper}>
                    <span className={classes.iphoneDateTextBold}>
                      Aujourd'hui à&nbsp;
                    </span>
                    <span className={classes.iphoneDateTextRegular}>20h21</span>
                  </div>
                </div>
                {(message || url) && (
                  <div className={classes.textMessageWrapper}>
                    <pre className={classes.textMessage}>
                      {`${message && message} ${url && url} ${
                        unsubscribe && unsubscribe
                      }`}
                    </pre>
                  </div>
                )}
              </div>
            </Grid>

            <Box className={classes.controlBannerWrapperStyle} boxShadow={6}>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.errorText}>
                    {error}
                  </Typography>
                </Grid>
                <Grid item xs={6} container justify="flex-end" spacing={2}>
                  <Grid item>
                    {iri ? (
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleUpdate}
                      >
                        Sauvegarder la campagne
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleCreate}
                      >
                        Créer une campagne
                      </Button>
                    )}
                  </Grid>
                  {iri && (
                    <Grid item>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleCreateOrder}
                      >
                        Commander
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Box className={classes.content}>
            <Grid ref={orderContainerRef} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="overline">Commande</Typography>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.volumeBox}>
                  <Typography variant="h6" component="span">
                    Volume :
                  </Typography>
                  <Typography
                    variant="h6"
                    component="span"
                    className={classes.volumeText}
                  >
                    {" " + volume}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <SearchBoxList
                  id="customers-search-box"
                  loading={!customers}
                  label="Annonceurs"
                  options={customers}
                  value={customerValue}
                  inputValue={customerInputValue}
                  onChange={(event, newValue) => {
                    setCustomerValue(newValue);
                    getCustomerPacks(newValue.id);
                    setPackValue(null);
                    setPackInputValue("");
                  }}
                  onInputChange={(event, newValue) => {
                    setCustomerInputValue(newValue);
                  }}
                  getOptionLabel={(option) => option.CompanyName}
                />

                <SearchBoxList
                  id="packs-search-box"
                  disabled={!customerInputValue}
                  label="Packs"
                  options={packs}
                  value={packValue}
                  inputValue={packInputValue}
                  onChange={(event, newValue) => {
                    setPackValue(newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    setPackInputValue(newValue);
                  }}
                  getOptionLabel={(option) => option.packName}
                />

                {packValue && (
                  <Typography
                    variant="body1"
                    color="initial"
                  >{`Volume restant : ${packValue.volumeLeft}`}</Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default CampaignModal;
