import React from 'react'

import { Link } from 'react-router-dom'

// Material-ui 
import { makeStyles } from '@material-ui/core/styles'
import {MenuItem, Menu} from '@material-ui/core/'
import IconButton from '@material-ui/core/IconButton'
import {AccountCircle} from '@material-ui/icons/'


const useStyles = makeStyles(theme => ({
  root:{
    
  }
}));


export default function ProfileMenu() {
  const classes = useStyles();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="Gestion du profil utilisateur"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <Link to="/profile">Gérer mon profil</Link>
        </MenuItem>
      </Menu>
    </div>
  )
}