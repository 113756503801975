import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";

// Export CSV
export const exportDataAsCsv = (columns, data, options = {}) => {
  const { fileName = "exported_data.csv", delimiter = "," } = options;
  const csvRows = [];

  try {
    // Add header row
    const headerRow = columns.map((column) => `"${column.title}"`);
    csvRows.push(headerRow.join(delimiter));

    // Add data rows
    data.forEach((row) => {
      const rowData = columns.map((column) => {
        const value = row[column.field];
        return value !== null && value !== undefined ? `"${value}"` : "";
      });
      csvRows.push(rowData.join(delimiter));
    });

    // Create CSV content
    const csvContent = csvRows.join("\n");

    // Create a Blob object with CSV data
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting data as CSV:", error);
  }
};

// Export PDF
export const exportDataAsPdf = (columns, data, options = {}) => {
  const { fileName = "exported_data.pdf", title = "Exported Data" } = options;

  try {
    // Create PDF document
    const doc = new jsPDF("landscape", "pt", "A4");

    doc.setFontSize(15);
    doc.text(title, 40, 40);

    // Define table headers
    const headers = columns.map((column) => column.title);

    // Define table rows
    const rows = data.map((row) =>
      columns.map((column) => row[column.field] || "")
    );

    // Add table headers and rows
    doc.autoTable({
      head: [headers],
      startY: 50,
      body: rows,
    });

    // Save PDF
    doc.save(fileName);
  } catch (error) {
    console.error("Error exporting data as PDF:", error);
  }
};

// Export Excel (Xlsx)
export const exportDataAsExcel = (columns, data, options = {}) => {
  const { fileName = "exported_data", sheetName = "Sheet1" } = options;

  try {
    const workbook = XLSX.utils.book_new();
    const filteredData = data.map(item =>
      columns.map(column => item[column.field] || "")
    );

    // Convert data to worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([columns.map(column => column.title)].concat(filteredData));

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // Save Excel file
    XLSX.writeFile(workbook, fileName + ".xlsx");
  } catch (error) {
    console.error("Error exporting data as Excel:", error);
  }
};