import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import _ from "lodash";
import { toast } from "react-toastify";
import mapboxgl from "mapbox-gl";
import moment from "moment/moment.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from "@material-ui/core/Drawer";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/core/Slider";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import MaterialTable from "material-table";

import SearchBoxList from "../forms/SearchBoxList";
import TextBox from "../forms/TextBox";
import ChannelItem from "./countModal/ChannelItem.js";
import Map from "./Map";

import TextsmsIcon from "@material-ui/icons/Textsms";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DoneIcon from "@material-ui/icons/Done";

import { useAuth } from "../../context/authContext";
import { Checkbox } from "@material-ui/core";

import { exportDataAsPdf, exportDataAsExcel } from "../../utils/exportHelpers.js";

const drawerWidth = window.innerWidth;

mapboxgl.accessToken =
  "pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowY: "hidden",
  },
  content: {
    padding: theme.spacing(2),
    marginTop: 48,
    marginBottom: 68,
    overflowY: "auto",
  },
  stepButtonsWrapper: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
    zIndex: 500,
    backgroundColor: "#FFF",
  },
  backButton: {
    padding: theme.spacing(0.75, 2),
    marginRight: theme.spacing(1),
  },
  nameAndFolderWrapper: {
    // display: "flex",
  },
  nameInput: {
    color: "initial !important",
    ...theme.typography.h4,
  },
  nameInputError: {
    color: "red",
  },
  errorText: {
    color: "red",
    marginLeft: theme.spacing(1),
    fontWeight: 600,
  },
  stepperRoot: {
    backgroundColor: theme.palette.grey[50],
  },
  stepRoot: {
    "&:hover": {
      cursor: "pointer !important",
    },
  },
  stepLabel: {
    cursor: "inherit !important",
  },
  stepIconActive: {
    color: `${theme.palette.secondary.main} !important`,
  },
  chanelsWrapper: {
    display: "flex",
    marginTop: theme.spacing(2),

    "& > div": {
      marginRight: theme.spacing(2),

      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  ageToggleWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),

    "& > p": {
      marginRight: theme.spacing(1),
    },
  },
  ageSliderWrapper: {
    marginTop: theme.spacing(6),
    width: "25%",
  },
  ageSliderCaptionWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  genderToggleWrapper: {
    marginTop: theme.spacing(2),

    "& > div": {
      display: "flex",

      " & > div": {
        "&:first-child": {
          marginRight: theme.spacing(4),
        },
      },
    },
  },
  toggleWrapper: {
    display: "flex",
    alignItems: "center",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  inputsWrapper: {
    marginTop: theme.spacing(4),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  sliderValueLabel: {
    zIndex: `10 !important`,
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(6, 4),
  },
  mapContainer: {
    width: "100%",
    height: 500,
  },
  tooltip: {
    fontSize: 14,
  },
}));

function getSteps() {
  return [
    "Canal d'acquisition",
    "Critères géographiques",
    "Critères socio-démographiques",
    "Résultat",
  ];
}

function debounceQuery(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

const CHANNELS_DATA = [
  {
    value: "sms",
    icon: <TextsmsIcon />,
    title: "SMS",
  },
  {
    value: "email",
    icon: <AlternateEmailIcon />,
    title: "EMAIL",
  },
  {
    value: "postal",
    icon: <EmailIcon />,
    title: "VOIE POSTALE",
  },
  {
    value: "phone",
    icon: <PhoneIcon />,
    title: "TÉLÉPHONE",
  },
];

const CSP_DATA = [
  { value: "1", name: "CSP -" },
  { value: "2", name: "CSP Moyen" },
  { value: "3", name: "CSP +" },
  { value: "4", name: "CSP ++" },
];

const HOUSE_TYPE_DATA = [
  { value: "1", name: "Maison" },
  { value: "2", name: "Appartement" },
];

const OCCUPANCY_TYPE_DATA = [
  { value: "1", name: "Locataire" },
  { value: "2", name: "Propriétaire" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const materialTableLocalization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} sur {count}",
    labelRowsSelect: "lignes",
    labelRowsPerPage: "lignes par page:",
  },
  toolbar: {
    nRowsSelected: "{0} saisie(s) séléctionnée(s)",
    exportTitle: "Exporter",
    searchPlaceholder: "Rechercher",
    searchTooltip: "Rechercher",
  },
  header: {
    actions: "Actions",
  },
  body: {
    emptyDataSourceMessage: "Aucune saisies entrées",
    filterRow: {
      filterTooltip: "Filtrer",
    },
  },
};

const CountModal = ({
  open,
  folders,
  currentFolder,
  handleDrawerClose,
  modalData,
  readOnly,
  addToCountsList,
  updateCountsList,
}) => {
  const potentialDataTableRef = useRef(null);
  const classes = useStyles();
  const { apiManager } = useAuth();

  const [dataLoading, setDataLoading] = React.useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [error, setError] = React.useState("");

  const [iri, setIRI] = React.useState(null);

  const [type, setType] = React.useState(null);

  const [channelSelected, setChannelSelected] = React.useState("");

  const [createdAt, setCreatedAt] = React.useState(null);

  const [id, setID] = React.useState(null);

  const [queryCount, setQueryCount] = React.useState(null);

  const [queryExports, setQueryExports] = React.useState(null);

  const [folderValue, setFolderValue] = React.useState(null);
  const [folderInputValue, setFolderInputValue] = React.useState("");

  const [countName, setCountName] = React.useState("");

  const [geoCritera, setGeoCritera] = React.useState("none");

  const [regionsCodes, setRegionsCodes] = React.useState([]);
  const [regionCodeValue, setRegionCodeValue] = React.useState(null);
  const [regionCodeInputValue, setRegionCodeInputValue] = React.useState("");

  const [departmentsCodes, setDepartmentsCodes] = React.useState([]);
  const [departmentCodeValue, setDepartmentCodeValue] = React.useState(null);
  const [departmentCodeInputValue, setDepartmentCodeInputValue] =
    React.useState("");
  const [regionsDepartmentsTableData, setRegionsDepartmentsTableData] =
    React.useState([]);

  const [postalsCodes, setPostalsCodes] = React.useState([]);
  const [postalCodeValue, setPostalCodeValue] = React.useState(null);
  const [postalCodeInputValue, setPostalCodeInputValue] = React.useState("");
  const [postalsCommunesTableData, setPostalsCommunesTableData] =
    React.useState([]);

  const [citiesCodes, setCitiesCodes] = React.useState([]);
  const [cityCodeValue, setCityCodeValue] = React.useState(null);
  const [cityCodeInputValue, setCityCodeInputValue] = React.useState("");

  const [irisQuery, setIrisQuery] = React.useState("");
  const [irisTableData, setIrisTableData] = React.useState([]);

  const [matchingAddresses, setMatchingAddresses] = React.useState([]);
  const [addressValue, setAddressValue] = React.useState(null);
  const [addressInputValue, setAddressInputValue] = React.useState("");
  const [addressTableData, setAddressTableData] = React.useState([]);

  const [useAgeSelector, setUseAgeSelector] = React.useState(false);
  const [ageValues, setAgeValues] = React.useState([20, 37]);

  const [male, setMale] = React.useState(false);
  const [female, setFemale] = React.useState(false);

  const [CSP, setCSP] = React.useState([]);

  const [houseType, setHouseType] = React.useState([]);

  const [occupancyType, setOccupancyType] = React.useState([]);

  const [FSquareNiv1Options, setFSquareNiv1Options] = React.useState([]);
  const [FSquareNiv1Value, setFSquareNiv1Value] = React.useState(null);
  const [FSquareNiv1InputValue, setFSquareNiv1InputValue] = React.useState("");

  const [FSquareNiv2Options, setFSquareNiv2Options] = React.useState([]);
  const [FSquareNiv2OptionsBis, setFSquareNiv2OptionsBis] = React.useState([]);
  const [FSquareNiv2Value, setFSquareNiv2Value] = React.useState([]);

  const [queryResult, setQueryResult] = React.useState(null);

  const [queryResultBy, setQueryResultBy] = React.useState(null);

  const [updatedAt, setUpdatedAt] = React.useState(null);

  const [userAccount, setUserAccount] = React.useState(null);

  const [potentialData, setPotentialData] = React.useState(null);
  const [potentialDataTable, setPotentialDataTable] = React.useState(null);
  const [potentialLoading, setPotentialLoading] = React.useState(true);

  const [coordinates, setMapCoordinates] = React.useState([]);
  const [zdcCoordinates, setZDCCoordinates] = React.useState([]);
  const [mapCenter, setMapCenter] = React.useState([]);

  const steps = getSteps();

  const [showZDCColumn, setShowZDCColumn] = React.useState(null);

  const fillModalData = React.useCallback(async () => {
    try {
      const result = await apiManager.getRessource(modalData.queryFolder);
      const folder = folders.find((item) => {
        return item["@id"] === modalData.queryFolder;
      });

      setIRI(modalData["@id"]);
      setType(modalData["@type"]);
      setChannelSelected(modalData.channel);
      setCreatedAt(modalData.createdAt);
      setID(modalData.id);
      setQueryCount(modalData.queryCount);
      setQueryExports(modalData.queryExports);
      if (folder) {
        setFolderValue(folder);
        setFolderInputValue(folder.folderName);
      } else {
        setFolderValue(result);
        setFolderInputValue(result.folderName);
      }
      setCountName(modalData.queryName);
      setQueryResult(modalData.queryResult);
      setQueryResultBy(modalData.queryResultBy);
      setUpdatedAt(modalData.updatedAt);
      setUserAccount(modalData.userAccount);
      // Params
      if (modalData.queryParams.length !== 0) {
        setGeoCritera(modalData.queryParams.geoChoice || "none");
        setRegionsDepartmentsTableData(modalData.queryParams.deptCodes || []);
        setPostalsCommunesTableData(modalData.queryParams.cityCodes || []);
        setIrisTableData(modalData.queryParams.irisCodes || []);
        setAddressTableData(modalData.queryParams.address || []);
        setUseAgeSelector(modalData.queryParams.age.checkAge || false);
        setAgeValues(
          modalData.queryParams.age
            ? [modalData.queryParams.age.min, modalData.queryParams.age.max]
            : [20, 37]
        );
        setMale(modalData.queryParams.gender.M || false);
        setFemale(modalData.queryParams.gender.F || false);
        setCSP(modalData.queryParams.csp || []);
        setHouseType(modalData.queryParams.homeType || []);
        setOccupancyType(modalData.queryParams.homeStatus || []);
      }
    } catch (e) {
      console.log(e);
    }
    console.log({ modalData });
  }, [apiManager, folders, modalData]);

  const getPotentialResult = React.useCallback(
    async (modalData) => {
      setMapCoordinates([]);
      setMapCenter([]);
      setZDCCoordinates([]);
      setPotentialLoading(true);
      console.log("yeeep");

      try {
        const results = await apiManager.getRessource(modalData.queryResult);
        console.log("yeeep", results);
        setPotentialData(results);
        setQueryCount(results.queryCount);

        if (modalData.queryResultBy[0] === "cityCodes") {
          const potentialDataTableArray = [];
          
          let ZCDValue = results.ventilatedResult[0].codePostal;

          results.ventilatedResult.forEach((item, parentIndex) => {
            potentialDataTableArray.push({
              id: parentIndex,
              codeCommune: item.codeCommune,
              codePostal: item.codePostal,
              nomCommune: item.nomCommune,
              zoneChalandise: item.zoneChalandise,
              queryCount: item.queryCount,
              totalQueryCount: item.totalQueryCount,
              ZDCCol: ZCDValue,
            });

            if (item.ZDCCities.length !== 0) {
              setShowZDCColumn(true);
              item.ZDCCities.forEach((item, index) => {
                potentialDataTableArray.push({
                  id: index,
                  codeCommune: item.cityCode,
                  codePostal: item.zipCode,
                  nomCommune: item.cityName,
                  zoneChalandise: null,
                  queryCount: item.queryCount,
                  totalQueryCount: null,
                  parentId: parentIndex,
                  ZDCCol: ZCDValue,
                });
              });
            }
          });

          setPotentialDataTable(potentialDataTableArray);

          const codeCommune = results.ventilatedResult.map(
            (item) => item.codeCommune
          );

          const codeZDC = _.flattenDeep(
            results.ventilatedResult.map((item) =>
              item.ZDCCities.map((item) => item.cityCode)
            )
          );

          codeCommune.forEach(async (item) => {
            const mapData = await apiManager.getRessource(
              `/api/geo/cities?code=${item}`
            );

            if (mapData[0].contour) {
              const coordinates = mapData[0].contour.coordinates;
              const center = mapData[0].centre.coordinates;

              if (coordinates.length === 1) {
                setMapCoordinates((prevState) =>
                  _.concat(prevState, [coordinates])
                );
              } else {
                const remadeArray = [_.flatten(_.flatten(coordinates))];

                setMapCoordinates((prevState) =>
                  _.concat(prevState, [remadeArray])
                );
              }
              setMapCenter((prevState) => _.concat(prevState, [center]));
            }
          });

          codeZDC.forEach(async (item) => {
            const mapData = await apiManager.getRessource(
              `/api/geo/cities?code=${item}`
            );

            const coordinates = mapData[0].contour.coordinates;

            setZDCCoordinates((prevState) =>
              _.concat(prevState, [coordinates])
            );
          });
        } else {
          setPotentialDataTable(results.ventilatedResult);
        }
        setPotentialLoading(false);
      } catch (e) {
        console.log(e);
      }
    },
    [apiManager]
  );

  const getFSquareOptions = React.useCallback(async () => {
    try {
      const niv1 = await apiManager.getRessource("/api/family/niv1/");
      const niv2 = await apiManager.getRessource("/api/family/niv2");
      setFSquareNiv1Options(niv1);
      setFSquareNiv2Options(niv2);
      setFSquareNiv2OptionsBis(niv2);

      if (modalData) {
        if (modalData.queryParams) {
          if (modalData.queryParams.fam1) {
            let niv1Value = niv1.find(
              (item) => item.CD_FAM_NIV1 === modalData.queryParams.fam1
            );
            setFSquareNiv1Value(niv1Value);
          }

          if (modalData.queryParams.fam2) {
            setFSquareNiv2Value(modalData.queryParams.fam2);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [apiManager, modalData]);

  const getPostalsCodes = React.useCallback(async () => {
    try {
      // const result = await axios.get(`https://geo.api.gouv.fr/communes`);
      const result = await apiManager.getRessources("cities");
      setPostalsCodes(result);
      setCitiesCodes(result);
    } catch (e) {
      console.log(e);
    }
  }, [apiManager]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && open) {
      setDataLoading(true);

      try {
        getFSquareOptions();
        getRegionsCodes();
        getDepartmentsCodes();
        getPostalsCodes();

        if (modalData) {
          fillModalData();

          if (modalData.queryParams.length === 0) {
            setActiveStep(1);
          } else {
            setActiveStep(3);

            if (modalData.queryResult) {
              getPotentialResult(modalData);
            } else {
              if (!readOnly) {
                runPotentialCount(modalData.id, true);
              } else {
                setMapCenter([]);
                setMapCoordinates([]);
                setPotentialData([]);
                setZDCCoordinates([]);
              }
            }
          }
        } else {
          emptyModalData();
          setActiveStep(0);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setDataLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fillModalData,
    getFSquareOptions,
    getPostalsCodes,
    getPotentialResult,
    modalData,
    open,
    readOnly,
  ]);

  const emptyModalData = () => {
    setIRI(null);
    setType(null);
    setChannelSelected("");
    setCreatedAt(null);
    setID(null);
    setQueryCount(null);
    setQueryExports(null);
    setFolderValue(null);
    setFolderInputValue("");
    setCountName("");
    setQueryResult(null);
    setQueryResultBy([]);
    setUpdatedAt(null);
    setUserAccount(null);

    // Params
    setGeoCritera("none");
    setRegionsDepartmentsTableData([]);
    setPostalsCommunesTableData([]);
    setIrisTableData([]);
    setAddressTableData([]);
    setRegionCodeValue(null);
    setDepartmentCodeValue(null);
    setPostalCodeValue(null);
    setCityCodeValue(null);
    setIrisQuery("");
    setUseAgeSelector(false);
    setAgeValues([20, 37]);
    setMale(false);
    setFemale(false);
    setCSP([]);
    setHouseType([]);
    setOccupancyType([]);
    setFSquareNiv1Value(null);
    setFSquareNiv2Value([]);
  };

  const saveModalData = async (item) => {
    const QUERY_ITEM = {
      "@id": iri,
      "@type": type,
      channel: channelSelected,
      createdAt: createdAt,
      id: id,
      queryCount: queryCount,
      queryExports: queryExports,
      queryFolder:
        (folderValue && folderValue["@id"]) ||
        (currentFolder && currentFolder["@id"]),
      queryName: countName,
      queryParams: {
        fam1: FSquareNiv1Value?.CD_FAM_NIV1,
        fam2: FSquareNiv2Value,
        geoChoice: geoCritera,
        deptCodes:
          geoCritera === "deptCodes" ? regionsDepartmentsTableData : [],
        cityCodes: geoCritera === "cityCodes" ? postalsCommunesTableData : [],
        irisCodes: geoCritera === "irisCodes" ? irisTableData : [],
        address: geoCritera === "address" ? addressTableData : [],
        gender: {
          M: male,
          F: female,
        },
        csp: CSP,
        homeType: houseType,
        homeStatus: occupancyType,
        age: {
          checkAge: useAgeSelector,
          min: ageValues[0],
          max: ageValues[1],
        },
      },
      queryResultBy: [geoCritera],
      updatedAt: updatedAt,
      userAccount: userAccount,
    };

    if (item) {
      updateCountsList(iri, item);
    } else {
      updateCountsList(iri, QUERY_ITEM);
    }

    try {
      toast.info(<CircularProgress color="inherit" size={24} />);
      await apiManager.updateRessource(`${iri}`, QUERY_ITEM);
      toast.success(<DoneIcon />);
    } catch (e) {
      console.log(e);
      toast.error("Erreur lors de la sauvegarde");
    }
  };

  const runPotentialCount = async (id, shouldSaveModalData) => {
    setMapCoordinates([]);
    setMapCenter([]);
    setZDCCoordinates([]);
    setPotentialLoading(true);

    try {
      const potential = await apiManager.getRessource(`/api/queries/run/${id}`);
      setPotentialData(potential);
      setQueryCount(potential.queryCount);

      if (shouldSaveModalData) {
        saveModalData({
          "@id": iri,
          "@type": type,
          channel: channelSelected,
          createdAt: createdAt,
          id: id,
          queryCount: potential.queryCount,
          queryExports: queryExports,
          queryFolder:
            (folderValue && folderValue["@id"]) ||
            (currentFolder && currentFolder["@id"]),
          queryName: countName,
          queryParams: {
            fam1: FSquareNiv1Value?.CD_FAM_NIV1,
            fam2: FSquareNiv2Value,
            geoChoice: geoCritera,
            deptCodes:
              geoCritera === "deptCodes" ? regionsDepartmentsTableData : [],
            cityCodes:
              geoCritera === "cityCodes" ? postalsCommunesTableData : [],
            irisCodes: geoCritera === "irisCodes" ? irisTableData : [],
            address: geoCritera === "address" ? addressTableData : [],
            gender: {
              M: male,
              F: female,
            },
            csp: CSP,
            homeType: houseType,
            homeStatus: occupancyType,
            age: {
              checkAge: useAgeSelector,
              min: ageValues[0],
              max: ageValues[1],
            },
          },
          queryResult: `/api/query_results/${potential.id}`,
          queryResultBy: [geoCritera],
          updatedAt: updatedAt,
          userAccount: userAccount,
        });
      }

      if (potential.queryResultBy[0] === "cityCodes") {
        const potentialDataTableArray = [];

        let ZCDValue = potential.ventilatedResult[0].codePostal;

        potential.ventilatedResult.forEach((item, parentIndex) => {
          potentialDataTableArray.push({
            id: parentIndex,
            codeCommune: item.codeCommune,
            codePostal: item.codePostal,
            nomCommune: item.nomCommune,
            zoneChalandise: item.zoneChalandise,
            queryCount: item.queryCount,
            totalQueryCount: item.totalQueryCount,
            ZDCCol: ZCDValue,
          });

          if (item.ZDCCities.length !== 0) {
            setShowZDCColumn(true);
            item.ZDCCities.forEach((item, index) => {
              potentialDataTableArray.push({
                id: index,
                codeCommune: item.cityCode,
                codePostal: item.zipCode,
                nomCommune: item.cityName,
                zoneChalandise: null,
                queryCount: item.queryCount,
                totalQueryCount: null,
                parentId: parentIndex,
                ZDCCol: ZCDValue,
              });
            });
          }
        });

        setPotentialDataTable(potentialDataTableArray);

        const codeCommune = potential.ventilatedResult.map(
          (item) => item.codeCommune
        );

        codeCommune.forEach(async (item) => {
          const mapData = await apiManager.getRessource(
            `/api/geo/cities?code=${item}`
          );

          const coordinates = mapData[0].contour.coordinates;
          const center = mapData[0].centre.coordinates;

          if (coordinates.length === 1) {
            setMapCoordinates((prevState) =>
              _.concat(prevState, [coordinates])
            );
          } else {
            const remadeArray = [_.flatten(_.flatten(coordinates))];

            setMapCoordinates((prevState) =>
              _.concat(prevState, [remadeArray])
            );
          }
          setMapCenter((prevState) => _.concat(prevState, [center]));
        });

        const codeZDC = _.flattenDeep(
          potential.ventilatedResult.map((item) =>
            item.ZDCCities.map((item) => item.cityCode)
          )
        );

        codeZDC.forEach(async (item) => {
          const mapData = await apiManager.getRessource(
            `/api/geo/cities?code=${item}`
          );

          const coordinates = mapData[0].contour.coordinates;

          setZDCCoordinates((prevState) => _.concat(prevState, [coordinates]));
        });
      } else {
        setPotentialDataTable(potential.ventilatedResult);
      }

      setPotentialLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handlePreviousStep = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  const handleNextStep = () => {
    if (!readOnly) {
      if (countName === "") {
        setError("Le nom est obligatoire pour continuer.");
      } else if (!folderInputValue) {
        setError("Le choix du dossier est obligatoire pour continuer.");
      } else {
        if (activeStep === 0) {
          checkStep0();
        }
        if (activeStep === 1) {
          checkStep1();
        }
        if (activeStep === 2) {
          checkStep2();
        }

        if (activeStep === 3) {
          setShowZDCColumn(false);
          handleDrawerClose();
        }
      }
    } else {
      if (activeStep === 3) {
        setShowZDCColumn(false);
        handleDrawerClose();
      } else {
        setActiveStep((prevState) => prevState + 1);
      }
    }
  };

  const checkStep0 = async () => {
    if (channelSelected === "") {
      setError("Le choix du channel est obligatoire pour continuer.");
    } else {
      if (!modalData) {
        try {
          toast.info("Création en cours..");

          const result = await apiManager.createRessource("queries", {
            queryName: countName,
            queryFolder:
              (folderValue && folderValue["@id"]) ||
              (currentFolder && currentFolder["@id"]),
            channel: channelSelected,
          });
          setIRI(result["@id"]);
          setType(result["@type"]);
          setCreatedAt(result.createdAt);
          setID(result.id);
          setQueryCount(result.queryCount);
          setQueryExports(result.queryExports);
          setQueryResult(result.queryResult);
          setUpdatedAt(result.updatedAt);
          setUserAccount(result.userAccount);

          addToCountsList({
            "@id": result["@id"],
            "@type": result["@type"],
            channel: channelSelected,
            createdAt: result.createdAt,
            id: result.id,
            queryCount: result.queryCount,
            queryExports: result.queryExports,
            queryFolder:
              (folderValue && folderValue["@id"]) ||
              (currentFolder && currentFolder["@id"]),
            queryName: countName,
            queryParams: {
              fam1: FSquareNiv1Value?.CD_FAM_NIV1,
              fam2: FSquareNiv2Value,
              geoChoice: geoCritera,
              deptCodes:
                geoCritera === "deptCodes" ? regionsDepartmentsTableData : [],
              cityCodes:
                geoCritera === "cityCodes" ? postalsCommunesTableData : [],
              irisCodes: geoCritera === "irisCodes" ? irisTableData : [],
              address: geoCritera === "address" ? addressTableData : [],
              gender: {
                M: male,
                F: female,
              },
              csp: CSP,
              homeType: houseType,
              homeStatus: occupancyType,
              age: {
                checkAge: useAgeSelector,
                min: ageValues[0],
                max: ageValues[1],
              },
            },
            queryResult: result.queryResult,
            queryResultBy: [geoCritera],
            updatedAt: result.updatedAt,
            userAccount: result.userAccount,
          });
          toast.success("Comptage créé !");
        } catch (e) {
          console.log(e);
          toast.error("Erreur lors de la création");
        }
      } else {
        saveModalData();
      }
      setError("");
      setActiveStep((prevState) => prevState + 1);
    }
  };

  const checkStep1 = () => {
    //Todo: check if table is empty depending on selection and display error else continue and update
    setActiveStep((prevState) => prevState + 1);
    saveModalData();
  };

  const checkStep2 = async () => {
    setActiveStep((prevState) => prevState + 1);
    await saveModalData();
    runPotentialCount(id, true);
  };

  const handleCountNameChange = (event) => {
    setCountName(event.target.value);
  };

  const handleGeoCriteriaChange = (event) => {
    setGeoCritera(event.target.value);
  };

  const getRegionsCodes = async () => {
    try {
      const result = await axios.get(`https://geo.api.gouv.fr/regions/`);

      const filteredResult = _.remove(result.data, function (n) {
        return n.code > 10;
      });

      setRegionsCodes(filteredResult);
    } catch (e) {
      console.log(e);
    }
  };

  const getDepartmentsCodes = async () => {
    try {
      const result = await axios.get(`https://geo.api.gouv.fr/departements`);
      setDepartmentsCodes(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddRegion = () => {
    const departements = departmentsCodes.filter((result) => {
      return result.codeRegion === regionCodeValue.code;
    });

    setRegionsDepartmentsTableData((prevState) => {
      return _.unionBy(
        prevState,
        departements.map((departement) => {
          return {
            codeRegion: regionCodeValue.code,
            nomRegion: regionCodeValue.nom,
            codeDepartement: departement.code,
            nomDepartement: departement.nom,
          };
        }),
        "codeDepartement"
      );
    });
  };

  const handleAddDepartment = () => {
    const region = regionsCodes.find((result) => {
      return result.code === departmentCodeValue.codeRegion;
    });

    setRegionsDepartmentsTableData((prevState) => {
      return _.unionBy(
        prevState,
        [
          {
            codeRegion: region.code,
            nomRegion: region.nom,
            codeDepartement: departmentCodeValue.code,
            nomDepartement: departmentCodeValue.nom,
          },
        ],
        "codeDepartement"
      );
    });
  };

  const handleOnPasteDepartment = async () => {
    try {
      let str = await navigator.clipboard.readText();
      str = str.replace(/\s{2,}/g, ",");
      str = str.replace(/\t/g, ",");
      str = str
        .toString()
        .trim()
        .replace(/(\r\n|\n|\r)/g, ",");
      const result = str.split(",");

      if (result[0] !== "") {
        result.forEach((departementCode) => {
          const departement = departmentsCodes.find((result) => {
            return result.code === departementCode;
          });

          const region = regionsCodes.find((result) => {
            return result.code === departement.codeRegion;
          });

          if (region) {
            setRegionsDepartmentsTableData((prevState) => {
              return _.unionBy(
                prevState,
                [
                  {
                    codeRegion: region.code,
                    nomRegion: region.nom,
                    codeDepartement: departementCode,
                    nomDepartement: departement.nom,
                  },
                ],
                "codeDepartement"
              );
            });
          } else {
            setError("Les codes entrés ne sont pas des codes régions.");
          }
        });
      }
    } catch (e) {
      console.log("ERROR READING CLIPBOARD", e);
    }
  };

  const handleAddPostalCode = () => {
    postalCodeValue.codePostaux.forEach(async (codePostal) => {
      try {
        const result = await apiManager.getRessource(
          `/api/cities?codePostal=${codePostal}`
        );

        const cities = result;

        setPostalsCommunesTableData((prevState) => {
          return _.unionBy(
            prevState,
            cities.map((city) => {
              return {
                codePostal: city.codePostaux.join(", "),
                codeCommune: city.code,
                nomCommune: city.nom,
                zoneChalandise: 0,
              };
            }),
            "codeCommune"
          );
        });
      } catch (e) {
        console.log(e);
      }
    });
  };

  const handleOnPastePostalCode = async () => {
    try {
      let str = await navigator.clipboard.readText();
      str = str.replace(/\s{2,}/g, ",");
      str = str.replace(/\t/g, ",");
      str = str
        .toString()
        .trim()
        .replace(/(\r\n|\n|\r)/g, ",");
      const result = str.split(",");

      result.forEach(async (codePostal) => {
        try {
          // const result = await axios.get(
          //   `https://geo.api.gouv.fr/communes?codePostal=${codePostal}`
          // );

          const result = await apiManager.getRessource(
            `/api/cities?codePostal=${codePostal}`
          );

          const cities = result;

          if (cities) {
            setPostalsCommunesTableData((prevState) => {
              return _.unionBy(
                prevState,
                cities.map((city) => {
                  return {
                    codePostal: city.codePostaux.join(", "),
                    codeCommune: city.code,
                    nomCommune: city.nom,
                    zoneChalandise: 0,
                  };
                }),
                "codeCommune"
              );
            });
          } else {
            setError("Les codes entrés ne sont pas des codes postaux.");
          }
        } catch (e) {
          console.log(e);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleZDCMasterPostalSlider = (event, newValue) => {
    let data = postalsCommunesTableData;

    data.map((item) => {
      return (item.zoneChalandise = newValue);
    });

    setPostalsCommunesTableData((prevState) => {
      return _.unionBy(prevState, data, "codeCommune");
    });
  };

  const handleZDCPostalTableSlider = (event, newValue, rowData) => {
    let result = postalsCommunesTableData.find((item) => {
      return item.codeCommune === rowData.codeCommune;
    });

    result.zoneChalandise = newValue;

    setPostalsCommunesTableData((prevState) => {
      return _.unionBy(prevState, result, "codeCommune");
    });
  };

  const handleZDCMasterAddressSlider = (event, newValue) => {
    let data = addressTableData;

    data.map((item) => {
      return (item.zdc = newValue);
    });

    setAddressTableData((prevState) => {
      return _.unionBy(prevState, data, "address");
    });
  };

  const handleZDCAddressTableSlider = (event, newValue, rowData) => {
    let result = addressTableData.find((item) => {
      return item.address === rowData.address;
    });

    result.zdc = newValue;

    setAddressTableData((prevState) => {
      return _.unionBy(prevState, result, "address");
    });
  };

  const handleAddCommune = () => {
    const cityInfo = citiesCodes.find((result) => {
      return result.code === cityCodeValue.code;
    });

    setPostalsCommunesTableData((prevState) => {
      return _.unionBy(
        prevState,
        [
          {
            codePostal: cityInfo.codePostaux.join(", "),
            codeCommune: cityCodeValue.code,
            nomCommune: cityCodeValue.nom,
            zoneChalandise: 0,
          },
        ],
        "codeCommune"
      );
    });
  };

  const handleOnPasteCommune = async () => {
    setError("");
    try {
      let str = await navigator.clipboard.readText();
      str = str.replace(/\s{2,}/g, ",");
      str = str.replace(/\t/g, ",");
      str = str
        .toString()
        .trim()
        .replace(/(\r\n|\n|\r)/g, ",");
      const result = str.split(",");

      if (result[0] !== "") {
        result.forEach((codeCommune) => {
          const cityInfo = citiesCodes.find((cityResult) => {
            return cityResult.code === codeCommune;
          });

          if (cityInfo) {
            setPostalsCommunesTableData((prevState) => {
              return _.unionBy(
                prevState,
                [
                  {
                    codePostal: cityInfo.codePostaux.join(", "),
                    codeCommune: cityInfo.code,
                    nomCommune: cityInfo.nom,
                    zoneChalandise: 0,
                  },
                ],
                "codeCommune"
              );
            });
          } else {
            setError("Les codes entrés ne sont pas des codes communes.");
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddIris = async () => {
    const result = await apiManager.getPostedData(`/api/iris`, {
      iris: [irisQuery],
    });

    setIrisTableData((prevState) => {
      return _.unionBy(
        prevState,
        result.iris.map((iris) => {
          return {
            codeIris: iris,
          };
        }),
        "codeIris"
      );
    });
  };

  const handleOnPasteIris = async () => {
    try {
      let str = await navigator.clipboard.readText();
      str = str.replace(/\s{2,}/g, ",");
      str = str.replace(/\t/g, ",");
      str = str
        .toString()
        .trim()
        .replace(/(\r\n|\n|\r)/g, ",");
      const result = str.split(",");

      const irisResult = await apiManager.getPostedData(`/api/iris`, {
        iris: result,
      });

      setIrisTableData((prevState) => {
        return _.unionBy(
          prevState,
          irisResult.iris.map((iris) => {
            return {
              codeIris: iris,
            };
          }),
          "codeIris"
        );
      });

      if (irisResult.notFound[0] !== "") {
        setError(
          irisResult.notFound.length !== 0 && irisResult.notFound.length <= 5
            ? `Les codes ${irisResult.notFound.join(", ")} sont invalides`
            : `Les codes ${_.slice(irisResult.notFound, 0, 5).join(
                ", "
              )}, ainsi que ${
                irisResult.notFound.length - 5
              } code(s) sont invalides`
        );
      } else {
        setError("");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddAddress = () => {
    setAddressTableData((prevState) => {
      return _.unionBy(
        prevState,
        [
          {
            address: addressInputValue,
            lon: addressValue.center[0],
            lat: addressValue.center[1],
            zdc: 0,
          },
        ],
        "address"
      );
    });
  };

  const handleIrisSearchChange = (event) => {
    setIrisQuery(event.target.value);
  };

  const handlePostalAddressSearchChange = (event, newValue) => {
    const value = event.target.value;
    setAddressInputValue(newValue);

    if (value) {
      debounceFetchAddresses(value);
    } else {
      setMatchingAddresses([]);
    }
  };

  const fetchAddresses = async (value) => {
    try {
      const result = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?language=fr&country=fr&types=address&access_token=pk.eyJ1IjoibGF1cmVudC1kZXNjYW1wcyIsImEiOiJja2loNWFsbnkxdTNyMnJwZXJsa294cGt0In0.3ncndIx8GEQwOwnaG0uulA`
      );
      const data = result.data.features;
      setMatchingAddresses(data);
    } catch (e) {
      console.log(e);
    }
  };

  const debounceFetchAddresses = React.useCallback(
    debounceQuery(fetchAddresses, 500),
    []
  );

  function ageSliderValueText(value) {
    return `${value}ans`;
  }

  const handleAgeChange = (event, newValue) => {
    setAgeValues(newValue);
  };

  const handleCSPChangeMultiple = (event) => {
    setCSP(event.target.value);
  };

  const handleHouseTypeChangeMultiple = (event) => {
    setHouseType(event.target.value);
  };

  const handleOccupancyTypeChange = (event) => {
    setOccupancyType(event.target.value);
  };

  const handleFSquareNiv1Change = (event, newValue) => {
    setFSquareNiv1Value(newValue);

    if (newValue === null) {
      setFSquareNiv2Options(FSquareNiv2OptionsBis);
    } else {
      let filteredInput = FSquareNiv2OptionsBis.filter(
        (item) => item.CD_FAM_NIV1 === newValue.CD_FAM_NIV1
      );
      setFSquareNiv2Options(filteredInput);
    }
  };

  const onClose = () => {
    handleDrawerClose();
    setError("");
    setShowZDCColumn(false);
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={onClose}
    >
      <AppBar position="absolute" color="secondary">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="overline">
              Création d'un nouveau comptage
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={
                    activeStep === 3
                      ? "Le nom du comptage n'est modifiable que dans les étapes précédentes"
                      : "Entrez le nom du comptage ici."
                  }
                  arrow
                  placement="top"
                >
                  <InputBase
                    id="standard-basic"
                    classes={{
                      root: classes.nameInput,
                      error: classes.nameInputError,
                    }}
                    fullWidth
                    value={countName}
                    onChange={handleCountNameChange}
                    placeholder="Entrer le nom du comptage"
                    inputProps={{ "aria-label": "nom-comptage" }}
                    disabled={readOnly || activeStep === 3}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <SearchBoxList
                  id="folders-search-box"
                  label="Dossiers"
                  options={folders}
                  value={folderValue || currentFolder}
                  inputValue={folderInputValue}
                  onChange={(event, newValue) => {
                    setFolderValue(newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    setFolderInputValue(newValue);
                  }}
                  getOptionLabel={(option) => option.folderName}
                  noOptionsText="Séléctionnez un dossier"
                  clearable
                  disabled={readOnly}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              classes={{
                root: classes.stepperRoot,
              }}
            >
              {steps.map((label, index) => (
                <Step
                  key={label}
                  onClick={() => {
                    if (index !== 3) {
                      saveModalData();
                      setActiveStep(index);
                    }
                  }}
                  classes={{
                    root: classes.stepRoot,
                  }}
                >
                  <StepLabel
                    StepIconProps={{
                      classes: { active: classes.stepIconActive },
                    }}
                    classes={{
                      disabled: classes.stepLabel,
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Box className={classes.stepButtonsWrapper} boxShadow={6}>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography variant="body2" className={classes.errorText}>
                  {error}
                </Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end" spacing={0}>
                <Grid item>
                  {activeStep !== 0 && (
                    <Button
                      variant="text"
                      color="default"
                      onClick={handlePreviousStep}
                      className={classes.backButton}
                      startIcon={<ChevronLeftIcon />}
                    >
                      Retour
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleNextStep}
                  >
                    {activeStep !== 3 ? "Suivant" : "Terminer"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {activeStep === 0 && (
            <Grid item xs={12}>
              <Typography variant="h6" color="initial">
                Canal d'acquisition
              </Typography>
              <Typography variant="subtitle1" color="initial">
                Choisissez un canal d'acquisition *
              </Typography>
              <Box className={classes.chanelsWrapper}>
                {CHANNELS_DATA.map((channel, index) => {
                  return (
                    <ChannelItem
                      key={index}
                      onClick={() => {
                        if (!readOnly) {
                          setChannelSelected(channel.value);
                        }
                      }}
                      icon={channel.icon}
                      title={channel.title}
                      selected={channel.value === channelSelected}
                    />
                  );
                })}
              </Box>
            </Grid>
          )}

          {activeStep === 1 && (
            <Grid item xs={12}>
              <Typography variant="h6" color="initial">
                Critères géographiques
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl className={classes.formControl} fullWidth>
                    <Select
                      fullWidth
                      labelId="geo-criteria-simple-select-placeholder-label-label"
                      id="geo-criteria-simple-select-placeholder-label"
                      value={geoCritera}
                      onChange={handleGeoCriteriaChange}
                      displayEmpty
                      className={classes.selectEmpty}
                      disabled={readOnly}
                      defaultValue="none"
                    >
                      <MenuItem value="none">
                        <em>Aucun</em>
                      </MenuItem>
                      <MenuItem value="deptCodes">
                        Codes régions / départements
                      </MenuItem>
                      <MenuItem value="cityCodes">
                        Codes postaux / communes
                      </MenuItem>
                      <MenuItem value="irisCodes">Codes IRIS</MenuItem>
                      <MenuItem value="address">Adresses postales</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8} />

                {geoCritera === "deptCodes" && (
                  <>
                    <Grid item xs={4}>
                      <SearchBoxList
                        id="region-code-search-box"
                        label="Codes régions"
                        options={regionsCodes}
                        value={regionCodeValue}
                        inputValue={regionCodeInputValue}
                        onChange={(event, newValue) => {
                          setRegionCodeValue(newValue);
                        }}
                        onInputChange={(event, newValue) => {
                          setRegionCodeInputValue(newValue);
                        }}
                        getOptionLabel={(option) =>
                          `${option.code} - ${option.nom}`
                        }
                        noOptionsText="Séléctionner un code région"
                        clearable
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={2}
                      justify="center"
                      alignContent="center"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleAddRegion}
                        disabled={readOnly || !regionCodeValue}
                      >
                        Ajouter
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <SearchBoxList
                        id="department-code-search-box"
                        label="Codes départements"
                        options={departmentsCodes}
                        value={departmentCodeValue}
                        inputValue={departmentCodeInputValue}
                        onChange={(event, newValue) => {
                          setDepartmentCodeValue(newValue);
                        }}
                        onInputChange={(event, newValue) => {
                          setDepartmentCodeInputValue(newValue);
                        }}
                        getOptionLabel={(option) =>
                          `${option.code} - ${option.nom}`
                        }
                        noOptionsText="Séléctionner un code département"
                        clearable
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={1}
                      justify="center"
                      alignContent="center"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleAddDepartment}
                        disabled={readOnly || !departmentCodeValue}
                      >
                        Ajouter
                      </Button>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={1}
                      justify="center"
                      alignContent="center"
                    >
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title="Copier la liste de code départements issus d’un fichier Excel puis cliquer sur coller pour les insérer."
                        arrow
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleOnPasteDepartment}
                          disabled={readOnly}
                        >
                          Coller
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <MaterialTable
                        columns={[
                          {
                            title: "Code région",
                            field: "codeRegion",
                            type: "numeric",
                            align: "left",
                          },
                          {
                            title: "Nom région",
                            field: "nomRegion",
                          },
                          {
                            title: "Code département",
                            field: "codeDepartement",
                            type: "numeric",
                            align: "left",
                          },
                          {
                            title: "Nom département",
                            field: "nomDepartement",
                          },
                        ]}
                        data={regionsDepartmentsTableData}
                        options={{
                          exportButton: true,
                          exportAllData: true,
                          showTitle: false,
                          selection: !readOnly,
                        }}
                        actions={[
                          {
                            tooltip: "Supprimer toutes les saisies",
                            icon: "delete",
                            onClick: (evt, data) => {
                              setRegionsDepartmentsTableData((prevState) =>
                                _.difference(prevState, data)
                              );
                            },
                          },
                        ]}
                        localization={materialTableLocalization}
                      />
                    </Grid>
                  </>
                )}

                {geoCritera === "cityCodes" && (
                  <>
                    <Grid item xs={4}>
                      <SearchBoxList
                        id="postal-code-search-box"
                        label="Codes postaux"
                        options={postalsCodes}
                        value={postalCodeValue}
                        inputValue={postalCodeInputValue}
                        onChange={(event, newValue) => {
                          setPostalCodeValue(newValue);
                        }}
                        onInputChange={(event, newValue) => {
                          setPostalCodeInputValue(newValue);
                        }}
                        getOptionLabel={(option) =>
                          `${option.nom} (${option.codePostaux.join(", ")})`
                        }
                        noOptionsText="Séléctionner un code postal"
                        clearable
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={1}
                      justify="center"
                      alignContent="center"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleAddPostalCode}
                        disabled={readOnly || !postalCodeValue}
                      >
                        Ajouter
                      </Button>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={1}
                      justify="center"
                      alignContent="center"
                    >
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title="Copier la liste de code postaux issus d’un fichier Excel puis cliquer sur coller pour les insérer."
                        arrow
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleOnPastePostalCode}
                          disabled={readOnly}
                        >
                          Coller
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                      <SearchBoxList
                        id="cities-code-search-box"
                        label="Codes communes"
                        options={citiesCodes}
                        value={cityCodeValue}
                        inputValue={cityCodeInputValue}
                        onChange={(event, newValue) => {
                          setCityCodeValue(newValue);
                        }}
                        onInputChange={(event, newValue) => {
                          setCityCodeInputValue(newValue);
                        }}
                        getOptionLabel={(option) =>
                          `${option.nom} (${option.code})`
                        }
                        noOptionsText="Séléctionner un code commune"
                        clearable
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={1}
                      justify="center"
                      alignContent="center"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleAddCommune}
                        disabled={readOnly || !cityCodeValue}
                      >
                        Ajouter
                      </Button>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={1}
                      justify="center"
                      alignContent="center"
                    >
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title="Copier la liste de code communes issus d’un fichier Excel puis cliquer sur coller pour les insérer."
                        arrow
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleOnPasteCommune}
                          disabled={readOnly}
                        >
                          Coller
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={3}>
                      <Slider
                        classes={{ valueLabel: classes.sliderValueLabel }}
                        defaultValue={0}
                        aria-labelledby="zdc-master-slider"
                        step={1}
                        min={0}
                        max={50}
                        onChange={handleZDCMasterPostalSlider}
                        valueLabelDisplay="off"
                        color="secondary"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MaterialTable
                        readOnly={readOnly}
                        columns={[
                          {
                            title: "Code postal",
                            field: "codePostal",
                            type: "numeric",
                            align: "left",
                          },
                          {
                            title: "Code commune",
                            field: "codeCommune",
                            type: "numeric",
                            align: "left",
                          },
                          {
                            title: "Nom commune",
                            field: "nomCommune",
                          },
                          {
                            title: "Zone de chalandise (KM)",
                            field: "zoneChalandise",
                            render: (rowData) => (
                              <Slider
                                classes={{
                                  valueLabel: classes.sliderValueLabel,
                                }}
                                defaultValue={0}
                                aria-labelledby="zdc-slider"
                                step={1}
                                min={0}
                                max={50}
                                value={rowData.zoneChalandise}
                                onChange={(event, newValue) =>
                                  handleZDCPostalTableSlider(
                                    event,
                                    newValue,
                                    rowData
                                  )
                                }
                                valueLabelDisplay="on"
                                color="secondary"
                                disabled={readOnly}
                              />
                            ),
                          },
                        ]}
                        data={postalsCommunesTableData}
                        options={{
                          exportButton: true,
                          exportAllData: true,
                          showTitle: false,
                          selection: !readOnly,
                        }}
                        actions={[
                          {
                            tooltip: "Supprimer toutes les saisies",
                            icon: "delete",
                            onClick: (evt, data) => {
                              setPostalsCommunesTableData((prevState) =>
                                _.difference(prevState, data)
                              );
                            },
                          },
                        ]}
                        localization={materialTableLocalization}
                      />
                    </Grid>
                  </>
                )}
                {geoCritera === "irisCodes" && (
                  <>
                    <Grid item xs={4}>
                      <TextBox
                        id="iris-box"
                        label="Code IRIS"
                        value={irisQuery}
                        onChange={handleIrisSearchChange}
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={1}
                      justify="center"
                      alignContent="center"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleAddIris}
                        disabled={readOnly || !irisQuery}
                      >
                        Ajouter
                      </Button>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={1}
                      justify="center"
                      alignContent="center"
                    >
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title="Copier la liste de code iris issus d’un fichier Excel puis cliquer sur coller pour les insérer."
                        arrow
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleOnPasteIris}
                          disabled={readOnly}
                        >
                          Coller
                        </Button>
                      </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                      <MaterialTable
                        readOnly={readOnly}
                        columns={[
                          {
                            title: "Code IRIS",
                            field: "codeIris",
                            type: "numeric",
                            align: "left",
                          },
                        ]}
                        data={irisTableData}
                        options={{
                          exportButton: true,
                          exportAllData: true,
                          showTitle: false,
                          selection: !readOnly,
                        }}
                        actions={[
                          {
                            tooltip: "Supprimer toutes les saisies",
                            icon: "delete",
                            onClick: (evt, data) => {
                              setIrisTableData((prevState) =>
                                _.difference(prevState, data)
                              );
                            },
                          },
                        ]}
                        localization={materialTableLocalization}
                      />
                    </Grid>
                  </>
                )}
                {geoCritera === "address" && (
                  <>
                    <Grid item xs={4}>
                      <SearchBoxList
                        id="postal-search-box"
                        label="Adresse postale"
                        options={matchingAddresses}
                        value={addressValue}
                        inputValue={addressInputValue}
                        onChange={(event, newValue) => {
                          setAddressValue(newValue);
                        }}
                        onInputChange={handlePostalAddressSearchChange}
                        getOptionLabel={(option) => `${option.place_name_fr}`}
                        noOptionsText="Aucune adresse correspondante"
                        clearable
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={1}
                      justify="center"
                      alignContent="center"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleAddAddress}
                        disabled={readOnly || !addressValue}
                      >
                        Ajouter
                      </Button>
                    </Grid>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={3}>
                      <Slider
                        classes={{ valueLabel: classes.sliderValueLabel }}
                        defaultValue={0}
                        aria-labelledby="zdc-master-slider"
                        step={1}
                        min={0}
                        max={50}
                        onChange={handleZDCMasterAddressSlider}
                        valueLabelDisplay="off"
                        color="secondary"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MaterialTable
                        readOnly={readOnly}
                        columns={[
                          {
                            title: "Adresse",
                            field: "address",
                          },
                          {
                            title: "Zone de chalandise (KM)",
                            field: "zdc",
                            render: (rowData) => (
                              <Slider
                                classes={{
                                  valueLabel: classes.sliderValueLabel,
                                }}
                                defaultValue={0}
                                aria-labelledby="zdc-slider"
                                step={1}
                                min={0}
                                max={50}
                                value={rowData.zdc}
                                onChange={(event, newValue) =>
                                  handleZDCAddressTableSlider(
                                    event,
                                    newValue,
                                    rowData
                                  )
                                }
                                valueLabelDisplay="on"
                                color="secondary"
                                disabled={readOnly}
                              />
                            ),
                          },
                        ]}
                        data={addressTableData}
                        options={{
                          exportButton: true,
                          exportAllData: true,
                          showTitle: false,
                          selection: !readOnly,
                        }}
                        actions={[
                          {
                            tooltip: "Supprimer toutes les saisies",
                            icon: "delete",
                            onClick: (evt, data) => {
                              setAddressTableData((prevState) =>
                                _.difference(prevState, data)
                              );
                            },
                          },
                        ]}
                        localization={materialTableLocalization}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}

          {activeStep === 2 && (
            <Grid item xs={12}>
              <Typography variant="h6" color="initial">
                Critères socio-démographiques
              </Typography>
              <Box className={classes.ageToggleWrapper}>
                <Typography variant="body2" color="initial">
                  Inclure une tranche d'âge
                </Typography>
                <Switch
                  checked={useAgeSelector}
                  onChange={() => setUseAgeSelector((prevState) => !prevState)}
                  inputProps={{ "aria-label": "Age selector toggle" }}
                  disabled={readOnly}
                />
              </Box>

              {useAgeSelector && (
                <Box className={classes.ageSliderWrapper}>
                  <Slider
                    color="secondary"
                    value={ageValues}
                    onChange={handleAgeChange}
                    valueLabelDisplay="on"
                    aria-labelledby="age-range-slider"
                    getAriaValueText={ageSliderValueText}
                    disabled={readOnly}
                  />
                  <Box className={classes.ageSliderCaptionWrapper}>
                    <Typography variant="caption" color="initial">
                      18 ans
                    </Typography>
                    <Typography variant="caption" color="initial" align="right">
                      100 ans
                    </Typography>
                  </Box>
                </Box>
              )}

              <Box className={classes.genderToggleWrapper}>
                <Typography variant="subtitle2" color="initial">
                  Genre
                </Typography>
                <Box>
                  <Box className={classes.toggleWrapper}>
                    <Typography variant="body2" color="initial">
                      Homme
                    </Typography>
                    <Switch
                      checked={male}
                      onChange={() => setMale((prevState) => !prevState)}
                      inputProps={{ "aria-label": "Male toggle" }}
                      disabled={readOnly}
                    />
                  </Box>
                  <Box className={classes.toggleWrapper}>
                    <Typography variant="body2" color="initial">
                      Femme
                    </Typography>
                    <Switch
                      checked={female}
                      onChange={() => setFemale((prevState) => !prevState)}
                      inputProps={{ "aria-label": "Male toggle" }}
                      disabled={readOnly}
                    />
                  </Box>
                </Box>
              </Box>

              <Box className={classes.inputsWrapper}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel shrink labelId="csp-mutiple-chip-label">
                        CSP
                      </InputLabel>
                      <Select
                        disabled={readOnly}
                        color="secondary"
                        labelId="csp-mutiple-chip-label"
                        id="csp-mutiple-chip"
                        multiple
                        value={CSP}
                        onChange={handleCSPChangeMultiple}
                        margin="dense"
                        input={
                          <Input
                            id="csp-select-multiple-chip"
                            style={{ minHeight: 50 }}
                            color="secondary"
                          />
                        }
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((item, index) => {
                              let name = null;

                              switch (item) {
                                case "1":
                                  name = "CSP -";
                                  break;
                                case "2":
                                  name = "CSP Moyen";
                                  break;
                                case "3":
                                  name = "CSP +";
                                  break;
                                case "4":
                                  name = "CSP ++";
                                  break;
                                default:
                                  name = null;
                              }

                              return (
                                <Chip
                                  key={index}
                                  label={name}
                                  className={classes.chip}
                                />
                              );
                            })}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {CSP_DATA.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        shrink
                        labelId="house-type-mutiple-chip-label"
                      >
                        Types d'habitations
                      </InputLabel>
                      <Select
                        disabled={readOnly}
                        color="secondary"
                        labelId="house-type-mutiple-chip-label"
                        id="house-type-mutiple-chip"
                        multiple
                        value={houseType}
                        onChange={handleHouseTypeChangeMultiple}
                        input={
                          <Input
                            id="house-type-select-multiple-chip"
                            style={{ minHeight: 50 }}
                          />
                        }
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((item, index) => {
                              let name = null;

                              switch (item) {
                                case "1":
                                  name = "Maison";
                                  break;
                                case "2":
                                  name = "Appartement";
                                  break;
                                default:
                                  name = null;
                              }

                              return (
                                <Chip
                                  key={index}
                                  label={name}
                                  className={classes.chip}
                                />
                              );
                            })}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {HOUSE_TYPE_DATA.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        shrink
                        labelId="occupancy-type-mutiple-chip-label"
                      >
                        Statuts d'habitation
                      </InputLabel>
                      <Select
                        disabled={readOnly}
                        color="secondary"
                        labelId="occupancy-type-mutiple-chip-label"
                        id="occupancy-type-mutiple-chip"
                        multiple
                        value={occupancyType}
                        onChange={handleOccupancyTypeChange}
                        input={
                          <Input
                            id="occupancy-type-select-multiple-chip"
                            style={{ minHeight: 50 }}
                          />
                        }
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((item, index) => {
                              let name = null;

                              switch (item) {
                                case "1":
                                  name = "Locataire";
                                  break;
                                case "2":
                                  name = "Propriétaire";
                                  break;
                                default:
                                  name = null;
                              }

                              return (
                                <Chip
                                  key={index}
                                  label={name}
                                  className={classes.chip}
                                />
                              );
                            })}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {OCCUPANCY_TYPE_DATA.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Typography variant="h6" color="initial">
                Family square
              </Typography>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <SearchBoxList
                      id="FSquare-niv1-search-box"
                      label="Family square niveau 1"
                      options={FSquareNiv1Options}
                      value={FSquareNiv1Value}
                      inputValue={FSquareNiv1InputValue}
                      onChange={handleFSquareNiv1Change}
                      onInputChange={(event, newValue) => {
                        setFSquareNiv1InputValue(newValue);
                      }}
                      getOptionLabel={(option) => `${option.LB_FAM_NIV1}`}
                      noOptionsText="Séléctionner un niveau"
                      clearable
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={FSquareNiv2Options}
                        value={FSquareNiv2Value}
                        onChange={(event, newValue) => {
                          setFSquareNiv2Value([...newValue]);
                        }}
                        getOptionLabel={(option) => option.LB_FAM_NIV2}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Family square niveau 2"
                            placeholder="Family square niveau 2"
                            margin="dense"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          {activeStep === 3 && (
            <>
              {modalData && !readOnly && (
                <>
                  <Grid item xs={9} />
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => runPotentialCount(id, false)}
                      fullWidth
                    >
                      Rafraîchir le comptage
                    </Button>
                  </Grid>
                </>
              )}
              {/* <Grid item xs={12}>
                <Typography variant="h6">Ventilé par :</Typography>
                <div style={{ display: 'flex', gap: 16 }}>
                  {[
                    {
                      id: 'partners',
                      label: 'Partenaires',
                    },
                    {
                      id: 'regions',
                      label: 'Régions',
                    },
                    {
                      id: 'departments',
                      label: 'Départements',
                    },
                    {
                      id: 'postalCodes',
                      label: 'Codes postaux',
                    }, 
                    {
                      id: 'cityCodes',
                      label: 'Code commune',
                    }, 
                    { 
                      id: 'sexe',
                      label: 'Sexe',
                    }
                  ].map((item) => {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox />
                        <Typography>{item.label}</Typography>
                      </div>
                    )
                  })}
                </div>
              </Grid> */}
              <Grid item xs={12}>
                {potentialData &&
                potentialData.length !== 0 &&
                !potentialLoading ? (
                  <>
                    {potentialData.queryResultBy.map(
                      (item) =>
                        (item === "deptCodes" && (
                          <Grid item xs={12}>
                            <MaterialTable
                              columns={[
                                {
                                  title: "Code région",
                                  field: "regCode",
                                  type: "numeric",
                                  align: "left",
                                },
                                {
                                  title: "Nom région",
                                  field: "regName",
                                },
                                {
                                  title: "Code département",
                                  field: "deptCode",
                                  type: "numeric",
                                  align: "left",
                                },
                                {
                                  title: "Nom département",
                                  field: "deptName",
                                },
                                {
                                  title: "Potentiel",
                                  field: "queryCount",
                                  type: "numeric",
                                  align: "left",
                                },
                              ]}
                              data={potentialData.ventilatedResult}
                              options={{
                                exportButton: true,
                              }}
                              title={`Potentiel : ${queryCount}`}
                              localization={materialTableLocalization}
                            />
                          </Grid>
                        )) ||
                        (item === "cityCodes" && (
                          <Grid item xs={12}>
                            <MaterialTable
                              tableRef={potentialDataTableRef}
                              onRowClick={(e, rowData) =>
                                potentialDataTableRef.current.onTreeExpandChanged(
                                  rowData.tableData.path,
                                  rowData
                                )
                              }
                              columns={[
                                showZDCColumn && {
                                  title: "ZDC",
                                  field: "ZDCCol",
                                  hidden: true,
                                },
                                {
                                  title: "Code postal",
                                  field: "codePostal",
                                  type: "numeric",
                                  align: "left",
                                },
                                {
                                  title: "Code commune",
                                  field: "codeCommune",
                                  type: "numeric",
                                  align: "left",
                                },
                                {
                                  title: "Nom commune",
                                  field: "nomCommune",
                                },
                                {
                                  title: "Zone de chalandise (KM)",
                                  field: "zoneChalandise",
                                  type: "numeric",
                                  align: "left",
                                },
                                {
                                  title: "Potentiel",
                                  field: "queryCount",
                                  type: "numeric",
                                  align: "left",
                                },
                                {
                                  title: "Potentiel avec ZDC",
                                  field: "totalQueryCount",
                                  type: "numeric",
                                  align: "left",
                                },
                              ].filter(Boolean)}
                              data={potentialDataTable}
                              parentChildData={(row, rows) =>
                                rows.find((a) => a.id === row.parentId)
                              }
                              options={{
                                exportButton: true,
                                exportAllData: true,
                                exportCsv: (columns, data) => {
                                  exportDataAsExcel(columns, potentialDataTable, {
                                    fileName: queryCount
                                      ? `Potentiel_${queryCount}`
                                      : `Potentiel_${moment().format("YYYY-MM-DD")}`,
                                  });
                                },
                                exportPdf: (columns, data) => {
                                  exportDataAsPdf(columns, potentialDataTable, {
                                    fileName: queryCount
                                      ? `Potentiel_${queryCount}`
                                      : `Potentiel_${moment().format("YYYY-MM-DD")}`,
                                    title: `Potentiel : ${queryCount}`,
                                  });
                                },
                              }}
                              title={`Potentiel : ${queryCount}`}
                              // localization={materialTableLocalization}
                              localization={{
                                pagination: {
                                  labelDisplayedRows: "{from}-{to} sur {count}",
                                  labelRowsSelect: "lignes",
                                  labelRowsPerPage: "lignes par page:",
                                },
                                toolbar: {
                                  nRowsSelected: "{0} saisie(s) séléctionnée(s)",
                                  exportTitle: "Exporter",
                                  searchPlaceholder: "Rechercher",
                                  searchTooltip: "Rechercher",
                                  exportCSVName: "Export as excel",
                                  exportPDFName: "Export as pdf",
                                },
                                header: {
                                  actions: "Actions",
                                },
                                body: {
                                  emptyDataSourceMessage: "Aucune saisies entrées",
                                  filterRow: {
                                    filterTooltip: "Filtrer",
                                  },
                                },
                              }}
                            />
                          </Grid>
                        )) ||
                        (item === "irisCodes" && (
                          <Grid item xs={12}>
                            <MaterialTable
                              columns={[
                                {
                                  title: "Code IRIS",
                                  field: "irisCode",
                                  type: "numeric",
                                  align: "left",
                                },
                                {
                                  title: "Potentiel",
                                  field: "queryCount",
                                  type: "numeric",
                                  align: "left",
                                },
                              ]}
                              data={potentialData.ventilatedResult}
                              options={{
                                exportButton: true,
                                exportAllData: true,
                              }}
                              title={`Potentiel : ${queryCount}`}
                              localization={materialTableLocalization}
                            />
                          </Grid>
                        )) ||
                        (item === "address" && (
                          <Grid item xs={12}>
                            <MaterialTable
                              columns={[
                                {
                                  title: "Adresse",
                                  field: "address",
                                },
                                {
                                  title: "Zone de chalandise (KM)",
                                  field: "zdc",
                                  type: "numeric",
                                  align: "left",
                                },
                                {
                                  title: "Potentiel",
                                  field: "queryCount",
                                  type: "numeric",
                                  align: "left",
                                },
                                // {
                                //   title: "Potentiel avec ZDC",
                                //   field: "totalQueryCount",
                                //   type: "numeric",
                                //   align: "left",
                                //   emptyValue: "0",
                                // },
                              ]}
                              data={potentialData.ventilatedResult}
                              options={{
                                exportButton: true,
                                exportAllData: true,
                              }}
                              title={`Potentiel : ${queryCount}`}
                              localization={materialTableLocalization}
                            />
                          </Grid>
                        )) ||
                        (item === "none" && (
                          <Grid item xs={12}>
                            <Typography variant="h6" color="initial">
                              Potentiel :
                            </Typography>
                            <Typography variant="body1" color="initial">
                              {queryCount}
                            </Typography>
                          </Grid>
                        ))
                    )}
                    {geoCritera === "cityCodes" && (
                      <Grid item xs={12}>
                        <Map
                          className={classes.mapContainer}
                          coordinates={coordinates}
                          zdcCoordinates={zdcCoordinates}
                          center={mapCenter}
                        />
                      </Grid>
                    )}
                  </>
                ) : (
                  <Box className={classes.loaderWrapper}>
                    <CircularProgress color="secondary" />
                  </Box>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Drawer>
  );
};

export default CountModal;
