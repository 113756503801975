import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../../../../context/authContext'

// Components
import MainTemplate from '../../../../components/templates/main'
import UserForm from '../../components/userForm'

// Notification
import { toast } from 'react-toastify'


export default function CreateForm(props){

  const { apiManager } = useAuth()
  const { userIRI, orgIRI } = { ...props }
  
  const [ userData, setUserData ] = useState(props.userData)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ inError, setInError] = useState(false)


  // Initializing userData if userIRI is provided (edit mode)
  if(!userData && userIRI && !isLoading && !inError){
    setIsLoading(true)
    apiManager.getRessource(userIRI)
      .then(response => {
        setUserData(response)
      })
      .catch(e => {
        toast.error(e)
        setInError(true)
      })
      .finally( () => setIsLoading(false))
  }

  /**
   * Check if the form is in edition mode or not
   */
  function isEditMode(){ return (userData && !isNaN(userData.id)) }

  return (
    !inError ? (
      <MainTemplate 
        key={userData ? userData.id : 'new'}
        pageCategory= 'Gestion des utilisateurs'
        pageTitle= {isEditMode() ? 'Editer un utilisateur' : 'Créer un utilisateur'}
      >
        { isLoading ? 'Chargement en cours' : <UserForm userData={userData} setUserData={setUserData} orgIRI={orgIRI} /> }
      </MainTemplate>
    ) : ''
  )
}

CreateForm.propTypes = {
  userIRI: PropTypes.string, // The user IRI when opening the form in edit mode
  organization: PropTypes.string, // An organization IRI used as a preselect organization when opening the form
}
