const locales_FR = {
  scenes: {
    query:{
      queryForm: {
        channel:{
          sms: 'SMS', 
          email: 'Email', 
          postal: 'Voie postale', 
          phone : 'Téléphone'
        },
        geoChoice : {
          none : 'Aucun',
          cityCodes : 'Codes postaux / communes', 
          irisCodes : 'Codes IRIS', 
          deptCodes : 'Codes régions / départements'
        },
        demoCriteria: {
          csp: {
            none: 'Aucune sélection',
            1: 'CSP -', 
            2: 'CSP Moyen',
            3: 'CSP +'
          },
          homeType: {
            none: 'Aucune sélection',
            1: 'Maison', 
            2: 'Appartement',
          }, 
          homeStatus: {
            none: 'Aucune sélection',
            1: 'Locataire', 
            2: 'Propriétaire',
          },
          gender: {
            M: 'Hommes', 
            F: 'Femmes',
          }
        },
      }, 
      querySummary: {
        channel:{
          'sms': 'SMS', 
          'email': 'Email', 
          'postal': 'Voie postale', 
          'phone' : 'Téléphone'
        },
        geoChoice:{
          none: 'Aucune sélection',
          irisCodes: 'Codes IRIS',
          zipCodes: 'Zipcodes',
          cityCodes: 'Codes communes',
          deptCodes: 'Codes départements',
        },
        demoCriteria: {
          csp: {
            none: 'Aucune sélection',
            1: 'CSP -', 
            2: 'CSP Moyen',
            3: 'CSP +'
          },
          homeType: {
            none: 'Aucune sélection',
            1: 'Maison', 
            2: 'Appartement',
          }, 
          homeStatus: {
            none: 'Aucune sélection',
            1: 'Locataire', 
            2: 'Propriétaire',
          },
          gender: {
            M: 'Hommes', 
            F: 'Femmes',
          }, 
        },
      }
    }
  },
  materialTable : {
    pagination: {
      labelDisplayedRows: '{from}-{to} sur {count}',
      labelRowsSelect: 'lignes',
      labelRowsPerPage: 'Lignes par page',
      firstAriaLabel: 'Première page',
      firstTooltip: 'Première page',
      previousAriaLabel: 'Page précédente',
      previousTooltip: 'Page précédente',
      nextAriaLabel: 'Page suivante',
      nextTooltip: 'Page suivante',
      lastAriaLabel: 'Dernière page',
      lastTooltip: 'Dernière page'
    },
    toolbar: {
      nRowsSelected: '{0} ligne(s) sélectionnée(s)',
      searchTooltip: 'Rechercher',
      searchPlaceholder: 'Rechercher',
    },
    header: {
      actions: 'Actions'
    },
    body: {
      addTooltip: 'Ajouter',
      deleteTooltip: 'Supprimer',
      editTooltip: 'Modifier',
      emptyDataSourceMessage: 'Aucune donnée enregistrée',
      filterRow: {
          filterTooltip: 'Filtrer'
      },
      editRow:{
        deleteText: 'Confirmer la suppression ?'
      }
    }
  }
}

function locales(key, locales = locales_FR){
  try{
    let theLocale = locales_FR
    key.split('.').forEach(subkey => {
      theLocale = theLocale[subkey]
    })
    return theLocale
  }
  catch(e){
    throw new Error('[locales.js] '+e)
  }
}


export default locales;