import React, { useState } from "react";
import { useAuth } from "../../../../context/authContext";

// Material-ui
import { Fab, Tooltip } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@material-ui/core/";
import LockOpenIcon from "@material-ui/icons/LockOpen";

// Notification
import { toast } from "react-toastify";

export default function ResetPassButton(props) {
  const { userId, className } = { ...props };
  const { apiManager } = useAuth();

  const [isSubmitting, setSubmitting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  /**
   * Handle dialog close (disagree)
   */
  function handleDisagreeDelete() {
    setDialogOpen(false);
  }

  /**
   * Handle the reset password action
   * TODO: this is handle dirtly and quickly by triggering the API on a wrongly set URL
   */
  async function handleAgreeDelete() {
    if (!isSubmitting) {
      setSubmitting(true);

      apiManager
        .get("/api/user_accounts/created/" + userId)
        .then((response) => {
          toast.success(
            "La procédure de modification de mot de passe a bien été envoyé à l'utilisateur par email"
          );
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {
          setSubmitting(false);
          setDialogOpen(false);
        });
    }
  }

  return (
    <React.Fragment>
      <Tooltip
        title="Réinitialiser le mot de passe"
        aria-label="Réinitialiser le mot de passe"
      >
        <Fab
          className={className}
          size="small"
          disabled={isSubmitting}
          onClick={() => setDialogOpen(true)}
        >
          <LockOpenIcon />
        </Fab>
      </Tooltip>

      <Dialog
        open={dialogOpen}
        onClose={handleDisagreeDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmez la réinitialisation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de réinitialiser le mot de passe de
            l'utilisateur. L'utilisateur ne pourra plus se connecter tant qu'il
            n'aura pas réinitialiser son mot de passe. <br />
            <br />
            La procédure de réinitialisation lui sera envoyée par email dés que
            vous aurez validé cette action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagreeDelete}>Annuler</Button>
          <Button
            onClick={handleAgreeDelete}
            variant="contained"
            color="primary"
            autoFocus
          >
            Réinitialiser le mot de passe
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
