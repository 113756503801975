import React, { useState } from "react";
import { useAuth } from "../../../context/authContext";
import PropTypes from "prop-types";

// Material-ui
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, FormLabel } from "@material-ui/core/";
import {
  /*Error as ErrorIcon, CheckCircle as CheckCircleIcon,*/ Help as HelpIcon,
} from "@material-ui/icons/";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core/";

// Form validation
import * as Yup from "yup";
import { Formik, Form } from "formik";

// Notification
import { toast } from "react-toastify";

// Components
import LoaderButton from "../../../components/LoaderButton";
import AlertMsg from "../../../components/AlertMsg";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  form: {},
  rulesIcon: {
    "&.info": {
      color: theme.palette.info.main,
    },
    "&.error": {
      color: theme.palette.error.main,
    },
    "&.success": {
      color: theme.palette.success.main,
    },
  },
  listItem: {
    "& .icon": {
      minWidth: "35px",
      color: theme.palette.info.main,
    },
    "&.error": {
      "& .icon": {
        color: theme.palette.error.main,
      },
    },
  },
}));

/**
 * The context represent the location (the page) where the form has been called from
 * Depending on that context the form will have different behaviour
 */
const validContext = ["userProfile", "resetPassword"];

/**
 * Defining the rules of validation when choosing a new password
 * fieldKey: indicates to which field the rules is applied to
 * context: indicates in which context (page) the rule is applied to
 * other paramas : depnds on the rules type
 */
const passwordRules = {
  minLength: {
    fieldKey: "newPassword",
    value: 10,
    context: ["userProfile"], // Available only on the profile page
  },
  minSpecialChar: {
    fieldKey: "newPassword",
    value: 1,
    charList: [
      "!",
      '"',
      "#",
      "$",
      "%",
      "&",
      "'",
      "(",
      ")",
      "*",
      "+",
      ",",
      "-",
      ".",
      "/",
      ":",
      ";",
      "<",
      "=",
      ">",
      "?",
      "@",
      "[",
      "\\",
      "]",
      "^",
      "_",
      "`",
      "{",
      "|",
      "}",
      "~",
    ],
    // charList: ['!', '#'],
    context: validContext, // available in every context
  },
};

/**
 * The UserForm component can be used in any context
 * Depending on that context the form will have different behaviour.
 * e.g : when used in the registration page, the user won't need to specify its current password as he didn't know it
 */
function UserPasswordForm(props) {
  const classes = useStyles();
  const { apiManager } = useAuth();
  const [needLoginRedirect, setNeedLoginRedirect] = useState(false);

  // Initialization
  const { context, token, userId } = { ...props };

  /**
   * Handling form submition
   */
  function handleFormSubmit(values, actions) {
    const newData = {
      password: values.newPassword,
      id: userId,
    };
    console.log("test");

    /**
     * Handling to submit cases depending on the form context
     * 1- 'userProfile' context : user is connected and is updated his own data - we simply PATCH the userAccount entity
     * 2- 'resetPassword' context : user is changing his password without beeing logged in - the API has a specific endpoint here
     */
    if (context === "userProfile") {
      // Before sending the PATCH request that will update the password, we must ensure that the 'currentPAssword field is correct
      // This is an asynchronous validation
      apiManager
        .get("/user_accounts/check_pass/" + userId, {
          password: values.currentPassword,
        })
        .then((response) => {
          // Password check is OK ! We can finally submit the new password
          // TODO: the user IRI is required here
          apiManager
            .patchRessource("/user_accounts/" + userId, newData)
            .then((response) => {
              toast.success(
                "Votre nouveau mot de passe a bien été enregistré."
              );
              actions.resetForm();
            })
            .catch((error) => {
              toast.error(error);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        })
        .catch((error) => {
          //TODO : handling errors (watch API side to generate a correct error format)
          toast.error(error);
          actions.setFieldError(
            "currentPassword",
            "Le mot de passe ne correspond pas à votre mot de passe actuel"
          );
        })
        .finally((error) => {
          actions.setSubmitting(false);
        });
    } else if (context === "resetPassword") {
      // Adding the validation token when the user change his password when not logged in
      newData.token = values.token;
      apiManager
        .unauthenticatedPost("/change-pass", newData) // TODO : should be a POST ?
        .then(() => {
          setNeedLoginRedirect(true);
          toast.success(
            "Votre nouveau mot de passe a bien été enregistré. Vous pouvez désormais vous connecter à Datamatch."
          );
        })
        .catch((error) => {
          toast.error("Le token a expiré ou est invalide");
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  }

  /**
   * Dynamically generate the different messages associated to a rules
   * depending on the rules parameters
   * @param {*} ruleKey
   */
  function getRulesMessage(ruleKey) {
    let messages = {};
    switch (ruleKey) {
      case "minLength":
        const msg = `Votre mot de passe doit contenir au minimum ${passwordRules.minLength.value} caractères`;
        messages = {
          error: msg,
          info: msg,
        };
        break;
      case "minSpecialChar":
        const info = (
          <React.Fragment>
            {`Nombre de caratères spéciaux : minimum ${passwordRules["minSpecialChar"]["value"]} caractère(s)`}{" "}
            <br />
            <span>Exemple :</span>
            <code>{passwordRules["minSpecialChar"]["charList"].join("")}</code>
          </React.Fragment>
        );

        messages = {
          error: `Votre mot de passe doit contenir au minimum ${passwordRules.minSpecialChar.value} caractère spécial`,
          info: info,
        };
        break;
      default:
        break;
    }
    return messages;
  }

  /**
   * Generate the content to display inside the info box to indicates the user
   * the rules he has to follow to specify his new password
   * TODO: make the box dynamic and react to user input
   */
  function getPasswordInfoMessage(formErrors, formTouched) {
    const getListItemClassName = (fieldKey) => {
      // TODO : handling error in the message box.
      // It require to be able to detect wich rules is failing (the rule key such as 'minLength') but formErrors only provide the messages for a field
      // One solution coulg be to split every validation in separate methoods and to use it both :
      // - in the validationSchema object
      // - In this method
      const inError = false; /*formErrors && Boolean(formErrors[fieldKey]) && formTouched && formTouched[fieldKey]*/
      return `${classes.listItem} ${inError ? "error" : ""}`;
    };

    return (
      <List dense={true}>
        {Object.keys(passwordRules).map((ruleKey, index) => {
          let rule = passwordRules[ruleKey];
          return (
            <ListItem
              key={index}
              className={getListItemClassName(rule["fieldKey"])}
            >
              <ListItemIcon className="icon">
                <HelpIcon />
              </ListItemIcon>
              <ListItemText
                className="text"
                primary={getRulesMessage(ruleKey).info}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }

  function validateSpecialChar(value) {
    const charListStr = passwordRules.minSpecialChar.charList.join("");
    const regExpStr = `^.*(?=.*[${charListStr}]).*$`;
    const regExp = new RegExp(regExpStr);
    return regExp.test(value);
  }

  function getInitialValues() {
    let defaultValues = {
      context: context,
    };

    switch (context) {
      case "userProfile":
        defaultValues.currentPassword = "";
        defaultValues.newPassword = "";
        defaultValues.confirmPassword = "";
        break;
      case "resetPassword":
        defaultValues.newPassword = "";
        defaultValues.confirmPassword = "";
        defaultValues.token = token;
        defaultValues.userId = userId;
        break;
      default:
        break;
    }
    return defaultValues;
  }

  /**
   * The validation schema for the form depends on the context the form has been called from
   * The rules will not be the same.
   */
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().when("context", {
      is: (val) => ["userProfile"].indexOf(val) !== -1,
      then: Yup.string().required(),
    }),
    newPassword: Yup.string()
      .min(passwordRules.minLength.value, getRulesMessage("minLength").error)
      .required()
      .test("regex", getRulesMessage("minSpecialChar").error, (val) =>
        validateSpecialChar(val)
      ),
    confirmPassword: Yup.string()
      .required()
      .test(
        "passwords-match",
        "Les mots de passe ne correspondent pas",
        function (value) {
          return this.parent.newPassword === value;
        }
      ),
    /**
     * For reset password page
     */
    token: Yup.string().when("context", {
      is: (val) => ["resetPassword"].indexOf(val) !== -1,
      then: Yup.string().required(),
    }),
    userId: Yup.number().when("context", {
      is: (val) => ["resetPassword"].indexOf(val) !== -1,
      then: Yup.number().required(),
    }),
  });

  return needLoginRedirect ? (
    <Redirect to="/login" />
  ) : (
    <Formik
      onSubmit={(values, actions) => {
        console.log("submit");
        handleFormSubmit(values, actions);
      }}
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      render={({ values, errors, touched, handleChange, isSubmitting }) => (
        <Form className={classes.form} noValidate>
          <input
            type="hidden"
            id="context"
            name="context"
            value={values.context}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <AlertMsg severity="info">
                Votre mot de passe doit respecter les règles suivantes :
                {getPasswordInfoMessage(errors, touched)}
              </AlertMsg>
            </Grid>

            {["userProfile"].indexOf(context) !== -1 && (
              <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  id="currentPassword"
                  label="Mot de passe actuel"
                  name="currentPassword"
                  type="password"
                  autoComplete="new-password"
                  fullWidth
                  className={classes.input}
                  error={
                    touched.currentPassword && Boolean(errors.currentPassword)
                  }
                  onChange={handleChange}
                  value={values.currentPassword}
                  helperText={
                    touched.currentPassword &&
                    errors.currentPassword && (
                      <FormLabel error>{errors.currentPassword}</FormLabel>
                    )
                  }
                />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="dense"
                id="newPassword"
                label="Nouveau mot de passe"
                name="newPassword"
                type="password"
                autoComplete="new-password"
                fullWidth
                className={classes.input}
                error={touched.newPassword && Boolean(errors.newPassword)}
                onChange={handleChange}
                value={values.newPassword}
                helperText={
                  touched.newPassword &&
                  errors.newPassword && (
                    <FormLabel error>{errors.newPassword}</FormLabel>
                  )
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="dense"
                id="confirmPassword"
                label="Confirmer le mot de passe"
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                fullWidth
                className={classes.input}
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                onChange={handleChange}
                value={values.confirmPassword}
                helperText={
                  touched.confirmPassword &&
                  errors.confirmPassword && (
                    <FormLabel error>{errors.confirmPassword}</FormLabel>
                  )
                }
              />
            </Grid>

            <Grid item xs={12}>
              {/* <LoaderButton
                isSubmitting={isSubmitting}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Modifier mon mot de passe
              </LoaderButton> */}
              <button type="submit">MODIFIER</button>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
}

UserPasswordForm.propTypes = {
  userId: PropTypes.string.isRequired,
  context: PropTypes.oneOf(validContext).isRequired,
};

export default UserPasswordForm;
