import React, { useState, useRef } from "react";

import { useAuth } from "../../context/authContext";

import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Avatar,
  TextField,
  Link,
  Typography,
  FormLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core/";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Component
import AlertMsg from "../../components/AlertMsg";
import LoaderButton from "../../components/LoaderButton";

// Form validation
import * as Yup from "yup";
import { Formik, Form } from "formik";

// Utils
import ReCAPTCHA from "react-google-recaptcha";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" target="_blank" href="https://www.dekuple.com/">
        Dekuple
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorBox: {
    color: theme.palette.error.main,
    padding: theme.spacing(1),
    textAlign: "center",
    fontWeight: "bolder",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gRecaptchaWrapper: {
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.8)",
      transformOrigin: "0 0",
    },
  },
}));

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export default function SignIn() {
  const recaptchaV3Ref = useRef(null);
  const recaptchaV2Ref = useRef(null);

  const classes = useStyles();
  const { authManager, apiManager } = useAuth();
  const [errorMsg, setErrorMsg] = useState("");
  const [isPasswordLostPopupOpen, setIsPasswordLostPopupOpen] = useState(false);
  const [passwordLostEmail, setPasswordLostEmail] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const [recaptchaV3failed, setRecaptchaV3Failed] = useState(false);
  const [showRecaptchaV3, setShowRecaptchaV3] = useState(true);

  async function VerifyRecaptchaToken(token, endpoint, version = "v3") {
    if (token !== null) {
      try {
        const response = await apiManager.unauthenticatedGet(endpoint, {
          token: token,
        });

        if (version === "v3") {
          return response.success && response.score > 0.7;
        } else {
          return response.success;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    } else {
      console.warn(`Recaptcha ${version} token is null`);
      return false;
    }
  }

  // Form validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  function handleLogin(values) {
    authManager
      .login({
        username: values.email,
        password: values.password,
      })
      .then(() => {
        /* Do nothing as login will change the AuthContext state and will automatically reload the app in authenticated mode */
      })
      .catch((error) => {
        setErrorMsg(
          `Une erreur est survenue lors de votre tentative de connexion. Veuillez vérifier vos identifiants de connexion ou réessayer plus tard.\nRaison:\n${error.message}`
        );
      })
      .finally(() => {
        if (recaptchaV2Ref.current) {
          recaptchaV2Ref.current.reset();
        }
      });
  }

  const handleSubmit = async (values) => {
    try {
      setFormLoading(true);
      setShowRecaptchaV3(true);

      // if recaptcha v3 failed then skip and go directly to recaptcha v2
      if (recaptchaV3failed) {
        const recaptchaV2Value = recaptchaV2Ref.current.getValue();

        const isV2Valid = await VerifyRecaptchaToken(
          recaptchaV2Value,
          "/recaptcha/v2",
          "v2"
        ); // for V2
        if (isV2Valid) {
          setShowRecaptchaV3(false); // conditional rendering of recaptcha v3 for preventing state update attempts on an unmounted component
          handleLogin(values);
        } else {
          setErrorMsg(
            "Une erreur est survenue lors de votre authentification : la validation de la protection Google Recaptcha V2 a échouée."
          );
        }
        return;
      }

      const recaptchaV3Value = await recaptchaV3Ref.current.executeAsync();

      const isV3Valid = await VerifyRecaptchaToken(
        recaptchaV3Value,
        "/recaptcha",
        "v3"
      );
      if (isV3Valid) {
        setShowRecaptchaV3(false);
        handleLogin(values);
      } else {
        setRecaptchaV3Failed(true);
        setErrorMsg(
          "Une erreur est survenue lors de votre authentification : la validation de la protection Google Recaptcha V3 a échouée. Veuillez réessayer avec le captcha V2."
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFormLoading(false);
    }
  };

  const togglePasswordLostPopup = () =>
    setIsPasswordLostPopupOpen((prevState) => !prevState);

  const handleLostPasswordEmailChange = (e) => {
    setPasswordLostEmail(e.target.value);
  };

  const handleResetPassword = async () => {
    setResetLoading(true);
    try {
      await apiManager.unauthenticatedPost("/reset-pass", {
        email: passwordLostEmail,
      });
      togglePasswordLostPopup();
      toast.info(
        "Un e-mail vous a été envoyé pour réinitialiser votre mot de passe."
      );
    } catch (error) {
      toast.error("Cette adresse e-mail n'est pas reconnue.");
    }
    setResetLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Datamatch
        </Typography>

        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          render={(formik) => (
            <Form className={classes.form} noValidate>
              {errorMsg && <AlertMsg severity="error">{errorMsg}</AlertMsg>}

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Adresse email"
                name="email"
                autoComplete="email"
                autoFocus
                type="email"
                error={formik.touched.email && Boolean(formik.errors.email)}
                onChange={formik.handleChange}
                value={formik.values.email}
                helperText={
                  formik.errors.email &&
                  formik.touched.email && (
                    <FormLabel error>{formik.errors.email}</FormLabel>
                  )
                }
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                error={formik.touched.email && Boolean(formik.errors.email)}
                onChange={formik.handleChange}
                value={formik.values.password}
                helperText={
                  formik.errors.password &&
                  formik.touched.password && (
                    <FormLabel error>{formik.errors.password}</FormLabel>
                  )
                }
              />

              {showRecaptchaV3 && (
                <ReCAPTCHA
                  ref={recaptchaV3Ref}
                  sitekey={process.env.REACT_APP_RECAPTCHA_V3_PUBLIC}
                  size="invisible"
                />
              )}

              {recaptchaV3failed && (
                <div className={classes.gRecaptchaWrapper}>
                  <ReCAPTCHA
                    ref={recaptchaV2Ref}
                    sitekey={process.env.REACT_APP_RECAPTCHA_V2_PUBLIC}
                  />
                </div>
              )}

              <LoaderButton
                className={classes.submit}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                isSubmitting={formLoading}
                disabled={formLoading}
              >
                Connexion
              </LoaderButton>
              <Box mt={2} display="flex" justifyContent="center">
                <Button
                  variant="text"
                  onClick={() => {
                    togglePasswordLostPopup();
                    setPasswordLostEmail(formik.values.email);
                  }}
                >
                  <Typography variant="body2" color="textSecondary">
                    Mot de passe oublié ?
                  </Typography>
                </Button>
              </Box>
            </Form>
          )}
        />
      </div>
      <Box mt={4}>
        <Copyright />
      </Box>
      <Dialog open={isPasswordLostPopupOpen} onClose={togglePasswordLostPopup}>
        <DialogTitle>Mot de passe oublié ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Aucun soucis, entrez votre adresse e-mail ci-dessous et nous vous
            enverrons un lien pour réinitialiser votre mot de passe.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Adresse e-mail"
            type="email"
            fullWidth
            variant="standard"
            onChange={handleLostPasswordEmailChange}
            value={passwordLostEmail}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableElevation
            onClick={togglePasswordLostPopup}
            disabled={resetLoading}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleResetPassword}
            disabled={!emailRegex.test(passwordLostEmail) || resetLoading}
          >
            Réinitialiser mon mot de passe
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
