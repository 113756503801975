import React from 'react';

import { Link } from 'react-router-dom'
import { useAuth } from '../../../context/authContext'

// Material-UI
import {AppBar, Toolbar, IconButton, Button, Typography } from '@material-ui/core/'

import MenuIcon from '@material-ui/icons/Menu'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'

// Components
import ProfileMenu from './UserMenu'


export default function TopBar(props){

  const classes = props.useStyles();
  const { authManager, isAuthenticated } = useAuth()
  const user = authManager.getUser()

  return (
    <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>{user.organizationName}</Typography>
          { isAuthenticated() ? (
            <React.Fragment>
            
              <Typography variant="body1" className={classes.welcomeMsg}>{`Bienvenue, ${user.firstName} ${user.lastName}`}</Typography>

              <ProfileMenu />

              <IconButton 
                color="inherit"
                onClick={authManager.logout}
              >
                <PowerSettingsNewIcon />
              </IconButton>
              
            </React.Fragment>
            
            ) : (
              <Button>
                <Link to="/login">Connexion</Link>
              </Button>)
          }
        </Toolbar>
      </AppBar>
  );

}